import "../../../styles/global.scss";
import "../../../styles/sections.scss";
import "../../../styles/articles.scss";
import PostIt from "../../../components/elements/findhelpfor/postIt";
import { motion } from "framer-motion";

// Define animation variants
const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } }
};

function EatingDisorder() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../find-help-for">Find Help For..</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
          <img src="/images/icons/chevron_right.svg" className="chevron" alt="Chevron right" />
          <div className="breadcrumb current_page">
            <a href="#">Eating Disorders</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Therapy for Eating Disorders</h1>
                    <img src="/images/illustrations/purpleScribble.svg" className="titleScribble" alt="Purple scribble decoration" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="/images/articles/findHelpFor/eating-disorder.jpg" alt="Eating Disorder" />
                </div>
              </div>
              <div className="content">
                <h2>Eating disorders are essentially a felt need to drastically control your food intake.</h2>
                <p>It becomes a disorder when this control creates a negative impact on your life, both physically and mentally.</p>
                <p>Once this need for control becomes an all-consuming thought, you can cause physical symptoms such as:</p>
                <ul>
                  <li>Dizziness or fainting</li>
                  <li>Lack of energy or wanting to sleep all the time</li>
                  <li>Tremors</li>
                  <li>Delayed or a stop in your menstrual cycle</li>
                  <li>Unhealthy weight loss or gain</li>
                </ul>
                <p>There are several <a href="https://www.nhs.uk/mental-health/feelings-symptoms-behaviours/behaviours/eating-disorders/overview/" target="_blank" rel="noreferrer noopener">forms of eating disorders</a> and it is important to understand what you have been doing. Also how you have been doing it and what it is doing to your body. Thankfully, there is always medical care for you when your disorder reaches the point where you need medical assistance.</p>
                <h2>What causes eating disorders.</h2>
                <p>What this page should clarify is why food can be seen as an ‘easy’ way to gain control over things within your life. By taking needed control over what you eat and what you do with your body, you are suppressing feelings you have towards a past event or experiences that were out of your control.</p>
                <p>Trauma can be a common trigger for an eating disorder. Yet, it doesn’t have to be one major life event. Whether it is a long period of mistreating, bullying or media influence, we all want to feel that life should be what we make it to be. If something hinders that belief, you will likely find it somewhere else – and this is where food habits become a large part of dealing with those feelings and experiences.</p>
                <h2>How therapy can help.</h2>
                <p>Counselling is a beneficial way of fully understanding why you have developed an eating disorder. You can attempt to create new habits around food, but they can come off short if you do not deal with the baseline issues under the disorder.</p>
                <p>This is where counselling becomes a way to seek an answer to what food relates back to in your life. Whether that reason is minute or major, it can be found through talking counselling and then worked through so that your eating disorder is manageable and fully understood. Once you’re able to gain control over your disorder, you can then gain control over your life for the better</p>
                <h2>How Therapy in London can help.</h2>
                <p>We know that your disorder will feel like it is the only thing within your life that you want to focus on. Our aim is to be a part of that focus so that you have someone who can really understand the depths of what you are going through. We can stay within that safe space to help you become the healthiest and happiest you have ever been.&nbsp;</p>
                <p>We believe that anyone has the ability to not let their disorder take over their life, and you are no exception.</p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default EatingDisorder;
