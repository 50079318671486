import "../../../styles/global.scss";
import "../../../styles/sections.scss";
import "../../../styles/articles.scss";
import PostIt from "../../../components/elements/findhelpfor/postIt";
import { motion } from "framer-motion";

// Define animation variants
const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } }
};

function SeperationAnxiety() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../find-help-for">Find Help For..</a>
            <img src="/images/illustrations/underline.svg" />
          </div>
          <img src="/images/icons/chevron_right.svg" className="chevron" />
          <div className="breadcrumb current_page">
            <a href="#">Separation Anxiety</a>
            <img src="/images/illustrations/underline.svg" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Therapy for Separation Anxiety</h1>
                    <img src="/images/illustrations/purpleScribble.svg" className="titleScribble" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="/images/articles/findHelpFor/separation-anxiety.jpg" />
                </div>
              </div>
              <div className="content">
                <h2>Separation anxiety is a common issue in both children and adults.</h2>
                <p>In children, separation anxiety occurs when a child is learning about themselves as a separate entity from their parents. This is a scary time for a child as the realization can feel daunting and lonely.</p>
                <p>When a child is going through this, an effective tool to use is a transitional object. This is usually a comforter or teddy that acts as a new felt sense of security for the child.</p>
                <p>Many studies have shown that these <a href="https://www.psychologytoday.com/gb/blog/the-guest-room/201407/more-just-teddy-bears">transitional objects are very effective</a> in developing a child’s independence away from their parents. It also affects their confidence in themselves and their development.</p>
                <p>It is an important time for a growing child as they learn the ways of the world around them. What is important to understand is when this is beneficial, and when it becomes a crutch or a crucial part of your life.</p>
                <h2>Even though separation anxiety is associated with young children, it’s important to notice the signs in adults too.</h2>
                <p>As children, we are learning the ways of the world. Adults are still in need of a sense of control, and this is when separation anxiety can take effect. You become too reliant on someone or something.</p>
                <p>What’s important to understand is why you are feeling this way, and the signs telling you to take action:</p>
                <ul>
                  <li>You don’t want to try new things</li>
                  <li>You feel unsafe without it/them</li>
                  <li>You plan things to accommodate it/them</li>
                </ul>
                <p>Symptoms can vary depending on the person, but once you understand your symptoms, you will be able to learn from them and what they are trying to tell you.</p>
                <p>Fear of leaving something can feel like life cannot move forward without that crutch. This fear comes from the security you have felt being around that person or object. If you were feeling anxiety or tension in a certain situation, you would have begun to feel comfortable with it there and it would act as a support to get you through it.</p>
                <p>Like in children, a transitional object can be beneficial in quelling some anxiety. When you become reliant, however, it’s important to notice these feelings before they exacerbate your anxiety and the tension you feel from that separation.</p>
                <p>If not, these symptoms can develop into:</p>
                <ul>
                  <li><a href="https://www.nhs.uk/mental-health/conditions/panic-disorder/">Panic attacks</a></li>
                  <li>Depression</li>
                  <li>Feeling physically ill</li>
                  <li>A felt sense of danger when leaving the house</li>
                </ul>
                <h2>What causes separation anxiety?</h2>
                <p>Anxiety caused by separation can creep up on you for multiple reasons. When a feeling of safety is interrupted, you will instinctively want reassurance and familiarity from anyone or anything.</p>
                <p>Those feelings will usually come up when you are in a new situation or environment, which is why any kind of grounding will give you a felt sense of control.</p>
                <h2>How therapy helps.</h2>
                <p>The first step to dealing with separation anxiety is largely accepting what you hope to gain from that attachment.</p>
                <p>Therapy is a way to really hone in on the attachment and uncover what it is trying to protect you from.</p>
                <p>That felt protection is stopping you from letting go of the attachment, and this is when therapy will unravel those feelings. Once you have a new viewpoint, you can envision a life without that added ‘protection’ – and with a new sense of independence.</p>
                <h2>How <em>Therapy in London</em> can help.</h2>
                <p>Separation anxiety can feel like no one will understand how necessary that attachment is to you. It gives you grounding and a sense of control over your life.</p>
                <p>What our practitioners offer is complete acceptance of what you are going through. Any feelings or thoughts you have are valid, and the therapeutic journey should be with someone that can wholeheartedly be there with you every step of the way. What we want for you is to feel safe and secure whilst you envision and move towards a life without separation anxiety.</p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default SeperationAnxiety;
