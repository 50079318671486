import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { approveProfile } from "../../app/api/admin/approveProfile";
import { disableProfile } from "../../app/api/admin/disableProfile";
import { enableProfile } from "../../app/api/admin/enableProfile";
import { getUser } from "../../app/api/admin/getUser";
import { rejectProfile } from "../../app/api/admin/rejectProfile";
import { updateProfile as updateProfileCall } from "../../app/api/profile/updateProfile";
import { uploadCustomImage } from "../../app/api/profile/uploadCustomImage";
import { uploadInsurance } from "../../app/api/profile/uploadInsurance";
import { uploadProfilePicture } from "../../app/api/profile/uploadPP";
import useToast from "../../app/hooks/useToast";
import { useUpdateProfileData } from "../../app/hooks/useUpdateProfile";
import { useProfileStore } from "../../app/stores";
import {
  ApproveOverlay,
  RejectOverlay,
} from "../../components/elements/admin/overlays";
import Loader from "../../components/elements/loader";
import EditProfileDrag from "../../components/elements/profileEditDrag";
import CTA from "../../components/fragments/CTA";
import "../../styles/buttons.scss";
import "../../styles/global.scss";
import "../../styles/overlays.scss";
import "../../styles/profiles.scss";

function TherapistProfileEdit() {
  const { userId } = useParams();
  const { notifyFailure, notifySuccess } = useToast();
  const [statusMessage, setStatusMessage] = useState("");
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["userAdmin", userId],
    queryFn: () => getUser(userId),
    refetchOnMount: true,
    refetchOnReconnect: true,
  });

  const { profile, updateProfile, clearSelectedPhoto, selectedPhoto } =
    useProfileStore((state) => ({
      profile: state.profile,
      updateProfile: state.updateProfile,
      clearSelectedPhoto: state.clearSelectedPhoto,
      selectedPhoto: state.selectedPhoto,
    }));

  const user = data?.user;
  const [isRejectOverlayVisible, setIsRejectOverlayVisible] = useState(false);
  const [isApproveOverlayVisible, setIsApproveOverlayVisible] = useState(false);

  const updateProfileData = useUpdateProfileData(isLoading);
  useEffect(() => {
    const shouldReload = sessionStorage.getItem("shouldReload");
    if (shouldReload === "true") {
      sessionStorage.setItem("shouldReload", "false");
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    if (user) {
      updateProfileData(user);
      sessionStorage.setItem("shouldReload", "true");
    }
  }, [user, updateProfileData]);

  const handleRejectProfileClick = () => {
    setIsRejectOverlayVisible(!isRejectOverlayVisible);
  };

  const handleApproveProfileClick = () => {
    setIsApproveOverlayVisible(!isApproveOverlayVisible);
  };
  const updateProfileMutation = useMutation({
    mutationFn: async (data) => {
      const response = await updateProfileCall(data);
      return response;
    },
    onSuccess: () => {
      notifySuccess("Profile updated successfully");
      refetch();
    },
  });

  const rejectProfileMutation = useMutation({
    mutationFn: async () => {
      const response = await rejectProfile({
        profileId: user?.profile?.id,
        statusMessage,
      });
      return response;
    },
    onSuccess: () => {
      handleRejectProfileClick();
      notifyFailure("Profile rejected successfully");
      refetch();
      setStatusMessage("");
    },
    onError: (error) => {
      notifyFailure(error.message);
    },
  });

  const approveProfileMutation = useMutation({
    mutationFn: () => {
      approveProfile({
        profileId: user?.profile?.id,
        statusMessage,
      });
    },
    onSuccess: () => {
      handleApproveProfileClick();
      notifySuccess("Profile approved successfully");
      refetch();
      setStatusMessage("");
    },
    onError: (error) => {
      notifyFailure(error.message);
    },
  });
  const disableProfileMutation = useMutation({
    mutationFn: async () => {
      const response = await disableProfile({ profileId: user?.profile?.id });
      return response;
    },
    onSuccess: () => {
      notifySuccess("Profile disabled successfully");
      refetch();
    },
  });

  const enableProfileMutation = useMutation({
    mutationFn: async () => {
      const response = await enableProfile({ userId: user?.id });
      return response;
    },
    onSuccess: () => {
      notifySuccess("Profile enabled successfully");
      refetch();
    },
  });
  const uploadPhotoMutation = useMutation({
    mutationFn: () => uploadProfilePicture(user.id, selectedPhoto),
    onSuccess: () => {
      notifySuccess("Profile picture uploaded successfully");
      refetch();
    },
    onError: (error) => {
      notifyFailure(`Failed to upload profile picture: ${error.message}`);
    },
  });

  const uploadInsuranceMutation = useMutation({
    mutationFn: () => uploadInsurance(user.id, profile.insuranceFile),
    onSuccess: () => {
      notifySuccess("Insurance file uploaded successfully");
      refetch();
    },
    onError: (error) => {
      notifyFailure(`Failed to upload insurance file: ${error.message}`);
    },
  });
  const uploadCustomPhotoMutation = useMutation({
    mutationFn: () =>
      uploadCustomImage(user.id, profile.selectedFurniturePicture),
    onSuccess: () => {
      notifySuccess("Custom image uploaded successfully");
      refetch();
      updateProfile({ selectedFurniturePicture: null });
    },
    onError: (error) => {
      notifyFailure(`Failed to upload custom image: ${error.message}`);
    },
  });

  const renderStatusBasedCTAs = (status) => {
    const commonCTAs = (
      <>
        <CTA
          type="ghost"
          text="Return Home"
          link="/admin/users"
          onClick={clearSelectedPhoto}
        />
        <CTA type="ghost" text="Update" onClick={handleSave} />
      </>
    );

    switch (status) {
      case "draft":
        return commonCTAs;
      case "awaitingApproval":
        return (
          <>
            {commonCTAs}
            <CTA
              type="orange"
              text="Reject"
              onClick={handleRejectProfileClick}
            />
            <CTA
              type="blue"
              text="Approve"
              onClick={handleApproveProfileClick}
            />
          </>
        );
      case "approved":
        return (
          <>
            {commonCTAs}
            <CTA
              type="ghost"
              text="Disable"
              onClick={disableProfileMutation.mutate}
            />
          </>
        );
      case "rejected":
        return (
          <>
            {commonCTAs}
            <CTA
              type="blue"
              text="Approve"
              onClick={handleApproveProfileClick}
            />
          </>
        );
      case "disabled":
        return (
          <>
            {commonCTAs}
            <CTA
              type="blue"
              text="Enable"
              onClick={enableProfileMutation.mutate}
            />
          </>
        );
      default:
        return commonCTAs;
    }
  };

  const handleSave = () => {
    const profileData = {
      userID: profile.userId,
      lamp: profile.lamp,
      plant: profile.plant,
      sofa: profile.sofa,
      color: profile.color,
      typesOfTherapy: profile.typesOfTherapy,
      wheelchairAccess: profile.wheelchairAccess,
      languages: profile.languages,
      sessionTypes: profile.sessionTypes,
      specialisms: profile.specialisms,
      pricePackage: profile.pricePackage,
      membershipBody: profile.membershipBody,
      formattedAddress: profile.location,
      qualifications: profile.qualifications,
      quote: profile.quote,
      phoneNumber: profile.phoneNumber,
      article1: profile.article1,
      article2: profile.article2,
      profileInfos: profile.profileInfos.map((info) => ({
        title: info.title,
        content: info.content,
        order: info.order,
        hidden: info.hidden,
        isThumbnail: info.isThumbnail,
      })),
      location: profile.locationObj,
    };
    // Only add profileId if it exists
    if (data?.user?.profile?.id) {
      profileData.profileId = data.user.profile.id;
    }

    updateProfileMutation.mutate(profileData);

    if (selectedPhoto && selectedPhoto instanceof File) {
      uploadPhotoMutation.mutate();
    }
    if (profile.insuranceFile && profile.insuranceFile instanceof File) {
      uploadInsuranceMutation.mutate();
    }
    if (
      profile.selectedFurniturePicture &&
      profile.selectedFurniturePicture instanceof File
    ) {
      uploadCustomPhotoMutation.mutate();
    }
  };

  if (isLoading) return <Loader subtitle="Loading..." />;
  return (
    <>
      <div className="profileView_wrap profileEdit_wrap">
        <div className="profileView">
          <div className="profileView_controls_wrap editProfile_navFix">
            <div className="titleEdit">
              <h2>Review Profile</h2>
            </div>
            <div className="controls">
              {renderStatusBasedCTAs(profile?.status)}
            </div>
          </div>

          <RejectOverlay
            isVisible={isRejectOverlayVisible}
            onClose={handleRejectProfileClick}
            onReject={() => rejectProfileMutation.mutate()}
            statusMessage={statusMessage}
            setStatusMessage={setStatusMessage}
          />

          <ApproveOverlay
            isVisible={isApproveOverlayVisible}
            onClose={handleApproveProfileClick}
            onApprove={() => approveProfileMutation.mutate()}
            statusMessage={statusMessage}
            setStatusMessage={setStatusMessage}
          />

          <EditProfileDrag />
        </div>
      </div>
    </>
  );
}

export default TherapistProfileEdit;
