import "../../../styles/global.scss";
import "../../../styles/sections.scss";
import "../../../styles/articles.scss";
import PostIt from "../../../components/elements/findhelpfor/postIt";
import { motion } from "framer-motion";

// Define animation variants
const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } }
};

function LowSelfEsteem() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../find-help-for">Find Help For..</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
          <img src="/images/icons/chevron_right.svg" className="chevron" alt="Chevron right" />
          <div className="breadcrumb current_page">
            <a href="#">Low Self Esteem</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Therapy for Low Self Esteem</h1>
                    <img src="/images/illustrations/purpleScribble.svg" className="titleScribble" alt="Purple scribble decoration" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="/images/articles/findHelpFor/low-self-esteem.jpg" alt="Low Self Esteem" />
                </div>
              </div>
              <div className="content">
                <h2>What is low self-esteem?</h2>
                <p>When feeling low self-esteem, you are essentially not considering yourself a high priority. You believe that you do not deserve certain things or outcomes throughout your life.</p>
                <p>Low self-esteem might seem like something that you believe to be a ‘personality trait’. Close friends and family members might even use it in the form of a compliment such as ‘<em>You are so selfless, you never think of yourself!</em>’</p>
                <p>On the surface, this is a pretty common ‘compliment’ and can be seen as a genuine form of flattery.&nbsp;</p>
                <p>The problem with this arises when you believe that this is your identity. What happens from here on out, is that you do not prioritize your own self-care, and consider what you want to be at the bottom of the priority barrel.</p>
                <p>We all need self-care and self-nurturing. Once you put this off and it turns into a habit, you develop low self-esteem in the form of:</p>
                <ul>
                  <li>Putting your needs behind everyone else’s</li>
                  <li>Accepting failures and not using them to your advantage</li>
                  <li>Losing an interest in self-care</li>
                  <li>Giving up on your dreams</li>
                  <li>Being ‘content’ with staying still in life</li>
                </ul>
                <p>When you feel like this it can not only feel debilitating but can also bring on other mental health issues such as:</p>
                <ul>
                  <li><a href="https://www.therapyin.london/find-help-for/anxiety/" target="_blank" rel="noreferrer noopener">Anxiety</a></li>
                  <li>Stress</li>
                  <li>Lack of <a href="https://www.therapyin.london/find-help-for/motivation/" target="_blank" rel="noreferrer noopener">motivation</a></li>
                  <li>Procrastination&nbsp;</li>
                </ul>
                <p>If left unaddressed these issues can even turn into:&nbsp;</p>
                <ul>
                  <li><a href="https://www.therapyin.london/find-help-for/depression/" target="_blank" rel="noreferrer noopener">Depression</a></li>
                  <li>Trigger phobias</li>
                  <li>Even believing that you deserve mental or physical abuse</li>
                </ul>
                <p>You can see how a simple feeling of low self-esteem can develop and evolve into a more serious condition.</p>
                <p>As with any issue, the earlier it is addressed, the easier it can be.&nbsp;Having said that, it is never too late to take control of how you feel about yourself and your actions.</p>
                <h2>What causes low self-esteem?&nbsp;</h2>
                <p>As with any issue, low self-esteem or confidence is formed through past experiences which create adverse habits within your present life.</p>
                <p>This can come from any and all experiences that might make you feel like you were either of lower importance to others, or that you do not deserve certain things in your life.</p>
                <p>It is not always as explicit as you might think, but can include:</p>
                <ul>
                  <li>Parents treating you differently from your siblings</li>
                  <li>Not being encouraged and therefore not knowing your abilities</li>
                  <li>An argument (or several) with a loved one</li>
                  <li>Feeling you ‘failed’ one too many times</li>
                  <li>Not having a plan of what to do next or what you want.</li>
                </ul>
                <h2>How therapy can help you.</h2>
                <p>If you have low self-esteem, then counseling can provide the neutral, impartial but honest space that you may need.</p>
                <p>Overcoming low self-esteem means finding confidence in yourself and using that to find success and happiness in your life. Within this neutral therapeutic space, you are able to address the person you believe you can be and want to be, without the expectations of others who know you as you are now.</p>
                <p>As you go through this idea of who you want to be, your counselor can guide you through your current life. You can then both work on you becoming that person and creating new, beneficial habits. These new habits come from approaching the world in a more life-affirming way in order to start prioritizing your needs.</p>
                <h2>How <em>Therapy in London</em> can help.</h2>
                <p>Low self-esteem and confidence issues are a barrier that can stop most from moving forward and being the best version of yourself possible.</p>
                <p>Therapy in London practitioners use many different modalities of therapy to guide you through how you reached the point you are at now. You can then understand that past journey, and find ways to create a new path for yourself.</p>
                <p>This is an exciting journey to take with your counselor and can help to find abilities and new heights for your life which you never thought possible.</p>
                <p>We want to help you find that confidence in yourself and we believe that it is there in everyone to be found.</p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default LowSelfEsteem;
