import "../../../styles/global.scss";
import "../../../styles/sections.scss";
import "../../../styles/articles.scss";
import PostIt from "../../../components/elements/findhelpfor/postIt";
import { motion } from "framer-motion";

// Define animation variants
const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } }
};

function PPS() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../find-help-for">Find Help For..</a>
            <img src="/images/illustrations/underline.svg" />
          </div>
          <img src="/images/icons/chevron_right.svg" className="chevron" />
          <div className="breadcrumb current_page">
            <a href="#">Peter Pan Syndrome</a>
            <img src="/images/illustrations/underline.svg" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Therapy for Peter Pan Syndrome</h1>
                    <img src="/images/illustrations/purpleScribble.svg" className="titleScribble" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="/images/articles/findHelpFor/peter-pan-syndrome.jpg" />
                </div>
              </div>
              <div className="content">
                <p>Peter Pan Syndrome refers to someone who may find it difficult to take on adult responsibilities, maintain mature relationships, or simply ‘grow-up’.</p>
                <p>This aptly-named issue has been a growing talking point surrounding mental health. We’re finding an increasing amount who find it difficult to mature or grow.&nbsp;</p>
                <p>Someone with Peter Pan Syndrome, can still work, be intelligent, and complete adult tasks. However there can be many things holding you back from success and happiness such as: </p>
                <ul>
                  <li>Relying on your partner or parents too much for simple tasks</li>
                  <li>Unable to commit fully to relationships</li>
                  <li>Not ambitious with career or achievements</li>
                  <li>Unable to make long term plans or look to the future</li>
                  <li>Finding it difficult to take accountability</li>
                </ul>
                <p>Because many with this issue can live normal, adult lives, you wouldn’t stand out as someone who <em>‘thinks they are a child’</em>. It instead relates to issues around emotional attachment and any anxiety that can come up for you around certain tasks or expectations of you.</p>
                <h2>Your relationships are the epitome of who you perceive yourself to be and how you want to be seen. </h2>
                <p>If your relationships are based on feeling unable to do things by yourself, it can also feel difficult to solidify relationships in your life.</p>
                <p>Many with Peter Pan Syndrome can either:</p>
                <ul>
                  <li>Still live with their parents well into adulthood</li>
                  <li>Turn to an older partner for care and reliability</li>
                  <li>Lean to younger partners, for shorter and less-committed relationships.</li>
                </ul>
                <p>This is when Peter Pan Syndrome can become a burden and difficult to understand why you have created these habits for yourself.</p>
                <h2>What causes Peter Pan Syndrome?</h2>
                <p>Peter Pan Syndrome is not a medical diagnosis or disorder so you can find some elements in many people.</p>
                <p>If this issue begins to affect your life and the people around you, this can rooted from specific backgrounds:</p>
                <ul>
                  <li><strong>Overprotective parents.</strong> This gives little space for children to understand the world for themselves. They later on find it difficult to make their own decisions or take the time to learn things for themselves.<br />What can also happen is these children grow up with a need to recreate the childhood they missed, or longed-for.</li>
                </ul>
                <ul>
                  <li><strong>Neglect.</strong> A child that has experienced neglect will usually yearn for guidance and support if they are not receiving it at home. An adult previously neglected can then continue a pattern of needing regular help and attention for their own reassurances and fear of abandonment.<br />Neglect can, again, result in some adults wanting to relive their childhood. Wanting to make impulsive decisions, avoiding consequences and responsibility.</li>
                </ul>
                <h2>How can therapy help?&nbsp;</h2>
                <p>The most effective way to rework these habits is to understand what you are attempting to placate in your life.</p>
                <p>As with any issue, the basis of it is attempting to hide from those more negative emotions, such as anxiety, tension, stress or anger.</p>
                <p>Through therapy you are able to delve into what emotions are trying to come out and what you can learn from them.&nbsp;</p>
                <h2>How <em>Therapy in London</em> can help.</h2>
                <p>Our practitioners want to be part of that safe space to really explore your background and what has led you to where you are.&nbsp;</p>
                <p>If you believe that Peter Pan Syndrome is having a detrimental effect on your life, we offer a place for you to take ownership of how you are feeling, understand what you want for your future and help you build a path to achieving it.</p>
                <p>Feeling unable to grow or mature can feel stifling, but we know that we are able to work with you to show how much you could achieve to create success in your life.</p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default PPS;
