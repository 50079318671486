import { zodResolver } from "@hookform/resolvers/zod";
import { useRef, useState, useEffect } from "react";
import { useSignInWithEmailAndPassword } from "react-firebase-hooks/auth";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../../../app/lib/firebaseClient";
import Loader from "../../../components/elements/loader";
import CTA from "../../../components/fragments/CTA";
import "../../../styles/buttons.scss";
import "../../../styles/global.scss";
import "../../../styles/signup.scss";
import { signInSchema } from "../../schemas";

function SignIn() {

  useEffect(() => {
    document.body.style.backgroundColor = "#3881F1";

    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []);

  const [showSignUpBlock2, setShowSignUpBlock2] = useState(false);
  const [showNextButton, setShowNextButton] = useState(true);
  const passwordInputRef = useRef(null);
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [signInWithEmailAndPassword, _, loading, error] =
    useSignInWithEmailAndPassword(auth);

  const form = useForm({
    resolver: zodResolver(signInSchema),
    mode: "onBlur",
  });

  const emailError = form.formState.errors?.email?.message;
  const handleNextClick = () => {
    if (emailError) return;
    setShowSignUpBlock2(true);
    setShowNextButton(false); // Hide the Next button after it's clicked
    if (passwordInputRef.current) {
      passwordInputRef.current.focus(); // Focus on the password input
    }
  };

  const handleGoBackClick = () => {
    setShowSignUpBlock2(false); // Hide signUp_block2 when "Go Back" is clicked
    setShowNextButton(true); // Show the Next button again
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleNextClick();
    }
  };
  const onSubmit = async (data) => {
    try {
      const result = await signInWithEmailAndPassword(
        data.email,
        data.password,
      );
      if (result?.user) {
        navigate("/therapist/profile", { replace: true });
      } else {
        console.error("Sign-in failed: No user returned");
      }
    } catch (error) {
      console.error("Sign-in failed", error);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <div className="signUp_wrap backgroundBlue">
        <div className="signUp_center">
          <div className="pageTitle">
            <h1>Login</h1>
          </div>
          <div className="signUp_block">
            <div className="count">
              <label>1 of 2</label>
            </div>
            <div className="block">
              <div className="title">
                <h2>Enter your email</h2>
                <img
                  src="../../../images/illustrations/orangeScribble.svg"
                  alt="Scribble"
                />
              </div>
              <div className="input">
                <Controller
                  control={form.control}
                  name="email"
                  rules={{ required: true }}
                  defaultValue=""
                  render={({ field }) => (
                    <input
                      type="text"
                      onKeyDown={handleKeyPress}
                      placeholder="Type Here"
                      // onKeyDown={handleKeyPress}
                      {...field}
                    />
                  )}
                />
                {emailError && <span className="errorNote">{emailError}</span>}
              </div>
              <div className="controls">
                {showNextButton && (
                  <CTA type="ghost" text="Next" onClick={handleNextClick} />
                )}
              </div>
            </div>
          </div>

          <div className={`signUp_block2 ${showSignUpBlock2 ? "fade-in" : ""}`}>
            <div className="count">
              <label>2 of 2</label>
            </div>
            <div className="block">
              <div className="title">
                <h2>Password</h2>
              </div>
              <div className="input">
                <Controller
                  control={form.control}
                  name="password"
                  rules={{ required: true }}
                  defaultValue=""
                  render={({ field }) => (
                    <input
                      type="password"
                      placeholder="Enter Password"
                      {...field}
                    />
                  )}
                />
                {form.formState.errors.password && (
                  <span className="errorNote">
                    {form.formState.errors.password.message}
                  </span>
                )}
                {error && (
                  <div className="errorNote">
                    {error.message.includes("invalid")
                      ? "Invalid email or password"
                      : error.message.includes("disabled")
                        ? "Your account has been disabled, please try again in 30 minutes or contact us on 'contact@therapyin.london"
                        : error.message.includes("already")
                          ? "Email already in use"
                          : error.message}
                  </div>
                )}
              </div>
              <div className="controls">
                <CTA
                  type="ghost_white"
                  text="Go Back"
                  onClick={handleGoBackClick}
                />
                <button className="cta_ghost_white" type="submit">
                  Sign In
                </button>
              </div>
              <div className="forgotPassword">
                <Link to="/portal/forgot-password">Forgot my Password</Link>
              </div>
            </div>
          </div>

          <div className="subLink">
            <label>Don&apos;t have an account?</label>
            <CTA
              type="ghost_white"
              text="Create Account"
              link="/portal/signup"
            />
          </div>
        </div>
      </div>
    </form>
  );
}

export default SignIn;
