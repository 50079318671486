import "../../styles/global.scss";
import "../../styles/sections.scss";
import "../../styles/articles.scss";
import { motion } from "framer-motion";

const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

export const TermsTherapist = () => {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../">Home</a>
            <img src="../../images/illustrations/underline.svg" />
          </div>
          <img src="../../images/icons/chevron_right.svg" className="chevron" />
          <div className="breadcrumb current_page">
            <a href="#">Terms & Conditions Therapists</a>
            <img src="../../images/illustrations/underline.svg" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView ">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={containerVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>T&C's Therapists</h1>
                    <img src="../../images/illustrations/purpleScribble.svg" className="titleScribble" />
                  </div>
                </div>
              </div>
              <div className="content">
                <h2>1. ABOUT THESE TERMS</h2>
                <p>1.1 These terms and conditions (Terms) set out the terms on which you can use and purchase directory services through our website (<a href="https://www.therapyin.london">www.therapyin.london</a>). You must accept these Terms for us to provide the Services to you.</p>
                <p>1.2 Please read these Terms carefully. These Terms tell you who we are and other important information. They describe how we sell our Services, set out your rights and responsibilities and tell you what to do if there is a problem. These Terms also limit our liability.</p>
                <p>1.3 By signing up for an account, you are agreeing to these Terms. If you do not agree to these Terms, you must not continue with our Services.</p>
                <p>1.4 Please note that we reserve the right to update, change or replace any part of these Terms at our sole discretion. However, the terms which apply to your order will be those in force at the time you submitted your order to us.</p>
                <p>1.5 We will collect some personal data about you in order to create your account (e.g., your name, email address, payment details). For information regarding how we process personal data, please see our privacy policy at <a href="https://www.therapyin.london/terms/privacy-policy">www.therapyin.london/terms/privacy-policy</a>.</p>
                <p>1.6 These terms cover the terms and conditions if you sign up for our Services via the Website. For information regarding access to and use of our Website, please see our Website terms of use at <a href="https://www.therapyin.london/terms/terms-of-use">www.therapyin.london/terms/terms-of-use</a>.</p>

                <h2>2. WHO ARE WE?</h2>
                <p>2.1 We are Therapy in London, registered in England and Wales with company number 12498132 whose registered address is Second Floor, 315 Regents Park Road, London, N3 1DP (we/us/our). Our VAT number is 448 0091 02.</p>
                <p>2.2 We are a directory service for mental health practitioners and in the business of selling an advertising platform to mental health practitioners as well as a private practice.</p>
                <p>2.3 If you have any questions about these Terms, please contact us using the details below: Email: <a href="mailto:contact@therapyin.london">contact@therapyin.london</a>.</p>

                <h2>3. SIGNING UP FOR AN ACCOUNT</h2>
                <p>3.1 To use our Services, you need to sign up for an account on our Website.</p>
                <p>3.2 Please check your details carefully before confirming them. You are responsible for ensuring that your account is complete and accurate.</p>
                <p>3.3 Once you create your account, a contract between you and us will be created that is subject to these Terms. We will then send you a confirmation email to let you know how you can continue with our Services.</p>
                <p>3.4 You agree to maintain and adhere to the highest professional standards throughout your engagement with our Services. This includes, but is not limited to:</p>
                <ul>
                  <li>3.4.1 Membership Bodies: Maintaining active membership in a recognised professional body accepted by Therapy in London.</li>
                  <li>3.4.2 Insurance: Carrying and maintaining adequate professional liability insurance.</li>
                  <li>3.4.3 Ethical Conduct: Adhering to the ethical guidelines and codes of conduct established by your professional body.</li>
                </ul>
                <p>3.5 You acknowledge that your failure to maintain these standards may result in the termination of your profile.</p>
                <p>3.6 We reserve the right to disable or reject any account at our discretion. If we are unable to accept your account or decide to disable your account, we will notify you as soon as possible.</p>

                <h2>4. DESCRIPTION OF OUR CONTENT</h2>
                <p>4.1 The description of the Services is as set out on our Website at <a href="https://www.therapyin.london/why-join-therapy-in-london">www.therapyin.london/why-join-therapy-in-london</a>.</p>
                <p>4.2 We cannot guarantee that the colours displayed on your device when using our Services will match the exact colours shown on our Website. The colours on profiles may vary depending on what device you are using and your settings.</p>
                <p>4.3 We are not responsible for Profile Content which fails to meet its description where your device does not meet the technical requirements as set out on our Website.</p>
                <p>4.4 When providing our Services to you, we will:</p>
                <ul>
                  <li>4.4.1 Provide our Services to you in accordance with these Terms; and</li>
                  <li>4.4.2 Comply with all applicable laws.</li>
                </ul>

                <h2>5. DELIVERY OF OUR SERVICES</h2>
                <p>5.1 If you are having any trouble accessing any part of our Website or Services, please email us at: <a href="mailto:contact@therapyin.london">contact@therapyin.london</a>.</p>

                <h2>6. LICENCE</h2>
                <p>6.1 How you use our Services in accordance with these Terms:</p>
                <ul>
                  <li>6.1.1 We grant you a non-exclusive, non-transferable licence to use our Services [and, where you are a business customer, for use within your business and to access your Profile Account for internal business purposes only]; and</li>
                </ul>
                <p>6.2 So as long as you do not violate any restrictions set out in these Terms, you may use your Profile for advertising purposes of your private practice.</p>

                <h2>7. LICENCE RESTRICTIONS</h2>
                <p>7.1 You must not:</p>
                <ul>
                  <li>7.1.1 Sell, distribute, transfer, or modify personal details to any other parties’ names. As well as rent, lease, loan, sub-license your Profile Account in any way.</li>
                  <li>7.1.2 Remove, delete, obscure, disable, modify, add to, tamper with, or circumvent any program code or data, copyright, trademark, or other proprietary notices, labels or copy protection contained on or within the Website.</li>
                  <li>7.1.3 Exploit our Services (or part of), content contained in it, or any of our related Articles for any commercial purpose.</li>
                  <li>7.1.4 Export the Website content or any copy or adaptation in violation of any applicable laws or regulations.</li>
                  <li>7.1.5 Create data or executable programs which mimic data or functionality of our Services.</li>
                  <li>7.1.6 Use our Services for any illegal or immoral purposes.</li>
                  <li>7.1.7 Use these Services in any other parties’ names to make any other Content which is substantially similar.</li>
                </ul>

                <h2>8. OUR INTELLECTUAL PROPERTY RIGHTS</h2>
                <p>8.1 You agree that we and our licensors own all intellectual property rights. These Terms do not grant you any rights to any intellectual property rights except as expressly set out in these Terms.</p>
                <p>8.2 You may not use our trademarks or trade names on your website or in any marketing materials unless you have an active, full subscription to our Services.</p>

                <h2>9. PRICE AND PAYMENT</h2>
                <p>9.1 Once submitted Membership Bodies and Insurance documents have been reviewed and validated, you will be redirected to Stripe to set up payment. The Price is inclusive of VAT.</p>
                <p>9.2 Prices for our Services may change at any time. We will notify you in advance of the date of any price changes unless:</p>
                <ul>
                  <li>9.2.1 The information you provided us in relation to your order was materially different from the information we required in order to provide the Services; or</li>
                  <li>9.2.2 There has been an error on the Website regarding the pricing of our Services and this affects your payments, we will try to contact you using the contact details you provided when you signed up. If this happens, we will give you the option to re-confirm your payment at the correct price or to cancel your order.</li>
                </ul>
                <p>9.3 We will charge the Price to the credit or debit card that you have provided to Stripe. All amounts due must be paid either monthly or annually through Direct Debit.</p>
                <p>9.4 You can review payments accepted by Stripe here: <a href="https://support.stripe.com">support.stripe.com</a>.</p>

                <h2>10. REFUNDS AND YOUR RIGHT TO CANCEL</h2>
                <p>10.1 Our Services are not refundable and subject to a 12-month minimum contract (the Commitment Period).</p>
                <p>10.2 After the Commitment Period, your Account will continue on a rolling basis unless you cancel via the Website or by emailing <a href="mailto:contact@therapyin.london">contact@therapyin.london</a> in writing.</p>

                <h2>11. YOUR PRIVACY</h2>
                <p>11.1 We will use any personal information that you provide to us in accordance with our Privacy Policy (as may be updated from time to time).</p>

                <h2>12. GENERAL</h2>
                <p>12.1 These Terms, our Privacy Policy and Website Terms of Use constitute the entire agreement between you and us. They replace any previous drafts, agreements, understandings and arrangements between us, whether in writing or oral.</p>
                <p>12.2 You must not assign or transfer these Terms to anyone else.</p>
                <p>12.3 A person who is not a party to these Terms will not have any rights to enforce them.</p>
                <p>12.4 These Terms are governed by the laws of England and Wales and any dispute between you and us is subject to the exclusive jurisdiction of the courts of England and Wales.</p>

              </div>
            </motion.div>
          </div>
        </div>
      </motion.div>
    </>
  );
}
