import { motion } from "framer-motion";
import propTypes from "prop-types";
import { Link } from "react-router-dom";
import "../../../styles/articles.scss";
import "../../../styles/global.scss";
import "../../../styles/sections.scss";
import "../../../styles/textTransitions.scss";

const staggeredVariants = {
  hidden: { opacity: 0, y: 20 },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, y: 40 },
  show: { opacity: 1, y: 0, transition: { duration: 0.6 } },
};

const Scrollview = ({ className, children }) => (
  <motion.div
    initial="hidden"
    whileInView="show"
    viewport={{ once: true }}
    variants={staggeredVariants}
    className={className}
  >
    {children}
  </motion.div>
);

function FHF(props) {
  return (
    <div className="block">
      <Link to={props.linkHref}>
        <Scrollview>
          <div className="image" style={{ height: "130px" }}>
            <motion.img
              src={props.imageSrc}
              alt={props.title}
              variants={itemVariants}
            />
          </div>
          <div style={{ height: "auto" }}>
            <motion.h2 id="slide-up" variants={itemVariants}>
              {props.title}
            </motion.h2>
          </div>
          <div style={{ height: "auto" }}>
            <motion.p variants={itemVariants}>{props.desc}</motion.p>
          </div>
        </Scrollview>
      </Link>
    </div>
  );
}

Scrollview.propTypes = {
  className: propTypes.string,
  children: propTypes.node,
};

FHF.propTypes = {
  imageSrc: propTypes.string,
  title: propTypes.string,
  description: propTypes.string,
  linkHref: propTypes.string,
  linkText: propTypes.string,
  className: propTypes.string,
  desc: propTypes.string,
};

export default FHF;
