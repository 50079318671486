import "../../styles/global.scss";
import "../../styles/sections.scss";
import "../../styles/articles.scss";
import { motion } from "framer-motion";

const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

export const PPolicy = () => {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../">Home</a>
            <img src="../../images/illustrations/underline.svg" />
          </div>
          <img src="../../images/icons/chevron_right.svg" className="chevron" />
          <div className="breadcrumb current_page">
            <a href="#">Privacy Policy</a>
            <img src="../../images/illustrations/underline.svg" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView ">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={containerVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Privacy Policy</h1>
                    <img
                      src="../../images/illustrations/purpleScribble.svg"
                      className="titleScribble"
                    />
                  </div>
                </div>
              </div>
              <div className="content">
                <h2>1. BACKGROUND</h2>
                <p>Last updated: 5 August 2024</p>
                <p>
                  1.1 This notice (Privacy Notice) tells you how we look after
                  your personal data when you visit our website at{" "}
                  <a href="https://www.therapyin.london" target="_blank">
                    https://www.therapyin.london
                  </a>{" "}
                  (Website) or when you purchase our Subscription for our mental
                  health advertising services, where you are a prospective
                  customer of our business, or where you are another type of
                  business contact, such as a supplier or service provider to
                  our business.
                </p>
                <p>
                  1.2 This notice sets out what information we collect about
                  you, what we use it for and whom we share it with. It also
                  explains your rights under data protection laws and what to do
                  if you have any concerns about your personal data.
                </p>
                <p>
                  1.3 We may sometimes need to update this Privacy Notice, to
                  reflect any changes to the way our services are provided or to
                  comply with new business practices or legal requirements. You
                  should check this Privacy Notice regularly to see whether any
                  changes have occurred.
                </p>

                <h2>2 WHO WE ARE AND OTHER IMPORTANT INFORMATION</h2>
                <p>
                  2.1 We are Therapy in London Partnership Limited, registered
                  in England and Wales with company number 12498132 with our
                  registered address at Second Floor, 315 Regents Park Road,
                  London, United Kingdom, N3 1DP (we, us or our).
                </p>
                <p>
                  2.2 For all visitors to our Website and for users who purchase
                  our Services through an organisation, we are the controller of
                  your information (which means we decide what information we
                  collect and how it is used).
                </p>
                <p>
                  2.3 We are registered with the Information Commissioner’s
                  Office (ICO), the UK regulator for data protection matters,
                  under number ZB378993.
                </p>

                <h2>3 CONTACT DETAILS</h2>
                <p>
                  3.1 If you have any questions about this Privacy Notice or the
                  way that we use information, please get in touch using the
                  following details:
                </p>
                <p>
                  Data protection officer: Paulyne Karahassan,{" "}
                  <a href="mailto:paulyne@therapyin.london">
                    paulyne@therapyin.london
                  </a>
                </p>

                <h2>4 THE INFORMATION WE COLLECT ABOUT YOU</h2>
                <p>
                  4.1 Personal data means any information which does (or could
                  be used to) identify a living person. We have grouped together
                  the types of personal data that we collect, and where we
                  receive it from, below.
                </p>
                <p>4.2 Type of personal data:</p>
                <ul>
                  <li>Identity Data: your first and last name or title.</li>
                  <li>
                    Contact Data: your email address, telephone numbers, home
                    address.
                  </li>
                  <li>
                    Technical Data: internet protocol (IP) address, browser type
                    and version, time zone setting and generic location, browser
                    plug-in types and versions, operating system and platform on
                    the devices you use to access our systems.
                  </li>
                  <li>
                    Usage Data: information about how you use our systems.
                  </li>
                  <li>
                    Financial Data: where you provide this over our Website to
                    purchase one of our services.
                  </li>
                  <li>
                    Feedback: information and responses you provide when
                    completing surveys and questionnaires.
                  </li>
                  <li>
                    Photo and Image Data: profile picture, images, videos and
                    audio.
                  </li>
                  <li>
                    Marketing and Communication Data: includes your preferences
                    in receiving marketing from us and our third parties and
                    your communication preferences.
                  </li>
                  <li>
                    Qualifications, Insurance and Registered bodies: includes
                    all documents and details provided to prove education level,
                    liability insurance and registered body memberships.
                  </li>
                </ul>
                <p>
                  4.3 Please note that we do not collect any payment card data
                  or similar data relating to your method of payment. You
                  provide this data directly to Stripe, who processes payments
                  on our behalf. We only receive and process information about
                  the timing and amount of your payment.
                </p>

                <h2>5 HOW WE USE YOUR INFORMATION</h2>
                <p>
                  5.1 We are required to identify a legal justification (also
                  known as a lawful basis) for collecting and using your
                  personal data. There are six legal justifications which
                  organisations can rely on. The most relevant of these to us
                  are where we use your personal data to:
                </p>
                <ul>
                  <li>comply with a legal obligation that we have;</li>
                  <li>
                    pursue our legitimate interests (our justifiable business
                    aims) but only if those interests are not outweighed by your
                    other rights and freedoms (e.g. your right to privacy); and
                  </li>
                  <li>do something for which you have given your consent.</li>
                </ul>
                <p>
                  5.2 Below is set out the lawful basis we rely on when we use
                  your personal data. If we intend to use your personal data for
                  a new reason that is not listed below, we will update our
                  Privacy Notice.
                </p>
                <p>5.2.1 Legal Obligation:</p>
                <ul>
                  <li>
                    Recording your preferences (e.g. marketing) to ensure that
                    we comply with data protection laws.
                  </li>
                  <li>
                    Where we send you information to comply with a legal
                    obligation (e.g. where we send you information about your
                    legal rights).
                  </li>
                  <li>
                    Where we retain information to enable us to bring or defend
                    legal claims.
                  </li>
                </ul>
                <p>5.2.2 Legitimate Interests:</p>
                <ul>
                  <li>
                    Where using your information is necessary to pursue our
                    legitimate business interests to:
                    <ul>
                      <li>a) improve and optimise our Website;</li>
                      <li>
                        b) monitor and make improvements to our Website to
                        enhance security and prevent fraud;
                      </li>
                      <li>
                        c) provide our services to you and ensure the proper
                        functioning of our Website; and
                      </li>
                      <li>
                        d) protect our business and defend ourselves against
                        legal claims.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Where we use your information for our legitimate interests,
                    we have assessed whether such use is necessary and that such
                    use will not infringe on your other rights and freedoms.
                  </li>
                </ul>
                <p>5.2.3 Consent:</p>
                <ul>
                  <li>
                    Where you have provided your consent to providing us with
                    information or allowing us to use or share your information.
                  </li>
                  <li>
                    Where you have consented to receive marketing material from
                    us.
                  </li>
                </ul>
                <p>
                  5.3 Where we need to collect your personal data (for example,
                  in order to fulfil a contract we have with you), failure to
                  provide us with your personal data may mean that we are not
                  able to provide you with the services. Where we do not have
                  the information required about you to fulfil an order, we may
                  have to cancel the service ordered.
                </p>
                <p>
                  5.4 We may anonymise the personal data we collect (so it can
                  no longer identify you) and then combine it with other
                  anonymous information so it becomes aggregated data.
                  Aggregated data helps us identify trends (e.g. what percentage
                  of users responded to a specific survey). Data protection law
                  does not govern the use of aggregated data and the various
                  rights described below do not apply to it.
                </p>

                <h2>6 WHO WE SHARE YOUR INFORMATION WITH</h2>
                <p>6.1 We share (or may share) your personal data with:</p>
                <ul>
                  <li>
                    Our personnel: our employees (or other types of workers) who
                    have contracts containing confidentiality and data
                    protection obligations.
                  </li>
                  <li>
                    Our supply chain: other organisations that help us provide
                    our goods. We ensure these organisations only have access to
                    the information required to provide the support we use them
                    and have a contract with them that contains confidentiality
                    and data protection obligations.
                  </li>
                  <li>Any actual or potential buyer of our business.</li>
                </ul>
                <p>
                  6.2 If we were asked to provide personal data in response to a
                  court order or legal request (e.g. from the police), we would
                  seek legal advice before disclosing any information and
                  carefully consider the impact on your rights when providing a
                  response.
                </p>

                <h2>7 WHERE YOUR INFORMATION IS LOCATED OR TRANSFERRED TO</h2>
                <p>7.1 We store your personal data on our servers in the UK.</p>
                <p>
                  7.2 We will only transfer information outside of the UK or EEA
                  where we have a valid legal mechanism in place (to make sure
                  that your personal data is guaranteed a level of protection,
                  regardless of where in the world it is located, e.g. by using
                  contracts approved by the ICO or the UK Secretary of State).
                </p>
                <p>
                  7.3 If you access our Website or purchase our services whilst
                  abroad then your personal data may be stored on servers
                  located in the same country as you or your organisation.
                </p>

                <h2>8 HOW WE KEEP YOUR INFORMATION SAFE</h2>
                <p>
                  8.1 We have implemented security measures to prevent your
                  personal data from being accidentally or illegally lost, used
                  or accessed by those who do not have permission. These
                  measures include:
                </p>
                <ul>
                  <li>
                    access controls and user authentication (including
                    multi-factor authentication)
                  </li>
                  <li>internal IT and network security</li>
                  <li>regular testing and review of our security measures</li>
                  <li>staff policies and training</li>
                  <li>incident and breach reporting processes</li>
                  <li>business continuity and disaster recovery processes</li>
                </ul>
                <p>
                  8.2 If there is an incident which has affected your personal
                  data and we are the controller, we will notify the regulator
                  and keep you informed (where required under data protection
                  law). Where we act as the processor for the affected personal
                  data, we notify the controller and support them with
                  investigating and responding to the incident.
                </p>
                <p>
                  8.3 If you notice any unusual activity on the Website, please
                  contact us at{" "}
                  <a href="mailto:contact@therapyin.london">
                    contact@therapyin.london
                  </a>
                  .
                </p>

                <h2>9 HOW LONG WE KEEP YOUR INFORMATION</h2>
                <p>
                  9.1 Where we act as the controller, we will only retain your
                  personal data for as long as necessary to fulfil the purposes
                  we collected it for.
                </p>
                <p>
                  9.2 To decide how long to keep personal data (also known as
                  its retention period), we consider the volume, nature, and
                  sensitivity of the personal data, the potential risk of harm
                  to you if an incident were to happen, whether we require the
                  personal data to achieve the purposes we have identified or
                  whether we can achieve those purposes through other means
                  (e.g. by using aggregated data instead), and any applicable
                  legal requirements (e.g. minimum accounting records for HM
                  Revenue & Customs).
                </p>
                <p>
                  9.3 We may keep Identity Data, Contact Data and certain other
                  data (specifically, any exchanges between us by email or any
                  other means) for up to seven years after the end of our
                  contractual relationship with you.
                </p>
                <p>
                  9.4 If you browse our Website, we keep personal data collected
                  through our analytics tools for only as long as necessary to
                  fulfil the purposes we collected it for.
                </p>
                <p>
                  9.5 If you have asked for information from us or you have
                  subscribed to our mailing list, we keep your details until you
                  ask us to stop contacting you.
                </p>

                <h2>10 YOUR LEGAL RIGHTS</h2>
                <p>
                  10.1 You have specific legal rights in relation to your
                  personal data.
                </p>
                <p>
                  10.2 We can decide not to take any action in relation to a
                  request where we have been unable to confirm your identity
                  (this is one of our security processes to make sure we keep
                  information safe) or if we feel the request is unfounded or
                  excessive. Usually there is no cost for exercising your data
                  protection rights, but we may charge a fee where we decide to
                  proceed with a request that we believe is unfounded or
                  excessive. If this happens we will always inform you in
                  writing.
                </p>
                <p>
                  10.3 We will respond to your legal rights request without
                  undue delay, but within one month of us receiving your request
                  or confirming your identity (whichever is later). We may
                  extend this deadline by two months if your request is complex
                  or we have received multiple requests at once. If we need to
                  extend the deadline, we will let you know and explain why we
                  need the extension.
                </p>
                <p>
                  10.4 We do not respond directly to requests which relate to
                  personal data for which we act as the processor. In this
                  situation, we forward your request to the relevant controller
                  and await their instruction before we take any action.
                </p>
                <p>
                  10.5 If you wish to make any of the right requests listed
                  below, you can reach us at{" "}
                  <a href="mailto:contact@therapyin.london">
                    contact@therapyin.london
                  </a>
                  .
                </p>
                <ul>
                  <li>
                    Access: You must be told if your personal data is being used
                    and you can ask for a copy of your personal data as well as
                    information about how we are using it to make sure we are
                    abiding by the law.
                  </li>
                  <li>
                    Correction: You can ask us to correct your personal data if
                    it is inaccurate or incomplete. We might need to verify the
                    new information before we make any changes.
                  </li>
                  <li>
                    Deletion: You can ask us to delete or remove your personal
                    data if there is no good reason for us to continue holding
                    it or if you have asked us to stop using it (see below). If
                    we think there is a good reason to keep the information you
                    have asked us to delete (e.g. to comply with regulatory
                    requirements), we will let you know and explain our
                    decision.
                  </li>
                  <li>
                    Restriction: You can ask us to restrict how we use your
                    personal data and temporarily limit the way we use it.
                  </li>
                  <li>
                    Objection: You can object to us using your personal data if
                    you want us to stop using it. If we think there is a good
                    reason for us to keep using the information, we will let you
                    know and explain our decision.
                  </li>
                  <li>
                    Portability: You can ask us to send you or another
                    organisation an electronic copy of your personal data.
                  </li>
                  <li>
                    Complaints: If you are unhappy with the way we collect and
                    use your personal data, you can complain to the ICO or
                    another relevant supervisory body, but we hope that we can
                    respond to your concerns before it reaches that stage.
                    Please contact us at{" "}
                    <a href="mailto:contact@therapyin.london">
                      contact@therapyin.london
                    </a>
                    .
                  </li>
                </ul>

                <h2>11 WHEN WE SEND YOU MARKETING MESSAGES</h2>
                <p>
                  11.1 If you have consented to receiving marketing messages
                  from us, you can opt out of these at any time via the link
                  provided in our communications.
                </p>
                <p>
                  11.2 Opting out of marketing will not affect our processing of
                  your personal data in relation to any order you have with us
                  and where we are required to use your personal data to fulfil
                  that order or provide you with certain information.
                </p>
              </div>
            </motion.div>
          </div>
        </div>
      </motion.div>
    </>
  );
}
