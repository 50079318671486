import React, { useState, useRef } from "react";
import { useProfileStore } from "../../../app/stores";
import "../../../styles/global.scss";
import "../../../styles/sections.scss";

import PropTypes from "prop-types";

function Types(props) {
  const { updateProfile, profile } = useProfileStore((state) => ({
    updateProfile: state.updateProfile,
    profile: state.profile,
  }));

  const [isExpanded, setIsExpanded] = useState(false);
  const typesContentRef = useRef(null);

  const handleMouseEnter = () => {
    if (typesContentRef.current) {
      // Set initial max-height to trigger the transition
      typesContentRef.current.style.maxHeight = `${typesContentRef.current.scrollHeight}px`;
      setIsExpanded(true);
    }
  };

  const handleMouseLeave = () => {
    if (typesContentRef.current) {
      // Reset max-height to original state
      typesContentRef.current.style.maxHeight = "50px";
      setIsExpanded(false);
    }
  };

  const handleOptionClick = (option) => {
    if (props.title === "Wheelchair Accessible") {
      updateProfile({ wheelchairAccess: option === "Yes" });
    } else if (props.title === "Types of Therapy") {
      updateProfile({
        typesOfTherapy: profile.typesOfTherapy?.includes(option)
          ? profile.typesOfTherapy.filter((therapy) => therapy !== option)
          : [...profile.typesOfTherapy, option],
      });
    } else if (props.title === "Session Types") {
      updateProfile({
        sessionTypes: profile.sessionTypes?.includes(option.toLowerCase())
          ? profile.sessionTypes.filter((type) => type !== option.toLowerCase())
          : [...profile.sessionTypes, option.toLowerCase()],
      });
    }
  };

  const isOptionSelected = (option) => {
    if (props.title === "Wheelchair Accessible") {
      return profile.wheelchairAccess === (option === "Yes");
    }
    if (props.title === "Types of Therapy") {
      return profile.typesOfTherapy?.includes(option);
    }
    if (props.title === "Session Types") {
      return profile.sessionTypes?.includes(option.toLowerCase());
    }
    return false;
  };

  const combinedClassName = `types_wrap ${props.className || ""}`;

  return (
    <div className={combinedClassName}>
      <div className="title">
        <div className="label_angle" />
        <label>{props.title}</label>
      </div>
      <div
        className="types_content"
        ref={typesContentRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{ maxHeight: isExpanded ? "auto" : "100px" }}
      >
        <div className="types_tags">
          {props.labels.map((label, index) => {
            const labelValue = typeof label === "string" ? label : label.name;
            const labelStyle = props.isShow
              ? {}
              : {
                backgroundColor: isOptionSelected(label) ? "white" : "",
                color: isOptionSelected(label) ? "black" : "",
              };
            return (
              <label
                key={index}
                onClick={() => handleOptionClick(label)}
                style={labelStyle}
              >
                {labelValue}
              </label>
            );
          })}
        </div>
      </div>
    </div>
  );
}

Types.propTypes = {
  title: PropTypes.string.isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  className: PropTypes.string,
  isShow: PropTypes.bool,
};

export default Types;
