import React from 'react';
import "../../styles/sections.scss";

function LineBackground() {
    const lines = Array.from({ length: 28 }).map((_, index) => <span key={index}></span>);

    return (
        <div className="backgroundLineEffect">
            {lines}
        </div>
    );
}

export default LineBackground;
