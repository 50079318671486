import propTypes from "prop-types";
import "../../styles/global.scss";
import "../../styles/sections.scss";

Overlay.propTypes = {
  isVisible: propTypes.bool,
  onClose: propTypes.func,
  title: propTypes.string,
  subtitle: propTypes.string,
  ctaBack: propTypes.string,
  ctaGo: propTypes.string,
  onBackClick: propTypes.func,
  onAcceptClick: propTypes.func,
  onTextareaChange: propTypes.func,
  textareaValue: propTypes.string,
  isRequired: propTypes.bool,
};

function Overlay(props) {
  return (
    <div className="overlay_wrap">
      <div className="overlay_center">
        <div className="overlay_block">
          <div className="title">
            <h2>{props.title}</h2>
            <p>{props.subtitle}</p>
          </div>
          <div className="content">
            <textarea
              value={props.textareaValue}
              onChange={props.onTextareaChange}
              required={props.isRequired}
            />
          </div>

          <div className="control">
            <button
              type="button"
              className="cta_ghost_blue"
              onClick={props.onBackClick}
            >
              {props.ctaBack}
            </button>
            <button
              type="button"
              className="cta_blue"
              onClick={props.onAcceptClick}
            >
              {props.ctaGo}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Overlay;
