import PropTypes from "prop-types";
import { forwardRef } from "react";
import { Link } from "react-router-dom";
import { getFileUrl } from "../../../app/lib/getFileUrl";
import "../../../styles/admin.scss";

const UserRow = forwardRef(
  ({ name, status, userId, profilePicture, firebaseUID }, ref) => {
    const formatStatus = (status) => {
      if (status === undefined) return "Profile Not Created";
      return status
        ?.split(/(?=[A-Z])/)
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    };

    const displayStatus = formatStatus(status);

    return (
      <div className="row" ref={ref}>
        <Link to={`/admin/users/profile/${firebaseUID}`}>
          <div className="imageName">
            <div className="image">
              <img
                src={getFileUrl("profilePicture", userId, profilePicture) || ""}
                alt=""
              />
            </div>
            <label>{name}</label>
          </div>
          <div className="controlsWrap">
            <label>{displayStatus}</label>
          </div>
        </Link>
      </div>
    );
  }
);

UserRow.propTypes = {
  name: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  profilePicture: PropTypes.string.isRequired,
  firebaseUID: PropTypes.string.isRequired,
};

UserRow.displayName = "UserRow";

export default UserRow;
