import "../../../styles/global.scss";
import "../../../styles/sections.scss";
import "../../../styles/articles.scss";
import PostIt from "../../../components/elements/findhelpfor/postIt";
import { motion } from "framer-motion";

const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } }
};

function SuicidalThoughts() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../find-help-for">Find Help For..</a>
            <img src="/images/illustrations/underline.svg" />
          </div>
          <img src="/images/icons/chevron_right.svg" className="chevron" />
          <div className="breadcrumb current_page">
            <a href="#">Suicidal Thoughts</a>
            <img src="/images/illustrations/underline.svg" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Therapy for Suicidal Thoughts</h1>
                    <img src="/images/illustrations/purpleScribble.svg" className="titleScribble" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="/images/articles/findHelpFor/suicidal-thoughts.jpg" />
                </div>
              </div>
              <div className="content">
                <p>Suicidal thoughts can range from a fleeting feeling that people in your life would be better off without you, to feeling like life has reached a point where it is unfixable.</p>
                <p>The important part of all this is that thoughts and feelings are just that. Thoughts and feelings.</p>
                <p>It may not feel like it right now, but anyone, including you, has the power to create a new understanding of these thoughts and build a future for themselves full of success, willingness, and personal growth.</p>

                <h3>If you have been having any kind of suicidal thoughts, <a href="https://www.samaritans.org/how-we-can-help/contact-samaritan/" target="_blank" rel="noreferrer noopener">you can <strong>contact The Samaritans (Call: 116 123)</strong> 24 hours a day, 7 days a week</a> for confidential help and guidance.</h3>

                <h2>What causes suicidal thoughts?</h2>
                <p>Suicidal thoughts can be brought up for many reasons. Many mental health issues, including disempowerment or feeling stuck and unmotivated, can spiral to this point.</p>
                <p>A lack of a support system, loneliness, finding certain things unmanageable, emotionally painful, or difficult to understand, all play a part in how you feel about your next steps and your future.</p>
                <h2>How therapy helps.</h2>
                <p>Building up self-esteem and confidence in yourself is the first obstacle in avoiding these thoughts. This is why talking to others creates a grounding for how you are feeling. By vocalizing your thoughts, it allows you to embed them into your reality, rather than thoughts that you have allowed to build up.</p>
                <p>This is where constant communication with others allows you to see past those thoughts and instead help you to create a positive, ongoing future for your life, away from these thoughts and feelings.</p>
                <p>Discussing your thoughts around how you are feeling gives you clarity on your own meaning of life. You may feel like your friends or family will not understand, and that is why it can be invaluable to talk to an outsider who can offer a fresh perspective for you.</p>
                <p>Counseling is available privately, but also to anyone no matter what your budget is. The NHS even offers many free services to help find you someone to speak to. <a href="https://www.samaritans.org/" target="_blank" rel="noreferrer noopener"><strong>The Samaritans (116 123)</strong></a> are always available and welcome you to speak to them no matter what time of day.</p>
                <p>Counseling means delving into what brought you to contemplate suicide, including when these thoughts began and what has happened up to this point.</p>
                <p>Reaching this perspective on life can make it difficult to go back and relive or retell, but what it will do is unravel your life and create a new narrative of your past, creating a fresh, clearer future.</p>
                <h2>How <em>Therapy in London</em> can help.</h2>
                <p>Your life can be nurtured, and you can create a new, happier narrative for yourself and your future.</p>
                <p>We want to take that time to understand what you have been going through and at your own pace. Your sessions are your own space with no limits on what you can talk about. With this freedom, we want to help you to see a much brighter outlook on your life that you might never have thought possible.</p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default SuicidalThoughts;
