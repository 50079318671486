import PropTypes from "prop-types";
import "../../styles/global.scss";
import "../../styles/sections.scss";

function FilterOptions({
  title,
  options,
  selectedOptions,
  onChange,
  isSingleSelect,
}) {
  const handleChange = (option) => {
    if (isSingleSelect) {
      onChange(option);
    } else {
      const isSelected = selectedOptions.includes(option);
      onChange(option, !isSelected);
    }
  };

  const isSelected = (option) => {
    return selectedOptions.includes(option);
  };

  return (
    <div className="filter_block">
      <h3>{title}</h3>
      <div className="radio_wrap">
        {options.map((option) => (
          <div
            key={option}
            className={`radio_toggle ${isSelected(option) ? "active" : ""}`}
          >
            <label>
              <input
                type={isSingleSelect ? "radio" : "checkbox"}
                name={title}
                value={option}
                checked={isSelected(option)}
                onChange={() => handleChange(option)}
              />
              <span
                className={isSelected(option) ? "active" : ""}
                htmlFor={title}
              >
                {option}
              </span>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}

FilterOptions.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  isSingleSelect: PropTypes.bool,
};

export default FilterOptions;
