import React, { Suspense, useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
var ImageResize;
import('quill-image-resize-module-react').then((module) => {
  ImageResize = module.default;
});

var Quill, ReactQuill;
import("react-quill").then((module) => {
  Quill = module.Quill;
  ReactQuill = module.default;
  Quill.register("modules/imageResize", ImageResize);
});

import { useNavigate, useParams } from "react-router-dom";
import { approveArticle } from "../../app/api/admin/approveArticle";
import { rejectArticle } from "../../app/api/admin/rejectArticle";
import {
  getArticleById,
  updateArticle,
  uploadArticleThumbnail,
} from "../../app/api/articles";
import { deleteArticle } from "../../app/api/articles/deleteArticle";
import useToast from "../../app/hooks/useToast";
import { queryClient } from "../../app/lib/createQueryClient";
import { getFileUrl } from "../../app/lib/getFileUrl";
import {
  ApproveOverlay,
  RejectOverlay,
} from "../../components/elements/admin/overlays";
import CTA from "../../components/fragments/CTA";
import Statuses from "../../components/fragments/statuses";
import "../../styles/buttons.scss";
import "../../styles/editor.scss";
import "../../styles/global.scss";
import "../../styles/profiles.scss";

function AdminWriteArticle() {
  const { articleId } = useParams();
  const [isRejectOverlayVisible, setIsRejectOverlayVisible] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [statusMessage, setStatusMessage] = useState("");
  const [isApproveOverlayVisible, setIsApproveOverlayVisible] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const navigate = useNavigate();

  const { data, refetch } = useQuery({
    queryKey: ["articleReview", articleId],
    queryFn: () => getArticleById(articleId),
    enabled: !!articleId,
  });

  const [articleContent, setArticleContent] = useState(
    data?.article?.content || "",
  );

  const [imageUrl, setImageUrl] = useState(data?.article?.picture || null);
  const { notifyFailure, notifySuccess } = useToast();

  const deleteArticleMutation = useMutation({
    mutationFn: () => {
      return deleteArticle(articleId);
    },
    onSuccess: () => {
      notifySuccess("Article Deleted");
      queryClient.invalidateQueries(["adminArticles", ""]);
      navigate("/admin/articles");
    },
    onError: () => {
      notifyFailure("Failed to delete article");
    },
  });
  useEffect(() => {
    if (data) {
      setArticleContent(data.article.content);
    }
  }, [data]);

  const handlePhotoChange = (event) => {
    const file = event.target.files[0];
    if (
      file &&
      (file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg")
    ) {
      setSelectedPhoto(file);
      setImageUrl(URL.createObjectURL(file));
      setHasChanges(true);
    } else {
      notifyFailure("Please select a JPEG, PNG, or JPG file.");
    }
  };

  const handleDeleteImage = () => {
    setSelectedPhoto(null);
    setImageUrl(null);
  };

  const handleUploadNewImage = () => {
    document.getElementById("fi").click();
  };

  const handleRejectProfileClick = () => {
    setIsRejectOverlayVisible(!isRejectOverlayVisible);
  };

  const handleApproveProfileClick = () => {
    setIsApproveOverlayVisible(!isApproveOverlayVisible);
  };

  const getArticleStatus = (status) => {
    switch (status) {
      case "awaitingApproval":
        return <Statuses text="Awaiting Review" color="status_red" />;
      case "approved":
        return <Statuses text="Approved" color="status_blue" />;
      case "rejected":
        return <Statuses text="Rejected" color="status_green" />;
      case "draft":
        return <Statuses text="Draft" color="status_green" />;
      default:
        return null;
    }
  };

  const updateArticleMutation = useMutation({
    mutationFn: () =>
      updateArticle({
        articleId,
        title: data?.article?.title,
        content: articleContent,
        specialisms: data?.article?.specialisms,
      }),
    onSuccess: () => {
      notifySuccess("Article updated successfully");
    },
    onError: () => {
      notifyFailure("Failed to update article");
    },
  });

  const approveArticleMutation = useMutation({
    mutationFn: () => {
      if (!imageUrl) {
        throw new Error("Please upload a photo before approving the article.");
      }
      return approveArticle({
        articleId: articleId,
        statusMessage: statusMessage,
      });
    },
    onSuccess: () => {
      notifySuccess("Article approved successfully");
      setIsApproveOverlayVisible(false);
      refetch();
    },
    onError: (error) => {
      notifyFailure(error.message || "Failed to approve article");
    },
  });

  const rejectArticleMutation = useMutation({
    mutationFn: () =>
      rejectArticle({
        articleId: articleId,
        statusMessage: statusMessage,
      }),
    onSuccess: () => {
      notifySuccess("Article rejected successfully");
      refetch();
      setIsRejectOverlayVisible(false);
    },
    onError: () => {
      notifyFailure("Failed to reject article");
    },
  });

  const uploadArticlePhotoMutation = useMutation({
    mutationFn: ({ articleId, photo }) =>
      uploadArticleThumbnail(articleId, photo),
    onSuccess: () => {
      notifySuccess("Article photo uploaded successfully");
      refetch();
      setSelectedPhoto(null);
    },
    onError: () => {
      notifyFailure("Failed to upload article photo");
    },
  });

  const handleSave = () => {
    if (!imageUrl) {
      notifyFailure("Please upload a photo before updating the article.");
      return;
    }
    updateArticleMutation.mutate();

    if (selectedPhoto && selectedPhoto instanceof File) {
      uploadArticlePhotoMutation.mutate({ articleId, photo: selectedPhoto });
    }
    setHasChanges(false);
  };

  useEffect(() => {
    if (data) {
      setArticleContent(data.article.content);
      if (data.article.picture) {
        const serverImageUrl = getFileUrl(
          "article",
          articleId,
          data.article.picture,
        );
        setImageUrl(serverImageUrl);
      } else {
        setImageUrl(null);
      }
      setHasChanges(false); // Reset changes when data is loaded
    }
  }, [data, articleId]);

  return (
    <>
      <div className="profileView_wrap backgroundClear">
        <div className="profileView">
          <div className="profileView_controls_wrap">
            <div className="titleEdit">
              <h2>Review Profile</h2>
            </div>
            <div className="controls">
              <button
                type="button"
                onClick={() => deleteArticleMutation.mutate()}
              >
                Delete Article
              </button>
              <CTA type="ghost" text="Return Home" link="/admin/articles" />
            </div>
          </div>

          <div className="narrowColumn_wrap">
            <div className="title">
              <h1>
                Topic:{" "}
                {data?.article.specialisms.map((s) => s.name).join(", ") ||
                  "N/A"}
              </h1>
              <h1>{data?.article?.title}</h1>
              <p>This is the text explaining the brief of the Article.</p>
              {data?.article?.status && getArticleStatus(data?.article?.status)}
            </div>
            <p>Add and image and update to approve an article</p>
            <div className="uploadFeaturedImage">
              <div className="upload_area">
                {imageUrl ? (
                  <>
                    <img
                      src={imageUrl}
                      alt="Featured"
                      style={{ maxWidth: "100%", maxHeight: "200px" }}
                    />
                    {data?.article?.photo ? (
                      <button type="button" onClick={handleUploadNewImage}>
                        Upload New Image
                      </button>
                    ) : (
                      <button type="button" onClick={handleDeleteImage}>
                        Delete Image
                      </button>
                    )}
                  </>
                ) : (
                  <label htmlFor="fi">Upload Featured Image</label>
                )}
              </div>
              <input
                type="file"
                name="fi"
                id="fi"
                onChange={handlePhotoChange}
                style={{ display: "none" }}
                accept="image/jpeg, image/png, image/jpg"
              />
            </div>

            <div className="editorWrap">
              <div className="editor">
                <Suspense fallback={<div>Loading editor...</div>}>
                  <ReactQuill
                    theme="snow"
                    value={articleContent}
                    onChange={(content) => {
                      setArticleContent(content);
                      setHasChanges(content !== data?.article?.content);
                    }}
                    modules={{
                      toolbar: [
                        [
                          "bold",
                          "italic",
                          "underline",
                          "strike",
                          { size: [] },
                          { background: [] },
                          { color: [] },
                        ],
                        [{ align: [] }],
                        [{ list: "ordered" }, { list: "bullet" }],
                        // [{ indent: "-1" }, { indent: "+1" }],
                        ["link", "image", "video"],
                        [],
                      ],
                      imageResize: {
                        parchment: Quill.import("parchment"),
                        modules: ["Resize", "DisplaySize"],
                      },
                    }}
                  />
                </Suspense>
              </div>

              <div className="editorControls">
                <RejectOverlay
                  isVisible={isRejectOverlayVisible}
                  onClose={handleRejectProfileClick}
                  onReject={() => rejectArticleMutation.mutate()}
                  statusMessage={statusMessage}
                  setStatusMessage={setStatusMessage}
                />
                <ApproveOverlay
                  isVisible={isApproveOverlayVisible}
                  onClose={handleApproveProfileClick}
                  onApprove={() => approveArticleMutation.mutate()}
                  statusMessage={statusMessage}
                  setStatusMessage={setStatusMessage}
                />
                {data?.article?.status !== "rejected" &&
                  data?.article?.status !== "approved" && (
                    <CTA
                      type="ghost"
                      text="Reject"
                      onClick={handleRejectProfileClick}
                    />
                  )}
                {data?.article?.status !== "approved" &&
                  !hasChanges &&
                  imageUrl && (
                    <CTA
                      type="blue"
                      text="Approve"
                      onClick={handleApproveProfileClick}
                    />
                  )}

                {hasChanges && imageUrl && (
                  <CTA type="blue" text="Update article" onClick={handleSave} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminWriteArticle;
