import "../../../styles/global.scss";
import "../../../styles/sections.scss";
import "../../../styles/articles.scss";
import PostIt from "../../../components/elements/findhelpfor/postIt";
import { motion } from "framer-motion";

// Define animation variants
const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } }
};

function Procrastination() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../find-help-for">Find Help For..</a>
            <img src="/images/illustrations/underline.svg" />
          </div>
          <img src="/images/icons/chevron_right.svg" className="chevron" />
          <div className="breadcrumb current_page">
            <a href="#">Procrastination</a>
            <img src="/images/illustrations/underline.svg" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Therapy for Procrastination</h1>
                    <img src="/images/illustrations/purpleScribble.svg" className="titleScribble" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="/images/articles/findHelpFor/procrastination.jpg" />
                </div>
              </div>
              <div className="content">
                <p>When dealing with procrastination, you are constantly fighting the urge to either do something more interesting, or something you would <em>rather</em> do. This can be anything other than what you are meant to be doing.</p>
                <p>This is a common issue, and you have likely found countless tips and tricks to stop procrastination. The problem with these quick fixes (such as time management and to-do lists) is that you are creating a forced perception of the mindset you are already in.</p>
                <p>Suffering from procrastination begins from the point of you wanting more control within your life. When you decide that a task is not going to be completed, or even started, you are searching for that control anywhere you can.&nbsp;</p>
                <p>This has one objective – that is to feel happy with your choices and the path you are taking. The problem arises when you sacrifice necessary aspects of your life because you are lacking elsewhere.</p>
                <p>This is why counselling can be a hugely effective way to deal with procrastination from the root of the problem.</p>
                <h2>What causes procrastination.</h2>
                <p>You can learn how procrastination evolves, but this fails to pinpoint how it began and what causes you to go down this route. When dealing with procrastination, it will usually be narrowed down to a part of your life that you may feel is out of your control.</p>
                <p>This can be:</p>
                <ul>
                  <li>Your job or career not being fulfilling anymore</li>
                  <li>Your relationship with your partner or lacking that romantic connection</li>
                  <li>Rifts in your family or close ones</li>
                  <li>You not enjoying your hobbies anymore</li>
                  <li>A feeling of lost identity</li>
                </ul>
                <p>When these are not addressed, your day to day life can feel like more of a chore and your enthusiasm for smaller tasks will become mundane. Because of this, it is normal for you to want to make more of an active choice in what you do and when.</p>
                <p>This is when procrastination gives you that opportunity to make those choices, even though it may be at the detriment of your day-to-day tasks, activities, deadlines or even your own expectations for yourself.</p>
                <h2>How therapy can help.&nbsp;</h2>
                <p><a href="https://www.therapyin.london/about">Therapy</a> is an effective way to target the reasons why you have been procrastinating, what effect the procrastination has had on your life and what it has been <em>trying</em> to take control of.</p>
                <p>When dealing with procrastination, it is important to understand what these actions have been doing. With that you can pivot those feelings and emotions – creating a more productive and action-focused approach throughout your life.</p>
                <h2>How <em>Therapy in London</em> can help.</h2>
                <p>Our counsellors are highly experienced in approaching all issues from all walks of life in their own unique way. When dealing with procrastination, there should be an understanding of why you think, feel and react the way you do. <a href="https://www.therapyin.london/"><em>Therapy in London</em></a> can offer a practitioner to you who is able to connect with you at your level. They can bring to light how to conquer your procrastination and live a more productive life from here on out.</p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default Procrastination;
