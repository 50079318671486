import { useMutation, useQuery } from "@tanstack/react-query";
import { motion } from "framer-motion"; // Import Framer Motion
import propTypes from "prop-types";
import { useState } from "react";
import {
  useSignInWithEmailAndPassword,
  useUpdateEmail,
} from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import { updateUser } from "../../app/api/auth/updateUser";
import { deleteAccount } from "../../app/api/profile/deleteAccount";
import { getUser } from "../../app/api/profile/getUser";
import useToast from "../../app/hooks/useToast";
import { auth } from "../../app/lib/firebaseClient";
import Loader from "../../components/animations/Loader";
import "../../styles/buttons.scss";
import "../../styles/global.scss";
import "../../styles/profiles.scss";
// Define animation variants
const containerVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } },
};

const TherapistAdminSettings = () => {
  const [updateEmail, updateEmailError] = useUpdateEmail(auth);
  const [signIn] = useSignInWithEmailAndPassword(auth);
  const navigate = useNavigate();
  const { notifySuccess, notifyFailure } = useToast();
  const [dialogState, setDialogState] = useState({
    showDeleteDialog: false,
    showChangeEmailDialog: false,
  });
  const [emailForm, setEmailForm] = useState({
    newEmail: "",
    confirmEmail: "",
    password: "",
  });

  const {
    data: { user },
    refetch,
  } = useQuery({
    queryKey: ["user"],
    queryFn: () => getUser(),
  });
  const [name, setName] = useState(user?.name || "");
  const [gender, setGender] = useState(user?.gender || "");

  const deleteAccountMutation = useMutation({
    mutationFn: () => {
      return deleteAccount(user.id);
    },
    onSuccess: async () => {
      await auth.signOut();
      navigate("/", { replace: true });
      notifySuccess("Account Deleted");
    },
    onError: (error) => {
      notifyFailure("Failed to delete account, error:", error);
    },
  });

  const updateUserMutatuion = useMutation({
    mutationFn: ({ userId, name, email, gender }) => {
      return updateUser({ userId, name, email, gender });
    },
    onSuccess: () => {
      refetch();
    },
    onError: (error) => {
      notifyFailure("Failed to update profile, error:", error);
    },
  });

  const toggleDialog = (dialogType) => {
    setDialogState((prev) => ({ ...prev, [dialogType]: !prev[dialogType] }));
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    setEmailForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleConfirmDelete = () => {
    deleteAccountMutation.mutate();
    toggleDialog("showDeleteDialog");
  };

  const handleUpdate = async () => {
    try {
      const updatedFields = {};
      if (name !== user.name) updatedFields.name = name;
      if (gender !== user.gender) updatedFields.gender = gender;

      if (emailForm.newEmail && emailForm.newEmail !== user.email) {
        if (!emailForm.password) {
          notifyFailure("Please enter your password to continue");
          return;
        }

        if (emailForm.newEmail !== emailForm.confirmEmail) {
          notifyFailure("Emails do not match");
          setEmailForm((prev) => ({ ...prev, newEmail: "", confirmEmail: "" }));
          return;
        }

        try {
          const sUser = await signIn(user?.email, emailForm.password);
          if (!sUser) {
            notifyFailure("Invalid password");
            setEmailForm((prev) => ({ ...prev, password: "" }));
            return;
          }

          await updateEmail(emailForm.newEmail);
          updatedFields.email = emailForm.newEmail;
        } catch (firebaseError) {
          notifyFailure(`Firebase error: ${firebaseError.message}`);
          setEmailForm({ newEmail: "", confirmEmail: "", password: "" });
          return;
        }
      }

      if (Object.keys(updatedFields).length > 0) {
        await updateUserMutatuion.mutateAsync({
          userId: user.id,
          ...updatedFields,
        });
      }

      refetch();
      if (emailForm.newEmail) {
        setEmailForm({ newEmail: "", confirmEmail: "", password: "" });
        toggleDialog("showChangeEmailDialog");
      }
      notifySuccess("Profile updated successfully");
    } catch (error) {
      notifyFailure(`Failed to update profile: ${error.message}`);
      setEmailForm({ newEmail: "", confirmEmail: "", password: "" });
      console.error(error);
    }
  };

  const renderNavLinks = () => {
    const links = [
      { to: "/therapist/profile", text: "Profile" },
      ...(user?.subscriptionStatus === true
        ? [{ to: "/therapist/articles", text: "Articles" }]
        : []),
      ...(user.profile?.status === "approved"
        ? [{ to: "/therapist/billing", text: "Billing" }]
        : []),
      { to: "/therapist/settings", text: "Settings", active: true },
    ];

    return links.map(({ to, text, active }) => (
      <motion.li
        key={text}
        className={active ? "active" : ""}
        initial="hidden"
        animate="visible"
        variants={fadeInVariants}
      >
        <Link to={to}>{text}</Link>
        <img
          src="../../../images/illustrations/underline.svg"
          alt="underline"
        />
      </motion.li>
    ));
  };

  const renderSettingsRows = () => {
    const settings = [
      {
        title: "Name",
        value: name,
        action: {
          text: "Update Name",
          onClick: handleUpdate,
          input: {
            type: "text",
            placeholder: "Name",
            value: name,
            onChange: (e) => setName(e.target.value),
          },
        },
      },
      {
        title: "Gender",
        value: `${gender
          ?.replace(/_/g, " ")
          .replace(/\b\w/g, (c) => c.toUpperCase())}`,
        action: {
          text: "Update Gender",
          onClick: handleUpdate,
          input: {
            type: "select",
            value: gender,
            onChange: (e) => setGender(e.target.value),
            options: [
              { value: "", label: "Select Gender" },
              { value: "cis_male", label: "Cis Male" },
              { value: "cis_female", label: "Cis Female" },
              { value: "non_binary", label: "Non Binary" },
              { value: "trans_male", label: "Trans Male" },
              { value: "trans_female", label: "Trans Female" },
              { value: "prefer_not_to_say", label: "Prefer not to say" },
            ],
          },
        },
      },

      {
        title: "Registered Email",
        value: user?.email,
        action: {
          text: "Change Email",
          onClick: () => toggleDialog("showChangeEmailDialog"),
        },
      },
      {
        title: "Password",
        value: "*************",
        action: {
          text: "Change Password",
          href: "/portal/forgot-password",
        },
      },
      {
        title: "Account Status",
        value:
          user?.profile?.status === "approved"
            ? "Live"
            : user?.profile?.status === "awaitingApproval"
            ? "Awaiting Approval"
            : "Draft",
        action: {
          text: "Delete Account",
          onClick: () => toggleDialog("showDeleteDialog"),
          danger: true,
        },
      },
    ];

    if (deleteAccountMutation.isPending) {
      return <Loader subtitle="Deleting Account.." />;
    }

    return settings.map(({ title, value, action }) => (
      <motion.div
        key={title}
        className="settingsRow"
        initial="hidden"
        animate="visible"
        variants={fadeInVariants}
      >
        <div className="settingsColumn">
          <h3>{title}</h3>
          <label>{value}</label>
        </div>
        <div className="settingsColumn">
          {action.input && action.input.type === "select" ? (
            <select
              value={action.input.value}
              onChange={action.input.onChange}
              className="settingsInputSelect"
            >
              {action.input.options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          ) : (
            action.input && (
              <input
                type={action.input.type}
                placeholder={action.input.placeholder}
                value={action.input.value}
                onChange={action.input.onChange}
                className="settingsInput"
              />
            )
          )}

          {action.href ? (
            <Link className="cta_blue" to={action.href}>
              {action.text}
            </Link>
          ) : (
            <button
              type="button"
              className={`cta_blue ${action.danger ? "danger" : ""}`}
              onClick={action.onClick}
            >
              {action.text}
            </button>
          )}
        </div>
      </motion.div>
    ));
  };

  return (
    <motion.div
      className="profileView_wrap signedIn"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <motion.div
        className="profileView"
        initial="hidden"
        animate="visible"
        variants={fadeInVariants}
      >
        <motion.div
          className="profileView_controls_wrap"
          initial="hidden"
          animate="visible"
          variants={fadeInVariants}
        >
          <motion.div
            className="adminNav"
            initial="hidden"
            animate="visible"
            variants={fadeInVariants}
          >
            <div className="profileView_page_title">
              <div className="title">
                <h1>Account</h1>
                <div className="account-status">
                  <p></p>
                </div>
              </div>
            </div>
            <div className="adminNav_links">
              <div className="links">
                <ul>{renderNavLinks()}</ul>
              </div>
              <div className="controls hideMobile" />
            </div>
          </motion.div>
        </motion.div>

        <motion.div
          className="profile_wrap"
          initial="hidden"
          animate="visible"
          variants={fadeInVariants}
        >
          <div className="profile_section_articles seeAllArticles">
            <div className="titleUnderline">
              <motion.h2
                initial="hidden"
                animate="visible"
                variants={fadeInVariants}
              >
                Settings
              </motion.h2>
              {/* biome-ignore lint/a11y/noSvgWithoutTitle: <explanation> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="282"
                height="37"
                viewBox="0 0 282 37"
                fill="none"
              >
                {/* SVG path data */}
              </svg>
            </div>

            <motion.div
              className="accountSettings_wrap"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              {renderSettingsRows()}
            </motion.div>
          </div>
        </motion.div>
      </motion.div>

      {dialogState.showDeleteDialog && (
        <ConfirmationDialog
          title="Delete Account"
          message="Are you sure you want to delete your account? This cannot be undone."
          onConfirm={handleConfirmDelete}
          onCancel={() => toggleDialog("showDeleteDialog")}
        />
      )}

      {dialogState.showChangeEmailDialog && (
        <EmailChangeDialog
          emailForm={emailForm}
          handleEmailChange={handleEmailChange}
          handleUpdate={handleUpdate}
          onCancel={() => toggleDialog("showChangeEmailDialog")}
          error={updateEmailError}
        />
      )}
    </motion.div>
  );
};

const ConfirmationDialog = ({ title, message, onConfirm, onCancel }) => (
  <motion.div
    className="overlay_wrap"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.3 }}
  >
    <div className="overlay_center">
      <div className="overlay_block">
        <div className="title">
          <h2>{title}</h2>
          <p>{message}</p>
        </div>
        <div className="control">
          <button type="button" onClick={onConfirm} className="cta_blue">
            Yes
          </button>
          <button type="button" onClick={onCancel} className="cta_blue">
            No
          </button>
        </div>
      </div>
    </div>
  </motion.div>
);

const EmailChangeDialog = ({
  emailForm,
  handleEmailChange,
  handleUpdate,
  onCancel,
  error,
}) => (
  <motion.div
    className="overlay_wrap"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.3 }}
  >
    <div className="overlay_center">
      <div className="overlay_block">
        <div className="title">
          <h2>Change Email</h2>
          <p>Update your email below</p>
        </div>
        <div className="control inputControls">
          <input
            type="email"
            name="newEmail"
            placeholder="New Email"
            value={emailForm.newEmail}
            onChange={handleEmailChange}
          />
          <input
            type="email"
            name="confirmEmail"
            placeholder="Confirm Email"
            value={emailForm.confirmEmail}
            onChange={handleEmailChange}
          />
          <input
            type="password"
            name="password"
            placeholder="Verify Password"
            value={emailForm.password}
            onChange={handleEmailChange}
          />
          <div className="settingsCTA">
            <button type="button" onClick={onCancel} className="cta_ghost_blue">
              Cancel
            </button>
            <button type="button" onClick={handleUpdate} className="cta_blue">
              Update
            </button>
          </div>
        </div>
        {error && <span>{error.message}</span>}
      </div>
    </div>
  </motion.div>
);

ConfirmationDialog.propTypes = {
  title: propTypes.string.isRequired,
  message: propTypes.string.isRequired,
  onConfirm: propTypes.func.isRequired,
  onCancel: propTypes.func.isRequired,
};

EmailChangeDialog.propTypes = {
  emailForm: propTypes.shape({
    newEmail: propTypes.string.isRequired,
    confirmEmail: propTypes.string.isRequired,
    password: propTypes.string.isRequired,
  }).isRequired,
  handleEmailChange: propTypes.func.isRequired,
  handleUpdate: propTypes.func.isRequired,
  onCancel: propTypes.func.isRequired,
  error: propTypes.string,
};

export default TherapistAdminSettings;
