import { Link } from "react-router-dom";
import ProfileArticlesView from "../../components/elements/profileArticlesView";
import CTA from "../../components/fragments/CTA";
import { motion } from "framer-motion";  // Import Framer Motion
import "../../styles/buttons.scss";
import "../../styles/global.scss";
import "../../styles/profiles.scss";

// Define animation variants
const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } }
};

function TherapistAdminArticles() {
  return (
    <motion.div
      className="profileView_wrap signedIn"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <motion.div
        className="profileView"
        initial="hidden"
        animate="visible"
        variants={fadeInVariants}
      >
        <div className="profileView_controls_wrap">
          <motion.div
            className="adminNav"
            initial="hidden"
            animate="visible"
            variants={fadeInVariants}
          >
            <div className="profileView_page_title">
              <div className="title">
                <h1>Account</h1>
                <p></p>
              </div>
            </div>
            <div className="adminNav_links">
              <div className="links">
                <ul>
                  <motion.li
                    initial="hidden"
                    animate="visible"
                    variants={fadeInVariants}
                  >
                    <Link to="/therapist/profile">Profile</Link>
                    <img
                      src="../../../images/illustrations/underline.svg"
                      alt="underline"
                    />
                  </motion.li>
                  <motion.li
                    className="active"
                    initial="hidden"
                    animate="visible"
                    variants={fadeInVariants}
                  >
                    <Link to="#">Articles</Link>
                    <img
                      src="../../../images/illustrations/underline.svg"
                      alt="underline"
                    />
                  </motion.li>
                  <motion.li
                    initial="hidden"
                    animate="visible"
                    variants={fadeInVariants}
                  >
                    <Link to="/therapist/billing">Billing</Link>
                    <img
                      src="../../../images/illustrations/underline.svg"
                      alt="underline"
                    />
                  </motion.li>
                  <motion.li
                    initial="hidden"
                    animate="visible"
                    variants={fadeInVariants}
                  >
                    <Link to="/therapist/settings">Settings</Link>
                    <img
                      src="../../../images/illustrations/underline.svg"
                      alt="underline"
                    />
                  </motion.li>
                </ul>
              </div>
              <div className="controls hideMobile">
                <CTA
                  link="../articles/available"
                  type="orange"
                  text="Write Article"
                />
              </div>
            </div>
          </motion.div>
        </div>

        <ProfileArticlesView />
      </motion.div>
    </motion.div>
  );
}

export default TherapistAdminArticles;
