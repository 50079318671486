import "../../../styles/global.scss";
import "../../../styles/sections.scss";
import "../../../styles/articles.scss";
import PostIt from "../../../components/elements/findhelpfor/postIt";
import { motion } from "framer-motion";

// Define animation variants
const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } }
};

function MenMentalHealth() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../find-help-for">Find Help For..</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
          <img src="/images/icons/chevron_right.svg" className="chevron" alt="Chevron right" />
          <div className="breadcrumb current_page">
            <a href="#">Men’s Mental Health</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Therapy for Men’s Mental Health</h1>
                    <img src="/images/illustrations/purpleScribble.svg" className="titleScribble" alt="Purple scribble decoration" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="/images/articles/findHelpFor/mensmentalhealth.jpg" alt="Men's Mental Health" />
                </div>
              </div>
              <div className="content">
                <p>Men have been restricted for years when it comes to discussing their own mental wellbeing.</p>
                <p>As society evolves, what are you doing in those moments where you feel scared, weak or immoral?</p>
                <p>Men have been encouraged to bottle up these feelings as they didn’t conform to society’s expectations.</p>
                <p>Movember is even the perfect illustration of men’s mental health awareness. Men <em>should</em> be speaking out and it should be plain as day. Just like a handlebar moustache right under your nose.</p>
                <p>According to the <a href="https://www.ons.gov.uk/peoplepopulationandcommunity/birthsdeathsandmarriages/deaths/bulletins/suicidesintheunitedkingdom/latest#suicides-in-england-and-wales" target="_blank" rel="noreferrer noopener">Office of National Statistics</a>, Men counted for three-quarters of all suicides in England and Wales in 2020. This has been a continued pattern for decades and we must address this by encouraging men to keep speaking out.</p>
                <h2>What causes men’s mental health issues</h2>
                <p>Other than having to conform to society’s expectations, It’s important to understand what issues men commonly face and how they can develop.</p>
                <ul>
                  <li>A cis man’s place is no longer to protect their spouse and be the only strong figure within the family.<br />Men have found themselves looking for a new meaning to their lives away from the obsolete societal norms.</li>
                  <li>Gay men are gradually having their voices heard yet still fighting for acceptance, which is a very conflicting place to be. We are still in a limbo of generations preaching outdated beliefs vs expressing full freedom and acceptance.</li>
                  <li>Even in a digital age we are evolving fast. As acceptance becomes louder you now have the mental capacity to discover who you are and there are no boundaries. As trans male you are still finding your place in the world yet outside of many social groups. You are creating a new path and a whole new way of living. This comes with a lot of societal pressure.</li>
                </ul>
                <p>Even with there being more fluidity with male identity, this presents new issues for men finding their place in the world including:</p>
                <ul>
                  <li>Confusion</li>
                  <li><a href="https://www.therapyin.london/find-help-for/anxiety/" target="_blank" rel="noreferrer noopener">Anxiety</a></li>
                  <li><a href="https://www.therapyin.london/find-help-for/depression" target="_blank" rel="noreferrer noopener">Depression</a></li>
                  <li>Loss of <a href="https://www.therapyin.london/find-help-for/motivation" target="_blank" rel="noreferrer noopener">motivation</a> and passion</li>
                  <li><a href="https://www.therapyin.london/find-help-for/anger" target="_blank" rel="noreferrer noopener">Anger</a> and rage</li>
                  <li><a href="https://www.therapyin.london/find-help-for/low-self-esteem/" target="_blank" rel="noreferrer noopener">Self esteem</a> issues</li>
                </ul>
                <h2>How can therapy help?</h2>
                <p>In order to take mental health out of the taboo box it has been in for so long, we must continue to talk about it openly.</p>
                <p>Therapy can seem like an intimidating process or something only meant for people with serious issues, but the regular discussion involved within counselling is what keeps us motivated, healthy and overall happier.</p>
                <p>Men have been told to hold back on their feelings for so long, yet mental health is present all of our lives. No matter who you are.</p>
                <p>This is why therapy is the ideal start to men getting the help they need and finding their voice when it comes to opening up emotionally.</p>
                <h2>How can Therapy in London help?</h2>
                <p>We aim to make talking counselling as diverse as possible. Personal wellbeing should be at the forefront of everyone’s mind and this isn’t limited to who you are or society’s expectations.</p>
                <p>When seeking counselling we want men to find their voice when talking about their own personal issues, feelings and desires. This is why we want to ensure that any man starting counselling has a safe space to find out who they are, what they want and how to make their own mental health a priority.</p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default MenMentalHealth;
