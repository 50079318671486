import "../../../styles/global.scss";
import "../../../styles/sections.scss";
import "../../../styles/articles.scss";
import PostIt from "../../../components/elements/findhelpfor/postIt";
import { motion } from "framer-motion";

// Define animation variants
const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } }
};

function Neurodiversity() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../find-help-for">Find Help For..</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
          <img src="/images/icons/chevron_right.svg" className="chevron" alt="Chevron right" />
          <div className="breadcrumb current_page">
            <a href="#">Neurodiversity</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Therapy for Neurodiversity</h1>
                    <img src="/images/illustrations/purpleScribble.svg" className="titleScribble" alt="Purple scribble decoration" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="/images/articles/findHelpFor/neurodiversity.jpg" alt="Neurodiversity" />
                </div>
              </div>
              <div className="content">
                <p>Neurodiversity refers to understanding the diversity of the human brain. You can uncover your strengths through your disorder <mark>rather than seeing this as something to be repaired or changed.</mark></p>

                <p>Neurodiversity tends to be individuals with diagnosed disorders, some of the most common being:</p>

                <ul>
                  <li><a href="https://www.therapyin.london/find-help-for/ocd/" target="_blank" rel="noreferrer noopener">OCD</a></li>
                  <li><a href="https://www.therapyin.london/find-help-for/bipolar-disorder/" target="_blank" rel="noreferrer noopener">Bipolar</a></li>
                  <li><a href="https://www.therapyin.london/find-help-for/adhd/" target="_blank" rel="noreferrer noopener">ADHD</a></li>
                  <li>Autism</li>
                </ul>

                <p>With any disorder, your brain functions in a different way from everyone else. Neurodiversity removes the idea of <em>normal</em> and <em>abnormal</em> from this equation.</p>

                <p>By doing this we are able to relate to these disorders, rather than something that must be managed or corrected.</p>

                <h2>What causes neurodiversity?</h2>

                <p>The term neurodivergent was introduced when many found that a lot of disorders are far from a hindrance if utilised correctly.</p>

                <p>Even when considering that many of the greatest minds in the world had such disorders, a neurodivergent mind is something to be nurtured with the correct tools rather than seen as an ailment.</p>

                <p>Whether you are neurodivergent or <a href="https://www.bbc.com/future/article/20191008-why-the-normal-brain-is-just-a-myth" target="_blank" rel="noreferrer noopener">neurotypical</a>, we all need certain support and processes. Whether that be to learn, express, or live in the most successful way possible.</p>

                <p>Even though not recognised in the medical world, this concept is beneficial for mental well-being.</p>

                <p>When coming to terms with your disorder, the first step is learning about your own personal way of thinking.</p>

                <p>When diagnosed with a mental disorder, your first instinct may be to either ignore it or try to hide it. This is when any neurodivergent will struggle as you haven’t yet learned what processes work for you. Instead, you attempt to fit into what you believe works for everyone else.</p>

                <h2>How therapy can help.</h2>

                <p>Talking therapy is one of the most beneficial ways to not only support you through your diagnosis but also provide tools and support long term.</p>

                <p>Through psychotherapy, you can come to an understanding of what this means for you long-term.</p>

                <p>CBT is also another form of therapy that offers coping mechanisms in your day-to-day life. This can be in the form of tools to help you process and express yourself efficiently, or a space to learn from your past experiences and rework them for the future.</p>

                <p>Both of these forms of therapy are helpful in learning your strengths and capabilities, and what you can do with them.</p>

                <h2>How <em>Therapy in London</em> can help.</h2>

                <p>Being neurodivergent doesn’t have to create limits or barriers for you. Our practitioners want to teach you that through a clear understanding of how you see the world, you are no less able than anyone else.</p>

                <p>We are able to be your support in learning who you are able what you want and helping you create those possibilities for yourself.</p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default Neurodiversity;
