import { useQuery } from "@tanstack/react-query";
import { motion } from "framer-motion";
import propTypes from "prop-types";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getSpecialisms } from "../../app/api/profile/getSpecialisms";
import { useSearchStore } from "../../app/stores/searchStore";
import "../../styles/global.scss";
import "../../styles/sections.scss";
import ZigGreen from "../animations/ZigZagGreen";
import InputGoogle from "./InputGoogle";

const slideUpVariants = {
  hidden: { y: 100, opacity: 0 },
  visible: { y: 0, opacity: 1, transition: { duration: 0.5 } },
};

const RotateLeft = ({ className, children }) => (
  <motion.div
    initial={{ rotate: 0 }}
    animate={{ rotate: -1 }}
    transition={{ ease: "easeOut", duration: 1 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    className={className}
  >
    {children}
  </motion.div>
);

const RotateRight = ({ className, children }) => (
  <motion.div
    initial={{ rotate: 0 }}
    animate={{ rotate: 2 }}
    transition={{ ease: "easeOut", duration: 1 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    className={className}
  >
    {children}
  </motion.div>
);

function Search() {
  const [inputValue, setInputValue] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [inputError, setInputError] = useState(false);
  const navigate = useNavigate();
  const {
    specialisms,
    setLocation,
    setSpecialisms,
    getSearchParams,
    setRadius,
    getDefaultRadius,
    setPrecise,
  } = useSearchStore();
  const inputRef = useRef({ value: "", error: "" });

  const { data } = useQuery({
    queryKey: ["specialisms"],
    queryFn: () => getSpecialisms(),
  });

  const handleInputChange = useCallback((event) => {
    setInputValue(event.target.value);
    setShowDropdown(true);
  }, []);

  const handleClear = () => {
    setLocation({
      formattedAddress: "",
    });
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const handleInputError = (hasError) => {
    setInputError(hasError);
  };

  const handleSpecialismSelect = useCallback(
    (specialism) => {
      setSpecialisms([...specialisms, specialism]);
      setInputValue("");
      setShowDropdown(false);
    },
    [specialisms, setSpecialisms],
  );

  const handleSpecialismRemove = useCallback(
    (specialismId) => {
      setSpecialisms(specialisms.filter((s) => s.id !== specialismId));
    },
    [specialisms, setSpecialisms],
  );

  const filteredSpecialisms = useMemo(() => {
    if (!data || !data.specialisms) return [];
    return data.specialisms.filter(
      (specialism) =>
        specialism.name.toLowerCase().includes(inputValue.toLowerCase()) &&
        !specialisms.some((selected) => selected.id === specialism.id),
    );
  }, [data, inputValue, specialisms]);

  const handlePlaceSelected = (place) => {
    const addressComponents = place.address_components;

    const locality = addressComponents.find((c) =>
      c.types.includes("locality"),
    );
    const postalTown = addressComponents.find((c) =>
      c.types.includes("postal_town"),
    );

    const nonCityNames = ["richmond", "barnet"];

    let precise = false;
    const isLocalityDefined = !!locality?.long_name;
    const isPostalTownDefined = !!postalTown?.long_name;

    const areNamesEqual =
      isLocalityDefined &&
      isPostalTownDefined &&
      locality.long_name.toLowerCase() === postalTown.long_name.toLowerCase();

    const isExcluded = isLocalityDefined
      ? nonCityNames.includes(locality.long_name.toLowerCase())
      : false;

    const isCity = areNamesEqual && !isExcluded;

    const hasAdditionalInfo = addressComponents.some((c) => {
      const a = ![
        "administrative_area_level_1",
        "administrative_area_level_2",
        "country",
        isCity ? "locality" : "",
        isCity ? "postal_town" : "",
      ].includes(c.types[0]);
      return a;
    });

    if (isCity) {
      precise = false;
    } else if (hasAdditionalInfo) {
      precise = true;
    } else {
      precise = false;
    }
    const newLocation = {
      country:
        addressComponents.find((c) => c.types.includes("country"))?.long_name ||
        "",
      city: locality?.long_name || postalTown?.long_name || "",
      county:
        addressComponents.find((c) =>
          c.types.includes("administrative_area_level_2"),
        )?.long_name ||
        addressComponents.find((c) =>
          c.types.includes("administrative_area_level_1"),
        )?.long_name,
      postCode:
        addressComponents.find((c) => c.types.includes("postal_code"))
          ?.long_name || "",
      formattedAddress: place.formatted_address,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
    };
    setLocation(newLocation);
    setPrecise(precise);
    setRadius(getDefaultRadius());
    if (inputRef.current) {
      inputRef.current.value = place.formatted_address;
    }
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    handleClear();
    setRadius(40);
    setPrecise(false);
    setSpecialisms([]);
  }, []);

  const handleSearch = () => {
    const params = getSearchParams();
    navigate(`/results?${params.toString()}`);
  };

  return (
    <div className="homeSplash_search">
      <motion.div
        className="envelopeWrap"
        variants={slideUpVariants}
        initial="hidden"
        animate="visible"
      >
        <div className="envelope">
          <picture>
            <source
              srcSet="images/illustrations/envelope.webp"
              type="image/webp"
            />
            <img
              src="images/illustrations/envelope.png"
              alt="envelope"
              width="940px"
              height="530px"
            />
          </picture>
        </div>

        <RotateLeft>
          <motion.div
            className="search_wrap_specialism"
            variants={slideUpVariants}
            initial="hidden"
            animate="visible"
          >
            <picture>
              <source
                srcSet="images/illustrations/stamp.webp"
                type="image/webp"
              />
              <img
                src="images/illustrations/stamp.png"
                alt="stamp"
                width="120"
                height="120"
              />
            </picture>

            <label>Find a Therapist</label>
            <div className="search_1">
              <h2>
                What do you{" "}
                <span>
                  {" "}
                  need help <ZigGreen />
                </span>{" "}
                with?
              </h2>
              <div className="search_input_wrap">
                <input
                  placeholder="e.g. Anxiety"
                  value={inputValue}
                  onChange={handleInputChange}
                />
                {showDropdown && filteredSpecialisms.length > 0 && (
                  <ul className="specialism-dropdown">
                    {filteredSpecialisms.map((specialism) => (
                      // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
                      <li
                        key={specialism.id}
                        onClick={() => handleSpecialismSelect(specialism)}
                      >
                        {specialism.name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="selected-specialisms">
                {specialisms.map((specialism) => (
                  <span key={specialism.id} className="selected-specialism">
                    {specialism.name}
                    <button
                      type="button"
                      onClick={() => handleSpecialismRemove(specialism.id)}
                    >
                      ×
                    </button>
                  </span>
                ))}
              </div>
            </div>
            {/* <button>I'm not sure..</button> */}
          </motion.div>
        </RotateLeft>
      </motion.div>

      <RotateRight>
        <motion.div
          className="search_wrap_location"
          variants={slideUpVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="searchMask"></div>
          <div className="search_2">
            <h2>Where are you based?</h2>
            <div className="search_input_wrap">
              <InputGoogle
                placeholder="e.g. Shoreditch"
                onPlaceSelected={handlePlaceSelected}
                label="Search"
                type="Text"
                name="locationSearch"
                ref={inputRef}
                onClear={handleClear}
                onError={handleInputError}
                onChange={(e) => {
                  setLocation({
                    ...location,
                    formattedAddress: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <button
            type="button"
            onClick={handleSearch}
            className="cta_ghost_white"
            disabled={inputError}
          >
            Find a Therapist
          </button>
        </motion.div>
      </RotateRight>
    </div>
  );
}

RotateLeft.propTypes = {
  children: propTypes.node,
  className: propTypes.string,
};

RotateRight.propTypes = {
  children: propTypes.node,
  className: propTypes.string,
};

export default Search;
