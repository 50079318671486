import "../../../styles/global.scss";
import "../../../styles/sections.scss";
import "../../../styles/articles.scss";
import PostIt from "../../../components/elements/findhelpfor/postIt";
import { motion } from "framer-motion";

// Define animation variants
const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } }
};

function Confidence() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../find-help-for">Find Help For..</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
          <img src="/images/icons/chevron_right.svg" className="chevron" alt="Chevron right" />
          <div className="breadcrumb current_page">
            <a href="#">Confidence</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Therapy for Confidence</h1>
                    <img src="/images/illustrations/purpleScribble.svg" className="titleScribble" alt="Purple scribble decoration" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="/images/articles/findHelpFor/confidence.jpg" alt="Confidence image" />
                </div>
              </div>
              <div className="content">
                <h2>It is common to lose confidence and this can happen for many reasons.</h2>
                <p>Confidence doesn’t have to mean being extraverted, a performer, or a generally loud person. Being fully confident means understanding who you are, what you want from life, and being able to accept failure.</p>
                <p>Once you understand these things, you are able to direct your confidence in any way you wish. Whether that be simply starting a conversation with a stranger, or being able to perform at an open-mic night.</p>

                <h2>What causes confidence issues?</h2>
                <p>Confidence issues can evolve from several factors in your past:</p>
                <ul>
                  <li>Being bullied and criticised</li>
                  <li>A lack of stability. E.g. moving home several times as a child</li>
                  <li>Losing your job</li>
                  <li>Underlying mental health issues such as anxiety and depression</li>
                  <li>A period of isolation, such as Covid lockdown or maternity leave.</li>
                </ul>
                <p>These issues and experiences create a narrative for you, telling you to avoid failure or negative attention. Your natural instinct will tell you to avoid any situation that may make you feel bad like it had in the past. When you avoid new situations, you can eventually feel unable to execute confidence, as that situation feels unfamiliar and therefore ‘dangerous’.</p>
                <p>For example, maternity and paternity leave can be an isolating experience for a lot of new parents. When you are sleep-deprived and still learning the ways of the parenting world, it can take a while to feel like yourself again. This lifestyle change can have an impact on your confidence. You are readjusting to new environments and a new version of yourself.</p>
                <p>Lacking confidence is a habit that can be reworked just as fast as it was lost. First, you need to decide where and when you feel unconfident and why you want to change that. Then you are able to make the next steps to change how you feel about those situations.</p>

                <h2>How therapy can help.&nbsp;</h2>
                <p>Therapy is the ideal place to understand why you have lost your confidence and a safe space to test yourself and build it back up again. This is because going back and pinpointing where your confidence began to decrease will also help you to reverse that for the better.</p>
                <p>Whether it be a moment of rejection or a new living situation, you can feel more confident than ever by understanding and confronting those feelings you had been ignoring.</p>

                <h2>How <em>Therapy in London</em> can help.</h2>
                <p>We use various techniques and modalities to understand who you are and where you want to go. We are then able to tailor your journey, bring back confidence in yourself and your plans for a happier future.</p>
                <p>Feeling a lack of confidence and beginning a journey to change can feel overwhelming, especially as the most effective way to do this is by ‘going out of your comfort zone’.&nbsp;</p>
                <p>What we want to do is expand your comfort zone to the point where it feels natural and as if it was always there to begin with.</p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default Confidence;
