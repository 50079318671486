import "../../../styles/global.scss";
import "../../../styles/sections.scss";
import "../../../styles/articles.scss";
import PostIt from "../../../components/elements/findhelpfor/postIt";
import { motion } from "framer-motion";

const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } }
};

function Trauma() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../find-help-for">Find Help For..</a>
            <img src="/images/illustrations/underline.svg" />
          </div>
          <img src="/images/icons/chevron_right.svg" className="chevron" />
          <div className="breadcrumb current_page">
            <a href="#">Trauma</a>
            <img src="/images/illustrations/underline.svg" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Therapy for Trauma</h1>
                    <img src="/images/illustrations/purpleScribble.svg" className="titleScribble" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="/images/articles/findHelpFor/trauma.jpg" />
                </div>
              </div>
              <div className="content">
                <h2>Trauma occurs when you experience something physically or emotionally painful and shocking.</h2>
                <p>We associate the <a href="https://www.mind.org.uk/information-support/types-of-mental-health-problems/trauma/about-trauma/" target="_blank" rel="noreferrer noopener">term <em>trauma</em></a> with life-changing events. But anything that feels traumatic for you is just as valid as anyone else’s experiences. This article focuses on any traumatic, shocking or upsetting event in your life.</p>
                <p>We cannot completely prevent these events. But we can address them after they have happened. It can happen to anyone but is it possible to learn to understand it, deal with it and live a normal, happy life.</p>
                <p>The reason trauma occurs is because an experience or event was unexpected and out of your comfort zone. It’s important to address it as early as possible and to not allow for any long-lasting mental effects such as:</p>
                <ul>
                  <li>Irrational fears and phobias</li>
                  <li>Losing motivation</li>
                  <li>Disengaging with friends and family</li>
                  <li>Turning to substances (alcohol, drugs) or crime</li>
                </ul>
                <p>It can be instinctual to cover it up and continue with your life as normal and as soon as possible. When you bury the emotional burden that trauma carries however, you are not allowing yourself to deal with what you have experienced.</p>
                <p>As with anything distressing, but especially when dealing with something that can have a lasting impact on your life, you must reframe the experience and your feelings in order to process and move forward.</p>
                <h2>What causes trauma.</h2>
                <p>You can incur trauma from any situation. General reasons for trauma include:</p>
                <ul>
                  <li>A dysfunctional or abusive family</li>
                  <li>Witnessing a crime or being involved in one</li>
                  <li>The death of a loved one</li>
                  <li>Peer pressures</li>
                </ul>
                <p>There cannot be a definitive list of things that will cause trauma to you. Because of that, it cannot be actively avoided.</p>
                <p>When involved in a traumatic event, you will usually go through life believing that you could have taken control of it or you could have done something to avoid it (which is common in PTSD).</p>
                <p>Trauma comes from something that you would never have chosen to have been involved in, therefore if you wouldn’t have chosen it for yourself, you wouldn’t have had the control to stop it from happening.</p>
                <p>Especially during your childhood, your mind is constantly absorbing, developing and learning and you cannot say what you will, or will not, see or experience.</p>
                <p>Once accepting that you have experienced trauma, what you can do is relate your trauma back to what happened specifically within your life. How did you react to it and what patterns formed from that experience?</p>
                <h2>How therapy can help you.</h2>
                <p>When revisiting trauma, the most beneficial way to do this is with professional support and guidance. Traumatic experiences have a lasting impact and so going through them again should be done with the care and consideration that those memories need.</p>
                <p>Professional counselling offers a step-by-step process of uncovering that trauma, what happened and your part in it. Through these discussions, you are able to create a new understanding of those memories without them leaving a negative scar on your future. This process allows you to uncover, understand, accept it, and then reform those memories for the future.</p>
                <h2>How <em>Therapy in London</em> can help.</h2>
                <p>We understand that your trauma is something that only you can comprehend, trauma can feel isolating and lonely. What our practitioners want to do is be a stable and consistent support in revisiting those experiences. We help you to create a new narrative for your life that doesn’t involve something that happened in your past.</p>
                <p>From doing this you can see your future in a whole new light, and every decision you make for yourself won’t involve a retelling of your trauma.</p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default Trauma;
