import { Link } from "react-router-dom";
import "../../styles/global.scss";
import "../../styles/sections.scss";

import propTypes from "prop-types";

SearchResultsArticles.propTypes = {
  link: propTypes.string,
  imgSrc: propTypes.string,
  title: propTypes.string,
  author: propTypes.string,
  authorId: propTypes.string,
};

function SearchResultsArticles(props) {
  const truncateTitle = (title) => {
    return title.length > 40 ? `${title.substring(0, 40)}...` : title;
  };

  return (
    <div className="article_block">
      <Link to={props.link}>
        <div className="image_wrap_border">
          <div className="octagon-container">
            <img
              className="octagon-image"
              src={props.imgSrc}
              alt="therapist-profile-image"
            />
          </div>
        </div>
        <div className="article_details">
          <h3>{truncateTitle(props.title)}</h3>{" "}
          <label>
            <Link to={`/results/profile/${props.authorId}`}>{props.author}</Link>
          </label>{" "}
        </div>
      </Link>

    </div>
  );
}

export default SearchResultsArticles;
