import { zodResolver } from "@hookform/resolvers/zod";
import { useRef, useState, useEffect } from "react";
import { useSendPasswordResetEmail } from "react-firebase-hooks/auth";
import { Controller, useForm } from "react-hook-form";
import { auth } from "../../../app/lib/firebaseClient";
import Loader from "../../../components/elements/loader";
import CTA from "../../../components/fragments/CTA";
import "../../../styles/buttons.scss";
import "../../../styles/global.scss";
import "../../../styles/signup.scss";
import { forgotPasswordSchema } from "../../schemas";

function ForgotPassword() {

  useEffect(() => {
    // Set body background color to blue for this page
    document.body.style.backgroundColor = "#01C476";

    // Cleanup function to reset background color when leaving the page
    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []);

  const [showSignUpBlock2, setShowSignUpBlock2] = useState(false);
  const [showNextButton, setShowNextButton] = useState(true);
  const passwordInputRef = useRef(null);
  const [sendPasswordResetEmail, loading, error] =
    useSendPasswordResetEmail(auth);

  const form = useForm({
    mode: "onBlur",
    resolver: zodResolver(forgotPasswordSchema),
  });

  const handleNextClick = () => {
    setShowSignUpBlock2(true);
    setShowNextButton(false); // Hide the Next button after it's clicked
    if (passwordInputRef.current) {
      passwordInputRef.current.focus(); // Focus on the password input
    }
  };

  const onSubmit = async (data) => {
    try {
      await sendPasswordResetEmail(data.email);
      handleNextClick();
    } catch (error) {
      console.error("Sign-in failed", error);
    }
  };
  const emailError = form.formState.errors?.email?.message || error?.message;

  if (loading) {
    return <Loader subTitle="Sending password reset email" />;
  }

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <div className="signUp_wrap backgroundGreen">
        <div className="signUp_center">
          <div className="pageTitle">
            <h1>Forgot Password</h1>
          </div>
          <div className="signUp_block">
            <div className="count">
              <label>1 of 2</label>
            </div>
            <div className="block">
              <div className="title">
                <h2>Enter the email associated to your account</h2>
                <img
                  src="../../../images/illustrations/orangeScribble.svg"
                  alt="Scribble"
                />
              </div>
              <div className="input">
                <Controller
                  control={form.control}
                  name="email"
                  rules={{ required: true }}
                  defaultValue=""
                  render={({ field }) => (
                    <input
                      type="text"
                      placeholder="Type Here"
                      {...field}
                    />
                  )}
                />
                {emailError && <span className="errorNote">{emailError}</span>}
              </div>


              <div className="controls">
                {showNextButton && (
                  <>
                    <button
                      type="submit"
                      className="cta_ghost_blue"
                    >
                      Send Reset Link
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>

          <div
            className={`signUp_block2 backgroundBlue passwordSentBlock ${showSignUpBlock2 ? "fade-in" : ""
              }`}
          >
            <div className="block">
              <div className="title">
                <h2>A password reset links has been sent to your email</h2>
              </div>
              <div className="controls">
                <CTA
                  type="ghost_white"
                  text="Return to Sign In"
                  link="/portal/signin"
                />
              </div>
            </div>
          </div>

          <div className="subLink">
            <CTA type="ghost_white" text="Return Home" link="/" />
          </div>
        </div>
      </div>
    </form>
  );
}

export default ForgotPassword;
