import "../../../styles/global.scss";
import "../../../styles/sections.scss";
import "../../../styles/articles.scss";
import PostIt from "../../../components/elements/findhelpfor/postIt";
import { motion } from "framer-motion";

// Define animation variants
const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } }
};

function Hypervigilance() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../find-help-for">Find Help For..</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
          <img src="/images/icons/chevron_right.svg" className="chevron" alt="Chevron right" />
          <div className="breadcrumb current_page">
            <a href="#">Hypervigilance</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Therapy for Hypervigilance</h1>
                    <img src="/images/illustrations/purpleScribble.svg" className="titleScribble" alt="Purple scribble decoration" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="/images/articles/findHelpFor/hypervigilance.jpg" alt="Hypervigilance" />
                </div>
              </div>
              <div className="content">
                <p>Before understanding hypervigilance, it’s important to differentiate between this and everybody’s regular brain function surrounding anxiety and suspected danger.</p>

                <p>In order to survive, we are instinctively aware of anything that might be perceived as a danger to us. Anxiety will trigger a fight or flight response when we are faced with it.</p>

                <p>This reaction to fear and danger is what keeps us safe and teaches us how to combat these dangers for the future.</p>

                <p>Anxiety spikes awareness of something you need to learn or what to avoid. Often something can startle your regular response and how you relate to your anxiety. This is when you can develop a fear of even potential anxiety and is when hypervigilance comes into play.</p>

                <h2>What causes hypervigilance?</h2>

                <p>A state of hypervigilance is an attempt to keep you and others safe, but essentially with no respite in between.</p>

                <p>This can be an exhausting feeling of:</p>

                <ul>
                  <li>Little to no trust in anyone</li>
                  <li>Unable to rest knowing someone is sad, annoyed or in trouble (whether or not it is to do with you)</li>
                  <li>Overthinking your thoughts and emotions</li>
                  <li>Fear of setbacks or failures</li>
                  <li>A paranoia that you have upset people</li>
                </ul>

                <p>When your usual judgment of what is and isn’t safe is interrupted, it is usually from:</p>

                <ul>
                  <li>A traumatic event or experience</li>
                  <li>Neglect</li>
                  <li>Abuse</li>
                  <li>Bullying</li>
                </ul>

                <p>These kinds of situations and experiences can make you feel as if your safety has been taken out of your control. When that trust in the outside world depreciates, it can be very difficult to rest and feel somewhat ‘safe’.</p>

                <p>Your hypervigilance can affect not just your own felt safety, but also others. From your experiences, you can be hypervigilant of other people’s unease, discomfort, or upset.</p>

                <p>When taking into account what has made you hypervigilant, it is important to understand that this is something to be reworked, rather than removed completely.</p>

                <p>Your safety is important, but you can only grow by learning from anxiety and fear. This means creating a new narrative for how you relate to that fear.</p>

                <h2>How therapy can help.</h2>

                <p>The most common desired outcome for therapy is to rework how you relate to your past experiences. Whether those experiences were positive, or negative, your relationship with them is what has brought you to this point of hypervigilance.</p>

                <p>During therapy you are able to learn from your past and understand what your hypervigilance is trying to control. Your heightened awareness can be debilitating and hold you back from many things, which is why therapy can be the most beneficial way of reworking your current narrative.</p>

                <h2>How <em>Therapy in London</em> can help.</h2>

                <p>You may be one of many people in a state of hypervigilance, yet not knowing why you cannot feel a sense of control or contentment over your life.</p>

                <p>This is why our practitioners aim to bring to light anything that may have been holding you back, and a new understanding of our past.</p>

                <p>Your hypervigilance doesn’t have to be a constant barrier, you are simply trying to protect yourself from history repeating itself. This doesn’t have to be the case for the future, which is why we aim to offer you that chance to change those habits for the better.</p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default Hypervigilance;
