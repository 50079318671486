class SmoothScroll {
  constructor(options = {}, target = window) {
    this.getScrollData = this.getScrollData.bind(this);
    this.onScroll = this.onScroll.bind(this);
    this.animateScroll = this.animateScroll.bind(this);
    this.onScrollEnd = this.onScrollEnd.bind(this);
    this.scrollTo = this.scrollTo.bind(this);
    this.onAnimationEnd = this.onAnimationEnd.bind(this);

    let allOptions = {
      momentum: 0.7, // Adjust for more or less momentum. Recommended range (0.6 - 1.2)
      speed: 0.4 // Adjust for faster/slower scroll. Recommended range (1 - 1.2)
    };

    for (let k in options) {
      allOptions[k] = options[k];
    }

    this.settings = allOptions;

    this.target = target;
    this.lastScrollTop = window.scrollY;
    this.lastTimestamp = performance.now();
    this.ticking = false;
    this.scrollSpeed = 0;
    this.targetScroll = window.scrollY;
    this.currentScroll = window.scrollY;
    this.nativeScroll = false;

    this.getScrollData();

    this.target.addEventListener("scroll", this.onScroll, { passive: true });
    this.target.addEventListener("scrollend", this.onScrollEnd, { passive: true });

    this.target.scrollToNative = this.target.scrollTo;
    this.target.scrollTo = this.scrollTo;

    this.timeout = null;

    this.queuedScroll = null;
  }

  onScrollEnd(event) {
    this.nativeScroll = false;
  }

  scrollTo(coX, coY, behavior = "instant") {
    if (this.ticking) {
      this.queuedScroll = { coX: coX, coY: coY };
    } else {
      if (this.target.scrollX == coX && this.target.scrollY == coY) return;
      this.nativeScroll = true;
      this.target.scrollToNative({ top: coX, left: coY, behavior: behavior });
    }
  }

  getScrollData() {
    this.currentScrollTop = this.target.scrollY;
    this.currentTimestamp = performance.now();

    this.scrollDistance = Math.abs(this.currentScrollTop - this.lastScrollTop);
    let timeElapsed = this.currentTimestamp - this.lastTimestamp;
    this.scrollSpeed = (this.scrollDistance / timeElapsed) * this.settings.speed;

    this.targetScroll = this.currentScrollTop;

    this.lastScrollTop = this.currentScrollTop;
    this.lastTimestamp = this.currentTimestamp;
  }

  isTouchEvent(event) {
    return event != null && event.touches != null && event.touches[0] != null && event.touches[0].clientY != null;
  }

  onScroll(event) {
    if (!this.isTouchEvent(event) && !this.nativeScroll) {
      this.getScrollData();

      if (!this.ticking) {
        this.target.requestAnimationFrame(this.animateScroll);
        this.ticking = true;
      }
    }
  }

  onAnimationEnd() {
    if (this.ticking) return;

    if (this.queuedScroll != null) {
      let coX = this.queuedScroll.coX;
      let coY = this.queuedScroll.coY;
      this.queuedScroll = null;
      this.scrollTo(coX, coY);
    }
  }

  animateScroll() {
    this.currentScroll += (this.targetScroll - this.currentScroll) * this.settings.momentum;
    this.target.scrollToNative(0, this.currentScroll);

    if (Math.abs(this.targetScroll - this.currentScroll) > 0.5) {
      this.target.requestAnimationFrame(this.animateScroll);
    } else {
      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(this.onAnimationEnd, 100);
      this.ticking = false;
    }
  }
}

export default SmoothScroll;