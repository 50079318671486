import "../../../styles/global.scss";
import "../../../styles/sections.scss";
import "../../../styles/articles.scss";
import PostIt from "../../../components/elements/findhelpfor/postIt";
import { motion } from "framer-motion";

const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

function Marriage() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../../counselling-services/">Types of Therapy</a>
            <img src="../../images/illustrations/underline.svg" />
          </div>
          <img src="../../images/icons/chevron_right.svg" className="chevron" />
          <div className="breadcrumb current_page">
            <a href="#">Marriage Counselling</a>
            <img src="../../images/illustrations/underline.svg" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView ">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={containerVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Marriage Counselling</h1>
                    <img src="../../images/illustrations/purpleScribble.svg" className="titleScribble" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="../../images/articles/typesOfTherapy/article/marraige.jpg" />
                </div>
              </div>
              <div className="content">
                <p>
                  The premise of marriage is to commit to another person ‘till death do
                  us part‘. When you make that decision, it is usually an easy one as you
                  have found that one person you believe that you can commit to.
                </p>
                <p>
                  What is difficult to grasp for many during marriage is that commitment
                  includes a parallel growth with that partner. When you commit to your
                  one-and-only, you are committing to who they were, are now and will
                  become.
                </p>
                <p>
                  Everyone must adapt, change and develop as a person independently and
                  within a relationship. This includes:
                </p>
                <ul>
                  <li>Likes and dislikes</li>
                  <li>Career</li>
                  <li>Passions</li>
                  <li>Beliefs</li>
                  <li>Humour</li>
                  <li>Friends and colleagues</li>
                </ul>
                <p>
                  Your changes must always complement one another, or you might find it
                  difficult to have the connection you once had.
                </p>
                <h2>What marriage counselling can help you with.</h2>
                <p>
                  Making the decision to begin marriage counselling doesn’t have to mean
                  that your rift is near the point of no return. When you and your
                  partner drift, you can start to resist confrontation. This leads to
                  resentments and hangups that build up and become a larger issue than
                  they once were.
                </p>
                <p>
                  Marriage gives a more palpable and legal term to your relationship.
                  Therefore, it can feel the easiest way to save your marriage is to hold
                  back what you want to say.
                </p>
                <p>
                  But confrontation doesn’t have to mean there is breakdown in your
                  relationship.
                </p>
                <p>
                  Unfortunately, this avoidance happens when communication goes out the
                  window and distance grows. This can lead to:
                </p>
                <ul>
                  <li>Adultery</li>
                  <li>
                    Not being able to talk to each other without it starting an argument
                  </li>
                  <li>
                    Doing more activities separately than together: Such as dinner, going
                    out and sleeping.
                  </li>
                  <li>Lying regularly</li>
                  <li>Less or no sex</li>
                  <li>
                    Affecting your family dynamic and connection with your children.
                  </li>
                </ul>
                <h3>
                  <strong>
                    In the circumstances where your partner is physically or mentally
                    abusive towards you or your family, contact the{' '}
                    <a
                      href="https://www.nationaldahelpline.org.uk/"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      National Domestic Abuse Helpline
                    </a>{' '}
                    right away for support.
                  </strong>
                </h3>
                <p>
                  All marriages must open up a line of communication from as early as
                  possible, and this can be difficult when you have gotten used to
                  withholding for so long. This is where the neutral space within a
                  counselling office has the largest benefit.
                </p>
                <p>
                  Counselling encourages you to talk to your partner and (importantly)
                  listen to what they have to say too.
                </p>
                <p>
                  Mental health professionals are able to be a non-judgment figure within
                  your marriage who encourage you to answer and even ask open-ended
                  questions to each other. These discussions result in you being able to
                  understand your partner’s feelings and for you to convey your own
                  without any false accusations, assumptions or personal attacks.
                </p>
                <p>
                  Your counsellor will always remain unbiased and this offers a very
                  beneficial neutral space for you to not just learn about your partner,
                  but also who you are and how you are seen too.
                </p>
                <h2>How Therapy in London can help.</h2>
                <p>
                  Our practitioners have the ability of being a strong presence in your
                  marriage but also making your partnership as close as it has ever been.
                </p>
                <p>
                  We want to ensure that you know exactly who your partner is, what they
                  want and how you can both grow together. This comes from your counsellor
                  offering a safe, relaxed and trusting space for you and your partner,
                  and this is what we offer to marriages in need of a new outlook on
                  their relationship.
                </p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default Marriage;
