import "../../styles/global.scss";
import "../../styles/sections.scss";
import "../../styles/articles.scss";
import "../../styles/cookies.scss";
import { motion } from "framer-motion";

const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

export const CPolicy = () => {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../">Home</a>
            <img src="../../images/illustrations/underline.svg" />
          </div>
          <img src="../../images/icons/chevron_right.svg" className="chevron" />
          <div className="breadcrumb current_page">
            <a href="#">Cookies Policy</a>
            <img src="../../images/illustrations/underline.svg" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView ">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={containerVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Cookies Policy</h1>
                    <img
                      src="../../images/illustrations/purpleScribble.svg"
                      className="titleScribble"
                    />
                  </div>
                </div>
              </div>
              <div className="content">
                <h2>1. INTRODUCTION</h2>
                <p>
                  1.1 Our Website (
                  <a href="https://www.therapyin.london" target="_blank">
                    https://www.therapyin.london
                  </a>
                  ) uses cookies and similar technologies. This helps us to
                  provide you with a good experience when you browse our Website
                  and also allows us to improve our Website.
                </p>
                <p>
                  1.2 Cookies are small text files that are downloaded to your
                  device (e.g. your computer or smartphone). Cookies contain
                  uniquely generated references which are used to distinguish
                  you from other users. They allow information gathered on one
                  webpage to be stored until it is needed for use on another,
                  allowing our Website to provide you with a personalised
                  experience (like remembering your favourites) and provide us
                  with statistics about how you interact with our (and sometimes
                  third party) Website.
                </p>
                <p>
                  1.3 Cookies are not harmful to your devices (like a virus or
                  malicious code) but some individuals prefer not to share their
                  information (for example, to avoid targeted advertising).
                </p>

                <h2>2 DIFFERENT TYPES OF COOKIES</h2>
                <p>
                  2.1 Session vs. persistent cookies: cookies have a limited
                  lifespan. Cookies which only last a short time or end when you
                  close your browser are called session cookies. Cookies which
                  remain on your device for longer are called persistent cookies
                  (these are the type of cookies that allow websites to remember
                  your details when you log back onto them).
                </p>
                <p>
                  2.2 First party vs. third party cookies: cookies placed on
                  your device by the website owner are called first party
                  cookies. When the website owner uses other businesses’
                  technology to help them manage and monitor their website, the
                  cookies added by the other business are called third party
                  cookies.
                </p>
                <p>
                  2.3 Categories of cookies: cookies can be grouped by what they
                  help the website or website owner do:
                </p>
                <ul>
                  <li>
                    Necessary cookies are cookies which help the website to run
                    properly (when they are strictly necessary cookies it means
                    their only function is to help the website work);
                  </li>
                  <li>
                    Performance / functionality cookies help a website owner
                    understand and analyse how a user uses a website, in order
                    to personalise content and remember user preferences;
                  </li>
                  <li>
                    Analytical cookies are used to understand how visitors
                    interact with the website. These cookies help provide
                    information on metrics such as the number of visitors,
                    bounce rate, etc.
                  </li>
                </ul>

                <h2>3 WHAT DO WE USE COOKIES FOR?</h2>
                <p>3.1 We use cookies:</p>
                <ul>
                  <li>to track how visitors use our Website;</li>
                  <li>to keep you signed into our Website;</li>
                  <li>
                    where we post content and links to content, we use cookies
                    to capture and analyse information such as the number of
                    views and shares.
                  </li>
                </ul>

                <h2>4 WHAT COOKIES DO WE USE?</h2>
                <p>4.1 The cookies we use on our Website are:</p>
                <table className="termsTable">
                  <thead>
                    <tr>
                      <th>Cookie</th>
                      <th>Purpose</th>
                      <th>What it does</th>
                      <th>How long it lasts</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td data-label="Cookie Name">__session</td>
                      <td data-label="Purpose">Authentication</td>
                      <td data-label="What It Does">
                        Stores session data for authenticated users, helping to
                        maintain user state across pages.
                      </td>
                      <td data-label="Duration">End of browser session</td>
                    </tr>
                    <tr>
                      <td data-label="Cookie Name">_ga</td>
                      <td data-label="Purpose">Analytics</td>
                      <td data-label="What It Does">
                        Used to distinguish users for analytics purposes.
                      </td>
                      <td data-label="Duration">2 years</td>
                    </tr>
                    <tr>
                      <td data-label="Cookie Name">_gid</td>
                      <td data-label="Purpose">Analytics</td>
                      <td data-label="What It Does">
                        Used to distinguish users, but with a shorter lifespan.
                      </td>
                      <td data-label="Duration">24 hours</td>
                    </tr>
                    <tr>
                      <td data-label="Cookie Name">_gat</td>
                      <td data-label="Purpose">Analytics</td>
                      <td data-label="What It Does">
                        Throttles request rates for analytics data.
                      </td>
                      <td data-label="Duration">1 minute</td>
                    </tr>
                    <tr>
                      <td data-label="Cookie Name">__stripe_mid</td>
                      <td data-label="Purpose">Payment Security</td>
                      <td data-label="What It Does">
                        Used to identify a user’s device for payment security
                        purposes and to prevent fraud.
                      </td>
                      <td data-label="Duration">1 year</td>
                    </tr>
                    <tr>
                      <td data-label="Cookie Name">__stripe_sid</td>
                      <td data-label="Purpose">Payment Processing</td>
                      <td data-label="What It Does">
                        Stores session data related to the payment process,
                        ensuring security.
                      </td>
                      <td data-label="Duration">30 minutes</td>
                    </tr>
                    <tr>
                      <td data-label="Cookie Name">_dd_s</td>
                      <td data-label="Purpose">Performance Monitoring</td>
                      <td data-label="What It Does">
                        Used by Datadog for session-based performance
                        monitoring, helping track how the application is
                        performing in real-time.
                      </td>
                      <td data-label="Duration">4 hours</td>
                    </tr>
                    <tr>
                      <td data-label="Cookie Name">_ga</td>
                      <td data-label="Purpose">Analytics</td>
                      <td data-label="What It Does">
                        Used to distinguish users by assigning a unique ID to
                        each user.
                      </td>
                      <td data-label="Duration">2 years</td>
                    </tr>
                    <tr>
                      <td data-label="Cookie Name">_gid</td>
                      <td data-label="Purpose">Analytics</td>
                      <td data-label="What It Does">
                        Used to distinguish users by assigning a unique ID,
                        similar to _ga, but with a shorter lifespan.
                      </td>
                      <td data-label="Duration">24 hours</td>
                    </tr>
                    <tr>
                      <td data-label="Cookie Name">_gat</td>
                      <td data-label="Purpose">Analytics</td>
                      <td data-label="What It Does">
                        Used to throttle request rate, limiting the collection
                        of data on high-traffic sites.
                      </td>
                      <td data-label="Duration">1 minute</td>
                    </tr>
                    <tr>
                      <td data-label="Cookie Name">_gac_&lt;property-id&gt;</td>
                      <td data-label="Purpose">Analytics</td>
                      <td data-label="What It Does">
                        Contains campaign-related information for the user. If
                        you've linked your Google Analytics and Google Ads
                        accounts, this cookie will be read by the Google Ads
                        website conversion tags unless you opt out.
                      </td>
                      <td data-label="Duration">90 days</td>
                    </tr>
                    <tr>
                      <td data-label="Cookie Name">_utmz</td>
                      <td data-label="Purpose">Analytics</td>
                      <td data-label="What It Does">
                        Stores the traffic source or campaign that explains how
                        the user reached your site.
                      </td>
                      <td data-label="Duration">6 months</td>
                    </tr>
                    <tr>
                      <td data-label="Cookie Name">_utma</td>
                      <td data-label="Purpose">Analytics</td>
                      <td data-label="What It Does">
                        Used to distinguish users and sessions. It is updated
                        every time data is sent to Google Analytics.
                      </td>
                      <td data-label="Duration">2 years from set/update</td>
                    </tr>
                    <tr>
                      <td data-label="Cookie Name">_utmb</td>
                      <td data-label="Purpose">Analytics</td>
                      <td data-label="What It Does">
                        Used to determine new sessions/visits. It is updated
                        every time data is sent to Google Analytics.
                      </td>
                      <td data-label="Duration">30 minutes from set/update</td>
                    </tr>
                    <tr>
                      <td data-label="Cookie Name">_utmc</td>
                      <td data-label="Purpose">Analytics</td>
                      <td data-label="What It Does">
                        Operates in conjunction with the _utmb cookie to
                        determine whether the user is in a new session/visit.
                      </td>
                      <td data-label="Duration">End of browser session</td>
                    </tr>
                    <tr>
                      <td data-label="Cookie Name">_utmt</td>
                      <td data-label="Purpose">Analytics</td>
                      <td data-label="What It Does">
                        Used to throttle request rate.
                      </td>
                      <td data-label="Duration">10 minutes</td>
                    </tr>
                    <tr>
                      <td data-label="Cookie Name">_utmv</td>
                      <td data-label="Purpose">Analytics</td>
                      <td data-label="What It Does">
                        Used to store visitor-level custom variable data.
                      </td>
                      <td data-label="Duration">2 years from set/update</td>
                    </tr>
                  </tbody>
                </table>

                <p>
                  4.2 We can only use cookies with your permission (you will be
                  prompted by a message when you first visit our Website, also
                  known as a cookie banner, where you can choose to accept or
                  decline our cookies).
                </p>
                <p>
                  4.3 You can update your cookies settings and permissions on
                  our Website by opting out of cookies when prompted.
                </p>
                <p>
                  4.4 You can choose to decline cookies but if you turn off
                  necessary cookies, some pages and functions on our Website may
                  not work properly. You can also manage cookies through your
                  browser settings or device settings (your user manual should
                  contain additional information). You may wish to visit{" "}
                  <a
                    href="https://allaboutcookies.org/how-to-manage-cookies"
                    target="_blank"
                  >
                    https://allaboutcookies.org/how-to-manage-cookies
                  </a>{" "}
                  to find out how to disable cookies on different browsers.
                </p>
                <p>
                  4.5 You can also delete cookies and opt-out of being tracked
                  by certain cookies directly with the relevant third-parties
                  (for example, you can disable Google Analytics on their
                  website).
                </p>
                <p>
                  4.6 If you have any questions about our cookies, or how we
                  otherwise use your personal data, please visit our Privacy
                  Notice (
                  <a
                    href="https://www.therapyin.london/terms/privacy-policy"
                    target="_blank"
                  >
                    https://www.therapyin.london/terms/privacy-policy
                  </a>
                  ) for further details.
                </p>
              </div>
            </motion.div>
          </div>
        </div>
      </motion.div>
    </>
  );
}
