import React, { Suspense } from "react";
import PropTypes from "prop-types";
import "../../../styles/global.scss";
import "../../../styles/sections.scss";

var Quill, ReactQuill;
import("react-quill").then((module) => {
  Quill = module.Quill;
  ReactQuill = module.default;
});

Qa.propTypes = {
  question: PropTypes.string,
  answer: PropTypes.string,
};

function Qa(props) {
  return (
    <div className="qa_wrap">
      <div className="qa">
        <div className="title">
          {/* <img src="../images/icons/chevronOrange.svg" /> */}
          <h2>{props.question}</h2>
        </div>

        <Suspense fallback={<div>Loading...</div>}>
          <ReactQuill theme="bubble" value={props.answer} readOnly={true} />
        </Suspense>
      </div>
    </div>
  );
}

export default Qa;
