import { useLayoutEffect } from "react";
import { Outlet } from "react-router-dom";
import "../../styles/global.scss";
import NavBar from "../elements/navbar";
import MetaData from "../fragments/MetaData";

function LayoutSignUp() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MetaData />
      <div>
        <NavBar isSignUp={true} />
        <main>
          <Outlet />
        </main>
      </div>
    </>
  );
}

export default LayoutSignUp;
