import { useState } from "react";
import { useProfileStore } from "../../../app/stores";
import "../../../styles/global.scss";
import "../../../styles/profiles.scss";
import "../../../styles/sections.scss";

function FeesEdit() {
  const { pricePackage, updatePricePackage } = useProfileStore((state) => ({
    pricePackage: state.profile.pricePackage,
    updatePricePackage: state.updatePricePackage,
  }));
  const [errors, setErrors] = useState({});

  // Ensure we always have 3 fee components
  const displayPricePackage = [
    ...pricePackage,
    ...Array(3 - pricePackage.length).fill({ name: "", price: "" }),
  ].slice(0, 3);

  return (
    <div>
      <div className="edit_title">
        <h2>Fees</h2>
      </div>
      <div className="fees_wrap">
        {displayPricePackage.map((pkg, index) => (
          <div className="fees_block" key={index}>
            <div className="fees_content">
              <input
                className="edit_input feeTitle_input"
                placeholder="Package"
                value={pkg.name}
                onChange={(e) => {
                  const value = e.target.value;
                  updatePricePackage(index, "name", value);
                  if (value.length > 100) {
                    setErrors((prev) => ({
                      ...prev,
                      [`name${index}`]:
                        "Package name must be 100 characters or less",
                    }));
                  } else {
                    setErrors((prev) => ({ ...prev, [`name${index}`]: "" }));
                  }
                }}
              />
              {errors[`name${index}`] && (
                <span className="error">{errors[`name${index}`]}</span>
              )}

              <div className="fee">
                <input
                  className="edit_input feePrice_input"
                  placeholder="Price"
                  value={pkg.price}
                  onChange={(e) => {
                    const value = e.target.value;
                    updatePricePackage(index, "price", value);
                    if (value.length > 100) {
                      setErrors((prev) => ({
                        ...prev,
                        [`price${index}`]:
                          "Price must be 100 characters or less",
                      }));
                    } else {
                      setErrors((prev) => ({ ...prev, [`price${index}`]: "" }));
                    }
                  }}
                />
                {errors[`price${index}`] && (
                  <span className="error">{errors[`price${index}`]}</span>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FeesEdit;
