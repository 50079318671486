import "../../../styles/global.scss";
import "../../../styles/sections.scss";
import "../../../styles/articles.scss";
import PostIt from "../../../components/elements/findhelpfor/postIt";
import { motion } from "framer-motion";

// Define animation variants
const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } }
};

function Paranoia() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../find-help-for">Find Help For..</a>
            <img src="/images/illustrations/underline.svg" />
          </div>
          <img src="/images/icons/chevron_right.svg" className="chevron" />
          <div className="breadcrumb current_page">
            <a href="#">Paranoia</a>
            <img src="/images/illustrations/underline.svg" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Therapy for Paranoia</h1>
                    <img src="/images/illustrations/purpleScribble.svg" className="titleScribble" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="/images/articles/findHelpFor/paranoid.jpg" />
                </div>
              </div>
              <div className="content">
                <p>Not all cases of paranoia are the same. There is a wide spectrum of cases of paranoia. They can range from mild everyday cases that are easy to diagnose to more extreme cases of psychotic paranoia.</p>

                <p>If you have paranoia, you can feel extremely isolated and, in turn, this can lead to it taking a long time for you to get help. For some people, it makes it difficult to admit that they have symptoms. It can also be difficult to find a therapist that you trust. But once you have built that trust with someone, therapy will help you to reduce your paranoid symptoms over time.</p>

                <h2>What is Paranoia?</h2>

                <p>If you have paranoia, you feel and think that there are threats against you when there is little or no evidence that this is the case. Paranoid thoughts could sometimes be described as delusions.</p>

                <p>Paranoid thoughts could also be described as extreme suspicions. For example, you may think that a one-off negative comment made by someone is, in fact, part of a larger hate campaign against you.</p>

                <p>You may feel intense anxious or fearful feelings related to persecution or conspiracy. Paranoia can become a delusion when these irrational thoughts become fixed and nothing can convince you that what you think or feel is not true.</p>

                <p>Paranoia can be present in several <a href="https://www.therapyin.london/find-help-for/" target="_blank" rel="noreferrer noopener">mental disorders</a> including:</p>

                <ul>
                  <li><a href="https://www.therapyin.london/find-help-for/bpd/" target="_blank" rel="noreferrer noopener">Borderline Personality Disorder</a></li>
                  <li><a href="https://www.therapyin.london/find-help-for/ocd/" target="_blank" rel="noreferrer noopener">Obsessive Compulsive Disorder</a></li>
                  <li><a href="https://www.therapyin.london/find-help-for/bipolar-disorder/" target="_blank" rel="noreferrer noopener">Bipolar Disorder</a></li>
                  <li><a href="https://www.therapyin.london/find-help-for/body-dysmorphia/" target="_blank" rel="noreferrer noopener">Body Dysmorphia</a> and <a href="https://www.therapyin.london/find-help-for/eating-disorders/" target="_blank" rel="noreferrer noopener">Eating Disorders</a></li>
                </ul>

                <p>But a person can have paranoia without any other symptoms — in these cases, they may be able to function in everyday life but their life can feel limited and isolated.</p>

                <p>Paranoia symptoms can include:</p>

                <ul>
                  <li>Being defensive and aggressive</li>
                  <li>Having trouble letting your guard down</li>
                  <li>Being unable to forgive, or accept criticism</li>
                  <li>Being easily offended</li>
                  <li>Not being able to trust or confide in other people</li>
                  <li>Feeling there are hidden meanings in people’s behaviours</li>
                </ul>

                <h2>What causes paranoia?</h2>

                <p>While there are a lot of theories as to why people become paranoid, it is still largely unknown. Most likely, there are a few different things that have combined to result in paranoid thoughts. There are, however, some general risk factors that researchers have identified. These include:</p>

                <ul>
                  <li>Having low <a href="https://www.therapyin.london/find-help-for/low-self-esteem/" target="_blank" rel="noreferrer noopener">self-esteem</a> and an expectation that you will be rejected</li>
                  <li>Experiencing a lot of <a href="https://www.therapyin.london/find-help-for/anxiety/" target="_blank" rel="noreferrer noopener">anxious</a> or worried feelings</li>
                  <li>Being isolated</li>
                  <li>Past traumatic experiences</li>
                  <li>Experiencing extreme stress</li>
                  <li>Lack of sleep</li>
                </ul>

                <p>There are other conditions that can result in paranoia, too. For example, schizophrenia, paranoid personality disorder, Alzheimer’s or dementia. Borderline personality disorder can also include having feelings of paranoia.</p>

                <p>Another cause of paranoia can also be <a href="https://www.therapyin.london/find-help-for/addiction/" target="_blank" rel="noreferrer noopener">recreational drug or alcohol use</a>. If you are already experiencing feelings of <a href="https://www.therapyin.london/find-help-for/depression/" target="_blank" rel="noreferrer noopener">depression</a> or <a href="https://www.therapyin.london/find-help-for/anxiety/" target="_blank" rel="noreferrer noopener">anxiety</a>, you may be particularly at risk — but researchers still know very little about the connection between paranoia and recreational drug and alcohol use.</p>

                <h2>How therapy can help</h2>

                <p>Your therapist will encourage you to consider your paranoid beliefs and the evidence that you feel there is for them. From there you will look at the possible alternative interpretations. If you have anxiety, you can address it with therapy which can often have an influence on your feelings of paranoia.  You will work with your therapist to uncover these emotions so that you have agency in the process.</p>

                <p>It is understandable that you may feel defensive and suspicious when beginning therapy. So your therapist will work to first build trust with you so that you can feel comfortable with them. Therapy for your paranoia can be a gradual process. You should take it at your own pace and with whom you feel most comfortable.</p>

                <h2>How <em>Therapy in London</em> can help</h2>

                <p>Finding the right therapist will be an important part of your journey and Therapy in London will work to match you with the right person — someone that you can trust.</p>

                <p>Once you are matched with a therapist that you feel comfortable with, you will meet with them and work towards a place of trust. From there, you can begin the journey towards lessening your feelings of paranoia.</p>

                <p>Throughout your journey, Therapy in London will be there to offer you the support that you need. We will help address — not only your paranoia — but also any other issues that may be exacerbating your paranoia.</p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default Paranoia;
