import PropTypes from "prop-types";
import { forwardRef } from "react";
import { Link } from "react-router-dom";
import { getFileUrl } from "../../../app/lib/getFileUrl";
import "../../../styles/admin.scss";

const ArticleRow = forwardRef(
  ({ title, status, userId, userPicture, userName, articleId }, ref) => {
    return (
      <div className="row" ref={ref}>
        <Link to={`/admin/articles/review/${articleId}`}>
          <div className="imageName">
            <div className="image">
              <img
                src={getFileUrl("profilePicture", userId, userPicture) || ""}
                alt=""
              />
            </div>
            <div className="context">
              <label>{title}</label>
              <label>{userName}</label>
            </div>
          </div>

          <div className="controlsWrap">
            <label>{status}</label>
          </div>
        </Link>
      </div>
    );
  }
);

ArticleRow.propTypes = {
  title: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  userPicture: PropTypes.string,
  userName: PropTypes.string.isRequired,
  articleId: PropTypes.string.isRequired,
};

ArticleRow.displayName = "ArticleRow";

export default ArticleRow;
