import "../../../styles/global.scss";
import "../../../styles/sections.scss";
import "../../../styles/articles.scss";
import PostIt from "../../../components/elements/findhelpfor/postIt";
import { motion } from "framer-motion";

// Define animation variants
const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } }
};

function Dissociation() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../find-help-for">Find Help For..</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
          <img src="/images/icons/chevron_right.svg" className="chevron" alt="Chevron right" />
          <div className="breadcrumb current_page">
            <a href="#">Dissociation</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Therapy for Dissociation</h1>
                    <img src="/images/illustrations/purpleScribble.svg" className="titleScribble" alt="Purple scribble decoration" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="/images/articles/findHelpFor/disassociation.jpg" alt="Dissociation" />
                </div>
              </div>
              <div className="content">
                <p>Dissociation is a feeling of not being present – as if you are observing your own emotions or experiences outside of your body.</p>
                <p>It can vary from minor, short instances to long-term dissociation – or Dissociation disorder.</p>
                <p>When experiencing dissociation it can happen in several forms including:</p>
                <ul>
                  <li>Unable to remember information about yourself or things that have happened.</li>
                  <li>Feeling ‘foggy’ or as if things around you are not real.</li>
                  <li>Feeling disconnected from your body and your emotions. Not fully feeling, but rather observing.</li>
                </ul>
                <p>All of these feelings can come and go in minutes, hours, or even days.</p>
                <h2>What causes dissociation?</h2>
                <p>Many can have temporary and short experiences of dissociation. This usually happens when you find yourself in a stressful, shocking, or upsetting situation.</p>
                <p>You can feel as if you can’t fully connect to your feelings about a situation. This happens when you are almost unable to keep up with your emotions.</p>
                <p>You can react during a bout of dissociation in several ways including:</p>
                <ul>
                  <li>By focusing on something insignificant instead of what is happening.</li>
                  <li>Reacting in an “unconventional” way. For example, laughing hysterically at a funeral.</li>
                  <li>Not being able to react at all. You don’t know what to say or do, so stay still and quiet.</li>
                </ul>
                <p>Every cause is different to each individual, and dissociation is a very common response to something that may cause hurt, upset, or trauma. This is a mental response to keep you safe. When you experience dissociation this is your instinctual reaction to mentally protect yourself from harm.</p>
                <p>To others, many can see dissociation as ‘rude’ or ‘heartless’, it is important however to understand how common this reaction can be and to not have certain expectations of how someone might react to something.</p>
                <h2>Long-term Dissociation, or Dissociation Disorder</h2>
                <p>This form of dissociation develops as a result of not addressing when it becomes a regular occurrence.</p>
                <p>As with all mental issues, if your auto-response is to disconnect to all stressful situations, this may have stemmed from a traumatic event in your life yet now has become your default response.</p>
                <p>This can affect your day-to-day life making you unable to fully move forward within your life – keeping you “safe”.</p>
                <p>And safety is important but can affect your ability to try new things or create new successes or achievements within your life. If you believe that dissociation is affecting your day-to-day life and stopping you from doing certain things, then therapy can be a highly beneficial way to rework this.</p>
                <h2>How can therapy help?</h2>
                <p>Once you have understood that you are experiencing dissociation, therapy will offer you a safe space to delve into what happens before, during, and after you dissociate.</p>
                <p>You and your therapist are then able to explore what you are distancing yourself from and what steps you can take to be more present, even in those triggering situations.</p>
                <h2>How can <em>Therapy in London</em> help?</h2>
                <p>We believe that any issues you may face are unique to you. Our practitioners want to fully understand what you are going through and be a part of your journey to being happier, healthier, and more fulfilled.</p>
                <p>When trying to understand your own dissociation it can feel lonely and confusing. This is why we ensure that your therapeutic space is somewhere you can feel safe, grounded, and able to willingly explore what you are experiencing.</p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default Dissociation;
