import "../../../styles/global.scss";
import "../../../styles/profiles.scss";
import "../../../styles/sections.scss";

import propTypes from "prop-types";

Fees.propTypes = {
  title: propTypes.string,
  fee: propTypes.string,
};

function Fees(props) {
  return (
    <div className="fees_block">
      <div className="fees_content">
        <h2>{props.title}</h2>
        <div className="fee">
          <label>{props.fee}</label>
        </div>
      </div>
    </div>
  );
}

export default Fees;
