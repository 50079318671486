import "../../../styles/global.scss";
import "../../../styles/sections.scss";
import PropTypes from "prop-types";

CustomInput.propTypes = {
  title: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

function CustomInput(props) {
  return (
    <div className="selectBlock">
      <label>{props.title}</label>
      <input
        type="text"
        placeholder={props.placeholder}
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
      />
    </div>
  );
}

export default CustomInput;
