import { useQuery } from "@tanstack/react-query";
import propTypes from "prop-types";
import "quill/dist/quill.snow.css"; // import Quill styles
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom"; // Import useLocation
import { getArticleById } from "../../app/api/articles";
import { useProfileStore } from "../../app/stores";
import "../../styles/buttons.scss";
import "../../styles/global.scss";
import "../../styles/profiles.scss";
import "../../styles/sections.scss";
import CTA from "../fragments/CTA";
import Loader from "./loader";
import Articles from "./profiles/articles";
import FeaturedImage from "./profiles/featuredImage";
import Fees from "./profiles/fees";
import Main from "./profiles/main";
import NavProfile from "./profiles/nav";
import Qa from "./profiles/qa";
import Qualifications from "./profiles/qualifications";
import Types from "./profiles/types";
import { LeftQuotation } from "./svgs/LeftQuotation";
import { RightQuotation } from "./svgs/RightQuotation";
import { UnderLine } from "./svgs/Underline";

ProfilePageView.propTypes = {
  pageType: propTypes.string,
  isTherapistAdmin: propTypes.bool,
  userId: propTypes.string,
  user: propTypes.object,
};

function ProfilePageView({ pageType, user }) {
  const [isMainVisible, setIsMainVisible] = useState(false); // Initialize as false
  const mainRef = useRef(null);
  const location = useLocation(); // Get current location

  const { profile: storeProfile } = useProfileStore((state) => ({
    profile: state.profile,
  }));

  const article1Query = useQuery({
    queryKey: ["article", user?.profile?.article1Id],
    queryFn: () => getArticleById(user?.profile?.article1Id),
    enabled: !!user?.profile?.article1Id,
  });

  const article2Query = useQuery({
    queryKey: ["article", user?.profile?.article2Id],
    queryFn: () => getArticleById(user?.profile?.article2Id),
    enabled: !!user?.profile?.article2Id,
  });

  const profile = pageType === "TherapistAdmin" ? storeProfile : user?.profile;

  const nonEmptyPricePackages = profile.pricePackage.filter(
    (pkg) => pkg.name && pkg.price
  );

  const renderArticles = () => {
    const articles = [
      article1Query.data?.article,
      article2Query.data?.article,
    ].filter((article) => article?.id);

    if (articles.length === 0) {
      return null;
    }

    return (
      <div className="articles_wrap">
        <div className="profile_section_articles">
          <div className="profile_section_heading">
            <div className="title">
              <div className="titleUnderline">
                <h2>Articles</h2>
                <UnderLine color={profile?.color} />
              </div>
            </div>
            <div className="controls">
              {location.pathname !== "/therapist/profile" && (
                <CTA
                  link={`/articles/${user?.id}`}  // Update link here
                  text="See All Articles"
                  type="shadow"
                />
              )}
            </div>
          </div>
          <div className="articles_wrap">
            {articles.map((article) => (
              <Articles
                key={article.id}
                userId={user?.id}
                title={article.title}
                desc={article.content}
                hideStatus={location.pathname === "/therapist/profile"}
                id={article.id}
                image={article.picture}
              />
            ))}
          </div>
        </div>
      </div>
    );
  };

  const handleScroll = () => {
    if (mainRef.current) {
      const rect = mainRef.current.getBoundingClientRect();
      setIsMainVisible(rect.top < 0); // Update state based on scroll position
    }
  };

  useEffect(() => {
    const ref = mainRef.current;

    // Handle initial visibility
    handleScroll();

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Add intersection observer
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsMainVisible(entry.isIntersecting);
      },
      { threshold: 0.1, rootMargin: "-100px 0px 0px 0px" }
    );

    if (ref) {
      observer.observe(ref);
    }

    return () => {
      if(typeof handleScroll != 'undefined' && handleScroll!=null) window.removeEventListener("scroll", handleScroll);
      if (ref) {
        observer.unobserve(ref);
      }
    };
  }, []);

  const renderNavProfile = () => {
    if (location.pathname === "/therapist/profile") {
      return null; // Ensure NavProfile is not rendered if URL is /therapist/profile
    }

    return (
      <div className={`navProfile hidden ${isMainVisible ? "visible" : "hidden"}`}>
        <NavProfile
          name={user?.name}
          profilePicture={user?.profile?.profilePicture}
          phoneNumber={user?.profile?.phoneNumber}
          email={user?.email}
          userId={user?.id}
        />
      </div>
    );
  };

  if (article1Query.isLoading || article2Query.isLoading) {
    return <Loader />;
  }

  return (
    <div className="profile_wrap">
      {renderNavProfile()}

      <div className="profilePage_split" id="fixed-target">
        <div
          className="profile_section staySticky"
          data-scroll
          data-scroll-sticky
          data-scroll-target="#fixed-target"
          data-scroll-offset="400"
        >
          <Main
            ref={mainRef}
            name={user?.name}
            qualifications={user?.profile?.qualifications}
            address={user?.profile?.location.formattedAddress}
            phone={user?.profile?.phoneNumber}
            email={user?.email}
            profilePicture={user?.profile?.profilePicture}
            userId={user?.id}
            color={user?.profile?.color}
          />
        </div>

        <div className="profile_section">
          <div className="profile_section_custom">
            <div className="section_drag">
              <FeaturedImage />
              <div className="profile_info_row">
                {profile?.quote && (
                  <div className="details sloganViewWrap">
                    <LeftQuotation color={profile?.color} />
                    <p>{profile?.quote}</p>
                    <RightQuotation color={profile?.color} />
                  </div>
                )}
              </div>
            </div>
            {profile.profileInfos
              ?.filter((info) => !info.hidden)
              .sort((a, b) => a.order - b.order)
              .map((info) => {
                return (
                  <div key={info.id} className="section_drag profileOutput">
                    {info.content !== "" && (
                      <Qa question={info.title} answer={info.content} />
                    )}
                  </div>
                );
              })}
            {nonEmptyPricePackages.length > 0 && (
              <div className="section_drag">
                <div className="fees_wrap">
                  {nonEmptyPricePackages.map((pkg) => (
                    <Fees key={pkg.id} title={pkg.name} fee={pkg.price} />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="profile_section_fullWidth" id="showContact">
        <div className="profile_section_types typesView">
          {user?.profile?.typesOfTherapy &&
            user?.profile?.typesOfTherapy.length > 0 && (
              <Types
                className="types_orange"
                title="Types of Therapy"
                labels={user?.profile.typesOfTherapy}
                arrayKey="typesOfTherapy"
                isShow
              />
            )}
          {user?.profile?.specialisms &&
            user?.profile?.specialisms.length > 0 && (
              <Types
                className="types_blue"
                title="Types of Specialisms"
                labels={user?.profile.specialisms}
                arrayKey="specialisms"
              />
            )}
          {user?.profile?.languages && user?.profile?.languages.length > 0 && (
            <Types
              className="types_green"
              title="Languages"
              labels={user?.profile.languages}
              arrayKey="languages"
            />
          )}
          {user?.profile?.wheelchairAccess && (
            <Types
              className="types_purple"
              title="Wheelchair Accessible"
              labels={user?.profile.wheelchairAccess ? ["Yes"] : ["No"]}
              arrayKey="wheelchairAccess"
              isShow
            />
          )}
          {user?.profile?.sessionTypes &&
            user?.profile?.sessionTypes.length > 0 && (
              <Types
                className="types_black"
                title="Session Types"
                labels={user?.profile.sessionTypes}
                arrayKey="sessionTypes"
                isShow
              />
            )}
        </div>

        {profile?.membershipBody?.length > 0 && (
          <div className="profile_section_qualifications">
            <div className="title">
              <h2>Membership Body</h2>
              <UnderLine color={profile?.color} />
            </div>

            <div className="fees_wrap">
              {user?.profile?.membershipBody?.map((membership) => (
                <Qualifications
                  key={membership.id}
                  title={membership.name}
                  sub={membership.number}
                />
              ))}
              <div className="fold" />
            </div>
          </div>
        )}

        {renderArticles()}
      </div>
    </div>
  );
}

export default ProfilePageView;
