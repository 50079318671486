import PostIt from "../../../components/elements/findhelpfor/postIt";
import "../../../styles/global.scss";
import "../../../styles/sections.scss";
import "../../../styles/articles.scss";
import { motion } from "framer-motion";

// Define animation variants
const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } }
};

function Bereavement() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../find-help-for">Find Help For..</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
          <img src="/images/icons/chevron_right.svg" className="chevron" alt="Chevron right" />
          <div className="breadcrumb current_page">
            <a href="#">Bereavement</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Therapy for Bereavement</h1>
                    <img src="/images/illustrations/purpleScribble.svg" className="titleScribble" alt="Purple scribble decoration" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="/images/articles/findHelpFor/bereavement.jpg" alt="Bereavement image" />
                </div>
              </div>
              <div className="content">
                <p>Bereavement is a unique experience for everyone and it comes with an all too real feeling of mortality within your own life. You may grieve differently from everyone, but the most important thing is to allow your feelings and emotions to come out, and to deal with them in the safest and most beneficial way possible.</p>

                <p>Grieving doesn’t come with rules, guidelines, or boundaries, so make sure that you allow yourself to come to terms with it, rather than hiding from it.</p>

                <p>Depending on who you have lost and your relationship with them, you can feel a vast spectrum of emotions and symptoms; whether they be physical or psychological:</p>

                <ul>
                  <li>A feeling of numbness to the news. i.e no emotions.</li>
                  <li>A <a href="https://www.therapyin.london/find-help-for/motivation/" target="_blank" rel="noreferrer noopener">detachment</a> from your day-to-day life</li>
                  <li>Heightened emotions: crying, <a href="https://www.therapyin.london/find-help-for/anxiety/" target="_blank" rel="noreferrer noopener">anxiety</a>, angry outbursts</li>
                </ul>

                <p>When going through bereavement, if you allow your emotions to build up without dealing with them, you can even experience symptoms related to:</p>

                <ul>
                  <li>Panic attacks</li>
                  <li><a href="https://www.therapyin.london/find-help-for/depression/" target="_blank" rel="noreferrer noopener">Depression</a></li>
                  <li>Phobias</li>
                </ul>

                <p>When you lose a loved one, your initial instinct is to find a way to fix it. i.e., take control of your grief and find a way to fix yourself as fast as possible. Feeling bereft can be all-consuming, and this is why your instinct may be to detach from those feelings.</p>

                <p>There are many ways you might do this, for example:</p>

                <ul>
                  <li>Making impulsive decisions</li>
                  <li>Pushing away loved ones</li>
                  <li>Escaping through <a href="https://www.therapyin.london/find-help-for/addiction/" target="_blank" rel="noreferrer noopener">substances</a> such as alcohol and drugs</li>
                  <li>Keeping yourself as busy as possible</li>
                  <li>Avoiding talking about your loss completely</li>
                </ul>

                <h2>How therapy can help you.</h2>

                <p>It is normal to think that if you accept loss, then that person will be forgotten – this isn’t the case. When speaking to your counsellor, they are able to not only be there for you during the grieving process, but also help shine a light on what that person has meant to you, and how their memory can be used as an influence to create the best life for yourself.</p>

                <p>When bereft, it can be easy to forget about your own self-care and motivations. Using talking therapy is an effective way of allowing that person to always remain in your life through your own success in the ongoing future.</p>

                <h2>How <em>Therapy in London</em> can help you.</h2>

                <p>Our practitioners want to know not just how you are dealing with your emotions, but also the importance of that person in your life. Bereavement is never a process of ‘getting over’ that person. We want to know about the impact that person had in your life – because that positive influence can be your drive for your future and so, never allowing your loved one to be forgotten.</p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default Bereavement;
