import "../../../styles/global.scss";
import "../../../styles/sections.scss";
import "../../../styles/articles.scss";
import PostIt from "../../../components/elements/findhelpfor/postIt";
import { motion } from "framer-motion";

const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } }
};

function WomenMentalHealth() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../find-help-for">Find Help For..</a>
            <img src="/images/illustrations/underline.svg" />
          </div>
          <img src="/images/icons/chevron_right.svg" className="chevron" />
          <div className="breadcrumb current_page">
            <a href="#">Women’s Mental Health</a>
            <img src="/images/illustrations/underline.svg" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Therapy for Women’s Mental Health</h1>
                    <img src="/images/illustrations/purpleScribble.svg" className="titleScribble" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="/images/articles/findHelpFor/womens-mental-health.jpg" />
                </div>
              </div>
              <div className="content">
                <p>Women’s mental health has been a heated topic for many years. The cis female body goes through many transformative stages. You may feel you are continually seeking a sense of control as your physical and emotional state constantly changes.</p>
                <p>A stigma exists around the concept that women think ‘emotionally’ rather than ‘logically’ – and this in itself is a bad thing. Understanding how your mind and body are connected enables you to feel both in control and empowered through each stage of your life.</p>
                <ul>
                  <li>Puberty</li>
                  <li>Ongoing menstrual cycle</li>
                  <li>Pregnancy</li>
                  <li>Infertility</li>
                  <li>Motherhood</li>
                  <li>Menopause</li>
                </ul>

                <p>These big and often stressful stages in a woman’s life are accompanied by significant hormonal fluxes and changes. A lot of the time you can feel completely at sea with both emotions and associated physical symptoms.</p>
                <h2>What causes women’s mental health issues?&nbsp;</h2>
                <p>Without professional support and psycho-education around hormonal changes and brain chemistry, women can feel alone. Your symptoms can seem unexplainable and somehow your ‘fault’.</p>
                <p>You may feel like you should be able to ‘think your way’ out of a situation or feel ashamed of the fact that this isn’t possible. The societal pressure created by this belief causes many women to attempt to hide their feelings and push down their physical symptoms.</p>
                <p>The societal disregard for ‘hormonal’ behaviour often compounds the sense that being affected by natural hormonal changes is ridiculous, shameful and to be suppressed.</p>
                <p>You may understand the familiar feeling of pushing down thoughts, feelings and emotions. You may also try to ignore symptoms, such as:</p>
                <ul>
                  <li><a href="https://www.therapyin.london/find-help-for/anxiety/" target="_blank" rel="noreferrer noopener">Anxiety</a></li>
                  <li><a href="https://www.therapyin.london/find-help-for/depression/" target="_blank" rel="noreferrer noopener">Depression</a></li>
                  <li>Fatigue and <a href="https://www.therapyin.london/find-help-for/motivation/" target="_blank" rel="noreferrer noopener">lack of motivation</a></li>
                  <li>Cognitive confusion (commonly called ‘brain fog’)</li>
                </ul>

                <p>This simply does not work. When symptoms linked to hormonal changes are disregarded, those issues causing that anxiety and tension are also being ignored. Symptoms can then get worse leading to a deep sense of desperation and helplessness too.</p>
                <p>Important milestones, like the above, can then also bring up unresolved issues from your past, present and fears about the future.</p>
                <h2>How can therapy help?</h2>
                <p>Talking to a counsellor can help you to understand what is happening to you and how to manage it. This new awareness restores a sense of agency. It empowers you to see the options moreover help you to make choices that suit your own individual circumstances.</p>
                <p>Walking through these life-stages with an empathetic guide offers you a safe space to be heard. It gives you the opportunity to explore or reconcile with issues and to then make positive, long-lasting change.</p>
                <p>It’s important to ask yourself, how often do you give yourself this space for self-discovery?</p>
                <h2>How can <em>Therapy in London</em> help?</h2>
                <p>Therapy in London carefully matches practitioners to every client. As an integrative counsellor and yoga therapist, I specialise in women’s mental health.</p>
                <p>My approach is based on creating an empathic, safe space for you to nurture self-awareness, explore, resolve and reconcile with troubling or distressing feelings and experiences.</p>
                <p>As women, it can be a comfort to know that we share similarities of experiences, and freeing to gain an understanding of what is going on in your body. Women may share life stages, but each of us brings our own unique life experience to every significant milestone. As a therapist, I help women to honour their unique experiences, to understand their story and to take back agency in their life.</p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default WomenMentalHealth;
