import PropTypes from "prop-types";
import { useState } from "react";
import "../../styles/global.scss";
import "../../styles/sections.scss";

const SearchInput = ({
  items,
  onSelectionChange,
  placeholder,
  initialSelectedItems,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [selectedItems, setSelectedItems] = useState(
    initialSelectedItems || []
  );

  const [showDropdown, setShowDropdown] = useState(false);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setShowDropdown(true);
  };

  const handleItemSelect = (item) => {
    const newItem = typeof item === "string" ? { id: item, name: item } : item;
    setSelectedItems([...selectedItems, newItem]);
    setInputValue("");
    setShowDropdown(false);
    onSelectionChange([...selectedItems, newItem]);
  };

  const handleItemRemove = (id) => {
    const updatedItems = selectedItems.filter((item) => item.id !== id);
    setSelectedItems(updatedItems);
    onSelectionChange(updatedItems);
  };

  const filteredItems = items
    .filter((item) =>
      (typeof item === "string" ? item : item.name)
        .toLowerCase()
        .includes(inputValue.toLowerCase())
    )
    .filter(
      (item) =>
        !selectedItems.some(
          (selectedItem) =>
            selectedItem.id === (typeof item === "string" ? item : item.id)
        )
    );

  return (
    <div className="search_wrap_specialism">
      <div className="search_1">
        <div className="search_input_wrap">
          <input
            placeholder={placeholder || "Search..."}
            value={inputValue}
            onChange={handleInputChange}
            onFocus={() => setShowDropdown(true)}
          />
          {showDropdown && inputValue && filteredItems.length > 0 && (
            <ul className="specialism-dropdown">
              {filteredItems.map((item) => (
                // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
                <li
                  key={typeof item === "string" ? item : item.id}
                  onClick={() => handleItemSelect(item)}
                >
                  {typeof item === "string" ? item : item.name}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="selected-specialisms">
          {selectedItems.map((item) => (
            <span key={item.id} className="selected-specialism">
              {item.name}
              <button type="button" onClick={() => handleItemRemove(item.id)}>
                ×
              </button>
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

SearchInput.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
        name: PropTypes.string.isRequired,
      }),
    ])
  ).isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  initialSelectedItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
};

export default SearchInput;
