import React, { useEffect, useRef } from "react";

const Lamp5 = () => {
    const lottieRef = useRef(null);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://unpkg.com/@lottiefiles/lottie-player@latest';
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
            const lottiePlayer = lottieRef.current;

            lottiePlayer.addEventListener('load', () => {
                lottiePlayer.stop();
            });

            const handleScroll = () => {
                if (lottiePlayer) {
                    const rect = lottiePlayer.getBoundingClientRect();
                    const windowHeight = window.innerHeight || document.documentElement.clientHeight;

                    // Calculate progress based on element's visibility in the viewport
                    const elementTop = rect.top + window.scrollY - lottiePlayer.offsetParent.offsetTop;
                    const elementHeight = rect.height;

                    const buffer = windowHeight * 0.25; // Buffer before and after the element is in view
                    const start = elementTop - windowHeight + buffer;
                    const end = elementTop + elementHeight + buffer; // Adjusted to element's bottom with buffer

                    const scrollY = window.scrollY;

                    // Calculate the scroll progress as a value between 0 and 1
                    const progress = Math.min(Math.max((scrollY - start) / (end - start), 0), 1);

                    // Calculate the frame to display based on the progress
                    const maxFrames = lottiePlayer.getLottie().totalFrames;
                    const frame = Math.floor(progress * maxFrames);

                    lottiePlayer.getLottie().goToAndStop(frame, true);

                }
            };

            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        window.addEventListener('scroll', handleScroll);
                        handleScroll(); // Update frame when entering view
                    } else {
                        window.removeEventListener('scroll', handleScroll);
                    }
                });
            }, { threshold: 0.1 });

            if (lottiePlayer) {
                observer.observe(lottiePlayer);
            }

            return () => {
                window.removeEventListener('scroll', handleScroll);
                observer.disconnect();
            };
        };

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div>
            <lottie-player
                ref={lottieRef}
                src="/images/animations/lamps/lamp5.json"
                background="transparent"
                speed="1"
                style={{ width: '400px', height: '450px' }}
                loop={false}
                autoplay={false}
            ></lottie-player>
        </div>
    );
};

export default Lamp5;
