import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { getAvailableArticles } from "../../app/api/admin/avaiableArticles";
import AvailableArticles from "../../components/elements/articles/availableArticles";
import "../../styles/buttons.scss";
import "../../styles/global.scss";
import "../../styles/profiles.scss";
import { motion } from "framer-motion";  // Import Framer Motion

// Define animation variants
const containerVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } }
};

function TherapistAvailableArticles() {
  const { data: availableArticlesData } = useQuery({
    queryKey: ["availableArticles", "therapist"],
    queryFn: () => getAvailableArticles({ status: "active" }),
  });

  return (
    <motion.div
      className="profileView_wrap backgroundClear"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <motion.div
        className="profileView"
        initial="hidden"
        animate="visible"
        variants={fadeInVariants}
      >
        <div className="profileView_controls_wrap">
          <div className="breadcrumbs_wrap">
            <motion.div
              className="breadcrumb"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              <Link to="/">Home</Link>
              <img
                src="../../images/illustrations/underline.svg"
                alt="underline"
              />
            </motion.div>
            <motion.img
              src="../../images/icons/chevron_right.svg"
              className="chevron"
              alt="chevron"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
            />
            <motion.div
              className="breadcrumb"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              <Link to="../articles">Articles</Link>
              <img
                src="../../images/illustrations/underline.svg"
                alt="underline"
              />
            </motion.div>
            <motion.img
              src="../../images/icons/chevron_right.svg"
              className="chevron"
              alt="chevron"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
            />
            <motion.div
              className="breadcrumb current_page"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              <Link to="#">Available Articles</Link>
              <img
                src="../../images/illustrations/underline.svg"
                alt="underline"
              />
            </motion.div>
          </div>
          <div className="controls" />
        </div>

        <motion.div
          className="narrowColumn_wrap"
          initial="hidden"
          animate="visible"
          variants={fadeInVariants}
        >
          <motion.div
            className="title"
            initial="hidden"
            animate="visible"
            variants={fadeInVariants}
          >
            <h1>Available Articles</h1>
            <p>Select Article you would like to write about</p>
          </motion.div>

          <motion.div
            className="availableArticles_wrap"
            initial="hidden"
            animate="visible"
            variants={containerVariants}
          >
            {availableArticlesData?.result?.map((article) => (
              <AvailableArticles
                key={article.id}
                labels={article.specialisms}
                title={article.title}
                brief={article.description}
                articleId={article.id}
              />
            ))}
          </motion.div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
}

export default TherapistAvailableArticles;
