import "../../../styles/global.scss";
import "../../../styles/sections.scss";
import "../../../styles/articles.scss";
import PostIt from "../../../components/elements/findhelpfor/postIt";
import { motion } from "framer-motion";

const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } }
};

function SexIssues() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../find-help-for">Find Help For..</a>
            <img src="/images/illustrations/underline.svg" />
          </div>
          <img src="/images/icons/chevron_right.svg" className="chevron" />
          <div className="breadcrumb current_page">
            <a href="#">Sex Issues</a>
            <img src="/images/illustrations/underline.svg" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Therapy for Sex Issues</h1>
                    <img src="/images/illustrations/purpleScribble.svg" className="titleScribble" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="/images/articles/findHelpFor/sex-issues.jpg" />
                </div>
              </div>
              <div className="content">
                <p>Issues with sex start with understanding your issues surrounding your sex life.</p>
                <p>Sex issues can be one or several common problems:</p>
                <ul>
                  <li>Low/no sex drive</li>
                  <li>Unsure of your sexual identity</li>
                  <li>Low enjoyment of sex</li>
                  <li>High sex drive</li>
                  <li>Sex addiction</li>
                  <li>Erectile dysfunction</li>
                  <li>Inability to climax</li>
                  <li>Unable to feel connected</li>
                </ul>
                <p>Your own issue depends on how you feel towards sex and how it is affecting your relationships. Once you understand these feelings, you can start to resolve them.</p>
                <h2>What causes sex issues?</h2>
                <p>As with all mental health issues, problems with sex life evolve from past events, thoughts, feelings, and actions.</p>
                <p>Intercourse is a rare moment where you need to be in a secure and relaxed state. Distractions, stress, or tension can be issues if you find yourself unable to perform or enjoy it.</p>
                <p>Anxiety and stress can create a block that prevents relaxation. Understanding these barriers can help you address them.</p>
                <h2>How therapy can help.</h2>
                <p>Counseling helps reorder and assess thoughts and issues burdening your mind. It sheds light on what your mind is trying to protect you from and helps resolve underlying concerns.</p>
                <h2>How <em>Therapy in London</em> can help?</h2>
                <p>We create a safe space where you can stay vulnerable and address your issues openly. Trust and transparency are crucial for successful therapy. We help find the right therapist for your growth and positive change.</p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default SexIssues;
