import "../../styles/global.scss";
import "../../styles/sections.scss";
import "../../styles/articles.scss";
import { motion } from "framer-motion";

const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

export const WebsiteDisclaimer = () => {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../">Home</a>
            <img src="../../images/illustrations/underline.svg" />
          </div>
          <img src="../../images/icons/chevron_right.svg" className="chevron" />
          <div className="breadcrumb current_page">
            <a href="#">Disclaimer</a>
            <img src="../../images/illustrations/underline.svg" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView ">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={containerVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Disclaimer</h1>
                    <img src="../../images/illustrations/purpleScribble.svg" className="titleScribble" />
                  </div>
                </div>
              </div>
              <div className="content">
                <h2>Terms of Use</h2>
                <p>These terms apply to your access and use of our website at: <a href="https://www.therapyin.london" target="_blank">https://www.therapyin.london</a> (our Website). You should view the Website Privacy Notice at: <a href="https://www.therapyin.london/terms/privacy-policy" target="_blank">www.therapyin.london/terms/privacy-policy</a> which explains what personal data we collect when you use our website.</p>
                <p>By accessing our Website, you acknowledge that these terms are legally binding. If you don’t agree with any of these terms, you should stop accessing and using our Website.</p>

                <h2>Accessing Our Website</h2>
                <p>We reserve the right to suspend the operation of our website at any time. We may also restrict access to some parts of our website to users who have registered with us.</p>

                <h2>Accessibility</h2>
                <p>We want to ensure that our website is as accessible as possible. If you have any difficulties using our website, please let us know by emailing <a href="mailto:contact@therapyin.london">contact@therapyin.london</a>.</p>

                <h2>Changes to Our Website</h2>
                <p>We update our website regularly and may change the content at any time. If we update any existing content, we’ll bring this to your attention at the top of the relevant page.</p>

                <h2>Copyright and Intellectual Property</h2>
                <p>All copyright and other intellectual property rights in our website and its content (including logos, illustrations, text, and videos) belong to us or our licensors. Unless we say otherwise, all rights are reserved. If you’re in any doubt about whether you can use anything on our website, please contact us for clarification.</p>

                <h2>External Links</h2>
                <p>We have no control over, and don’t accept any responsibility for, the content of any third-party website. If we link to any third-party website, this doesn’t mean that we endorse or recommend the organization which operates it.</p>

                <h2>Linking to Content on Our Website</h2>
                <p>You can create links to any content on our website; however, you mustn’t do so in any way which is misleading or suggests any relationship between us where one doesn’t exist. We’re not obliged to notify you if any content you’ve linked to has been updated, moved, or deleted.</p>

                <h2>Passwords</h2>
                <p>If you need a username or password to access any part of our website, you agree to:</p>
                <ul>
                  <li>Ensure that any details provided to us are accurate;</li>
                  <li>Keep your username or password confidential;</li>
                  <li>Let us know promptly if you believe that your username or password has been compromised.</li>
                </ul>
                <p>We may terminate your access to any password-protected areas of our website at any time if we believe that your use of our website is affecting the security and stability of our website or is detrimental to other users.</p>

                <h2>Reliance on Content</h2>
                <p>Our website, and content published on it, is for information purposes only. While we aim to ensure that any content is correct at the time it is published, we can’t guarantee that it will continue to be correct at the time you access it. We don’t accept any liability or responsibility of any nature, arising from any reliance placed by you or anyone else on the content published on our website. To the maximum extent permitted by law, we exclude our liability for direct, indirect, or consequential loss.</p>

              </div>
            </motion.div>
          </div>
        </div>
      </motion.div>
    </>
  );
}
