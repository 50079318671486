import { useQuery } from "@tanstack/react-query";
import { format, parseISO } from "date-fns";
import { motion } from "framer-motion"; // Import Framer Motion
import { Link, useParams } from "react-router-dom";
import { getArticleById } from "../app/api/articles";
import { getUserById } from "../app/api/profile/getUserByid";
import { getFileUrl } from "../app/lib/getFileUrl";
import { lazy } from "react";
import AuthorWidget from "../components/elements/articles/authorWidget";
import Loader from "../components/elements/loader";
import "../styles/articles.scss";
import "../styles/global.scss";
import "../styles/sections.scss";
import { Suspense } from "react";
var Quill, ReactQuill;
import("react-quill").then((module) => {
  Quill = module.Quill;
  ReactQuill = module.default;
});

// Define animation variants
const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } },
};

function ArticleView() {
  const { userId, articleId } = useParams();

  const { data: userData, isLoading: userLoading } = useQuery({
    queryKey: ["user", userId],
    queryFn: () => (userId ? getUserById(userId) : null),
    enabled: !!userId,
  });
  const { data: articleData, isLoading: articleLoading } = useQuery({
    queryKey: ["article", articleId],
    queryFn: () => getArticleById(articleId),
  });
  if (userLoading || articleLoading) return <Loader subtitle="Loading..." />;
  const formattedDate = format(
    parseISO(articleData.article.createdAt),
    "do MMMM yyyy",
  );

  return (
    <motion.div
      className="articlesPage_wrap"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <motion.div
        className="breadcrumbs_wrap"
        initial="hidden"
        animate="visible"
        variants={fadeInVariants}
      >
        <div className="breadcrumb">
          <Link to="/">Home</Link>
          <img src="/images/illustrations/underline.svg" alt="underline" />
        </div>
        <img
          src="/images/icons/chevron_right.svg"
          className="chevron"
          alt="chevron-right"
        />
        <div className="breadcrumb">
          <Link to="/articles">Articles</Link>
          <img src="/images/illustrations/underline.svg" alt="underline" />
        </div>
        <img
          src="/images/icons/chevron_right.svg"
          className="chevron"
          alt="chevron-right"
        />
        <div className="breadcrumb current_page breadcrumb_elipses">
          <a href="#">{articleData.article.title}</a>
          <img src="/images/illustrations/underline.svg" alt="underline" />
        </div>
      </motion.div>

      <motion.div
        className="articleView_wrap"
        initial="hidden"
        animate="visible"
        variants={fadeInVariants}
      >
        <motion.div
          className="articleView"
          initial="hidden"
          animate="visible"
          variants={fadeInVariants}
        >
          <motion.div
            className="articleContent"
            initial="hidden"
            animate="visible"
            variants={fadeInVariants}
          >
            <div className="article_start fullWidthStarted">
              <div className="title">
                <h1>{articleData.article.title}</h1>
                <div className="writerProfile">
                  <div className="image">
                    <img
                      src={getFileUrl(
                        "profilePicture",
                        userData?.user?.id,
                        userData?.user?.profile?.profilePicture,
                      )}
                      alt="author-pp"
                    />
                  </div>

                  <div className="details">
                    <label>
                      <Link to={`/results/profile/${userData.user.id}`}>
                        {userData.user.name}
                      </Link>
                    </label>
                    <label>{formattedDate}</label>
                  </div>
                </div>
              </div>
              {articleData?.article?.picture && (
                <div className="featured_image fullWidthFeatured">
                  <img
                    src={getFileUrl(
                      "article",
                      articleData?.article?.id,
                      articleData?.article?.picture,
                    )}
                    alt="article-thumbnail"
                  />
                </div>
              )}
            </div>
            <div className="content">
              <Suspense fallback={<Loader subtitle="Loading editor..." />}>
                <ReactQuill
                  theme="bubble"
                  value={articleData?.article?.content}
                  readOnly={true}
                />
              </Suspense>
            </div>
          </motion.div>
        </motion.div>
        <AuthorWidget
          author={userData.user.name}
          qual={userData.user.profile.qualifications}
          noArticles={userData.user.articleCount}
          userId={userData.user.id}
          profilePicture={
            userData.user.profile.profilePicture
              ? getFileUrl(
                "profilePicture",
                userData.user.id,
                userData.user.profile.profilePicture,
              )
              : "../images/placeholders/philip.png"
          }
        />
      </motion.div>
    </motion.div>
  );
}

export default ArticleView;
