import "../../../styles/global.scss";
import "../../../styles/sections.scss";
import "../../../styles/articles.scss";
import PostIt from "../../../components/elements/findhelpfor/postIt";
import { motion } from "framer-motion";

const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } }
};

function WorkIssues() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../find-help-for">Find Help For..</a>
            <img src="/images/illustrations/underline.svg" />
          </div>
          <img src="/images/icons/chevron_right.svg" className="chevron" />
          <div className="breadcrumb current_page">
            <a href="#">Work and Career Issues</a>
            <img src="/images/illustrations/underline.svg" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Therapy for Work and Career Issues</h1>
                    <img src="/images/illustrations/purpleScribble.svg" className="titleScribble" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="/images/articles/findHelpFor/work-and-career-issues.jpg" />
                </div>
              </div>
              <div className="content">
                <p>Within the UK, a lot of our identity centers around your work or where you see your career going. This can put a considerable amount of pressure and stress on you, especially as we spend the majority of our week at work.</p>
                <p>When your job takes its toll, or you start to question your career path, it can feel as if you are losing a part of yourself.</p>
                <p>A job can become so integrated into your life that you can find it hard to look past it. This is when many work and career issues arise and why you can start to question where you want your career to go.</p>
                <h2>What causes work and career issues</h2>
                <p>As society has encouraged a bright career to become an integral part of your identity, it can feel as if you cannot question whether monetary success equals happiness. This is the first step to understanding where your issues lie and what you personally consider to be success.</p>
                <p>When the two intertwine you can find yourself either:</p>
                <ul>
                  <li>Staying too long in a job you dislike</li>
                  <li>Tolerating a toxic work environment</li>
                  <li>Hopping from one job to another in hope of instant gratification</li>
                  <li>Neglecting other aspects of your life in favour of work</li>
                </ul>
                <p>There can be a huge spectrum of feelings, emotions and negative habits attached to your work, but this comes down to what you expect of yourself and what you hope for your future. It is important to understand how you can find happiness for yourself – whether that be in your work or outside of it.</p>
                <h2>How can therapy help with career issues?</h2>
                <p>As we do spend so much time at work, it’s important for you to come to an understanding of what your job means to you.</p>
                <p>This can come down to you deciding that you want a career change, you don’t want to be defined by your career and focus on other things, or you want to aim higher. Either way, these are all questions that come up during therapy. Sessions offer you that space to really focus on yourself, your happiness and your goals for your future.</p>
                <p>With little distractions and a safe, confidential space, you are able to candidly talk through what you want for yourself and how you can <em>feel</em> successful, as opposed to just monetary success.</p>
                <p>Understanding how you define your own success is when you are able to grasp what path you want to go down.</p>
                <h2>How can <em>Therapy in London</em> help?</h2>
                <p>We consider everyone to be completely unique in their hopes, dreams, and ambitions. With this, we bring a tailored therapeutic to you meaning that your future doesn’t have to fit into anyone else’s.</p>
                <p>Our practitioners understand that not everyone’s path and success are made from cookie-cutter expectations. So we want to find out with you, what makes you happy and what a successful future looks like to you, and only you.</p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default WorkIssues;
