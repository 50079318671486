import "../../../styles/global.scss";
import "../../../styles/sections.scss";
import "../../../styles/articles.scss";
import PostIt from "../../../components/elements/findhelpfor/postIt";
import { motion } from "framer-motion";

// Define animation variants
const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } }
};

function Depression() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../find-help-for">Find Help For..</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
          <img src="/images/icons/chevron_right.svg" className="chevron" alt="Chevron right" />
          <div className="breadcrumb current_page">
            <a href="#">Depression</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Therapy for Depression</h1>
                    <img src="/images/illustrations/purpleScribble.svg" className="titleScribble" alt="Purple scribble decoration" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="/images/articles/findHelpFor/depression.jpg" alt="Depression image" />
                </div>
              </div>
              <div className="content">
                <p>Depression occurs in 1 of 5 of us, yet is still an issue that needs to be discussed openly in order to fully understand. The more we talk about depression and the effect it has on 1 in 5 of us, the further we can get to prevent it as early as possible.</p>

                <h2>What is depression?</h2>

                <p>Depression stems from other issues that can go unresolved or ignored. It is a feeling of worthlessness and hopelessness. When suffering from depression, it is difficult to notice the positive or good things in your life and you find yourself focusing on the negative.</p>

                <p>This is an extreme mental reaction to <a href="#">not facing past issues</a> and either burying or ignoring them and letting them exacerbate. These issues can be anything, big or small, that you might have unresolved from your past.</p>

                <p>Many other issues can feel easy to ignore, which is when a depressive state is a way of sending alarm bells to you, letting you know that something needs to be addressed right now.</p>

                <p>This is why understanding depression is vital for everyone. With a full understanding of what it is, you can take away blame from yourself and others, and you can start focusing on how you can rework those emotions to get through this depressive state.</p>

                <p>There are many symptoms associated with depression. The way everyone deals with depression is relative to your background, lifestyle, and what issues led to your depression for you. The most common symptoms include:</p>

                <ul>
                  <li>Isolating yourself from friends and family</li>
                  <li><a href="#">Anger</a> and pushing away loved ones</li>
                  <li>Self-sabotage</li>
                  <li>Fatigue, sleeping more</li>
                  <li>Insomnia or irregular sleep patterns</li>
                  <li>Physical symptoms can even occur such as more frequent colds and flus</li>
                  <li>In extreme cases, <a href="#">self-harming</a></li>
                </ul>

                <h3><strong><a href="#">If you or anyone you know is self-harming or having suicidal thoughts, you can speak to The Samaritans, 24 hours a day, 7 days a week.</a></strong></h3>

                <p>All of these symptoms relate to you and what the depression has been trying to protect you from.</p>

                <h2>What causes depression?</h2>

                <p>When you feel those bouts of anxiety (pre-depression), and you don’t do anything about it, that message will become louder and louder, until it is an all-consuming depressive state.</p>

                <p>Sometimes it can be out of your control, such as a <a href="#">traumatic</a> event. Your mind is reacting to the trauma by signaling for you to avoid it again at all costs. This is why addressing your trauma as early as possible is the best way to avoid it leading to depression.</p>

                <p>For many, it can be difficult to pinpoint a moment in time that led to depression. This is because it can also result from years of passive issues, unresolved confrontations, or even past thoughts, goals, and dreams not pursued.</p>

                <p>Everyone’s mind is wired to remember each and every negative thought and feeling you have, and try and avoid you having it again, so with every bout of anxiety, regret, or remorse, it can build up to depression later down the line.</p>

                <h2>How therapy can help with depression.</h2>

                <p>Throughout our lives, we build a complex web, which is why it is so important to keep your mind in order. You cannot remember every bad memory or negative thought pattern you have created, which is why therapy is the most beneficial way to go through things in your past to rework them for a better future.</p>

                <h2>How <em>Therapy in London</em> can help.</h2>

                <p><a href="https://www.therapyin.london/our-therapists/" target="_blank" rel="noreferrer noopener">Our practitioners</a> want to be a constant guide and support through what can be a mentally challenging time for you.</p>

                <p>What we fully understand is how unique everyone who walks through our door is. We don’t believe in treating every issue the same way, because your depression means something to you and only to you.</p>

                <p>We have created a small, select few practitioners so that every client can find a therapist who can relate to them and build a long-lasting, beneficial relationship through therapy.</p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default Depression;
