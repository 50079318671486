import PropTypes from "prop-types";
import "../../styles/global.scss";
import "../../styles/sections.scss";

function FilterSelectObject({
  title,
  options,
  selectedOptions,
  onChange,
  multiSelect = false,
}) {
  const handleChange = (e) => {
    const selectedValue = e.target.value;
    const selectedOption = options.find(
      (option) => option.id === selectedValue
    );
    if (multiSelect) {
      onChange(selectedOption, true);
    } else {
      onChange([selectedOption], true);
    }
  };

  const handleRemove = (optionToRemove) => {
    onChange(optionToRemove, false);
  };

  const availableOptions = options.filter(
    (option) => !selectedOptions.some((selected) => selected.id === option.id)
  );

  return (
    <div className="filter_block">
      <h3>{title}</h3>

      <select value="" onChange={handleChange}>
        <option value="">Select {title}</option>
        {availableOptions.map((option) => (
          <option key={option.id} value={option.id} style={{ color: "black" }}>
            {option.name}
          </option>
        ))}
      </select>
      <div className="selected-options">
        {selectedOptions.map((option) => (
          <span key={option.id} className="selected-option">
            {option.name}
            <button type="button" onClick={() => handleRemove(option)}>
              ×
            </button>
          </span>
        ))}
      </div>
    </div>
  );
}

FilterSelectObject.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  multiSelect: PropTypes.bool,
};

export default FilterSelectObject;
