import { useQuery } from "@tanstack/react-query";
import PropTypes from "prop-types";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getLanguages } from "../../app/api/profile/getLanguages";
import { getSpecialisms } from "../../app/api/profile/getSpecialisms";
import { typesOfTherapy } from "../../app/data/typesOftherapy";
import useIsMobile from "../../app/hooks/useIsMobile";
import { useSearchStore } from "../../app/stores/searchStore";
import "../../styles/global.scss";
import "../../styles/sections.scss";
import FilterOptions from "../fragments/filterRadio";
import FilterSelectObject from "../fragments/filterSelectObject";
import FilterSelectString from "../fragments/filterSelectString";
import InputGoogle from "./InputGoogle";

function Filters({ onSearch, onHideFiltersClick }) {
  const isMobile = useIsMobile();
  const [inputError, setInputError] = useState(false);
  const {
    specialisms,
    therapyTypes,
    languages,
    gender,
    setSpecialisms,
    setTherapyTypes,
    setLanguages,
    setGender,
    setLocation,
    sessionTypes,
    setSessionTypes,
    wheelchairAccess,
    setWheelchairAccess,
    getSearchParams,
    resetFilters,
    location,
    setOtherInfo,
    setPage,
    setLimit,
    radius,
    setRadius,
    setLongitude,
    setLatitude,
    setPrecise,
    getDefaultRadius,
  } = useSearchStore();

  const handleRadiusChange = (e) => {
    const value = Number(e.target.value, 10);
    setRadius(Number.isNaN(value) ? null : value);
  };
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const inputRef = useRef(null);
  const specialismsQuery = useQuery({
    queryKey: ["specialisms"],
    queryFn: async () => await getSpecialisms(),
  });

  const languagesQuery = useQuery({
    queryKey: ["languages"],
    queryFn: async () => await getLanguages(),
  });

  const handleClear = () => {
    setLocation({
      formattedAddress: "",
    });
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const genders = [
    "Cis Male",
    "Cis Female",
    "Trans Male",
    "Trans Female",
    "Non-Binary",
    "Prefer not to say",
  ];

  const handleSearch = () => {
    if (!inputError) {
      const params = getSearchParams();
      navigate(`/results?${params.toString()}`);
      onSearch();

      // Hide the filters on mobile after updating results
      if (isMobile) {
        onHideFiltersClick();  // This hides the filters
      }
    }
  };
  const handlePlaceSelected = (place) => {
    const addressComponents = place.address_components;

    const locality = addressComponents.find((c) =>
      c.types.includes("locality")
    );
    const postalTown = addressComponents.find((c) =>
      c.types.includes("postal_town")
    );

    const nonCityNames = ["richmond", "barnet"];

    let precise = false;
    const isLocalityDefined = !!locality?.long_name;
    const isPostalTownDefined = !!postalTown?.long_name;

    const areNamesEqual =
      isLocalityDefined &&
      isPostalTownDefined &&
      locality.long_name.toLowerCase() === postalTown.long_name.toLowerCase();

    const isExcluded = isLocalityDefined
      ? nonCityNames.includes(locality.long_name.toLowerCase())
      : false;

    const isCity = areNamesEqual && !isExcluded;
    const hasAdditionalInfo = addressComponents.some((c) => {
      const a = ![
        "administrative_area_level_1",
        "administrative_area_level_2",
        "country",
        isCity ? "locality" : "",
        isCity ? "postal_town" : "",
      ].includes(c.types[0]);
      return a;
    });

    if (isCity) {
      precise = false;
    } else if (hasAdditionalInfo) {
      precise = true;
    } else {
      precise = false;
    }
    const newLocation = {
      country:
        addressComponents.find((c) => c.types.includes("country"))?.long_name ||
        "",
      city: locality?.long_name || postalTown?.long_name || "",
      county:
        addressComponents.find((c) =>
          c.types.includes("administrative_area_level_2")
        )?.long_name ||
        addressComponents.find((c) =>
          c.types.includes("administrative_area_level_1")
        )?.long_name,
      postCode:
        addressComponents.find((c) => c.types.includes("postal_code"))
          ?.long_name || "",
      formattedAddress: place.formatted_address,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
    };
    setPrecise(precise);
    setLocation(newLocation);
    setRadius(getDefaultRadius());
    if (inputRef.current) {
      inputRef.current.value = place.formatted_address;
    }
  };

  const handleSessionTypeChange = (option, isSelected) => {
    if (isSelected) {
      setSessionTypes([...sessionTypes, option]);
    } else {
      setSessionTypes(sessionTypes.filter((type) => type !== option));
    }
  };

  const handleWheelchairAccessChange = (value) => {
    setWheelchairAccess(value === "Yes" ? true : value === "No" ? false : null);
  };
  const handleInputError = (hasError) => {
    setInputError(hasError);
  };
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (
      searchParams.has("country") ||
      searchParams.has("city") ||
      searchParams.has("county") ||
      searchParams.has("postCode") ||
      searchParams.has("formattedAddress") ||
      searchParams.has("latitude") ||
      searchParams.has("longitude") ||
      searchParams.has("radius")
    ) {
      setLocation({
        country: searchParams.get("country") || "",
        city: searchParams.get("city") || "",
        county: searchParams.get("county") || "",
        postCode: searchParams.get("postCode") || "",
        formattedAddress: searchParams.get("formattedAddress") || "",
        latitude: searchParams.get("latitude") || null,
        longitude: searchParams.get("longitude") || null,
        radius: searchParams.get("radius") || null,
      });
    }

    if (searchParams.has("specialisms") && specialismsQuery.data) {
      const specialismIds = searchParams.get("specialisms").split(",");
      const selectedSpecialisms = specialismsQuery.data.specialisms.filter(
        (s) => specialismIds.includes(s.id.toString())
      );
      setSpecialisms(selectedSpecialisms);
    }

    if (searchParams.has("typeOfTherapy")) {
      setTherapyTypes(searchParams.get("typeOfTherapy").split(","));
    }

    if (searchParams.has("languages") && languagesQuery.data) {
      const languageIds = searchParams.get("languages").split(",");
      const selectedLanguages = languagesQuery.data.languages.filter((l) =>
        languageIds.includes(l.id.toString())
      );
      setLanguages(selectedLanguages);
    }

    if (searchParams.has("gender")) {
      setGender(searchParams.get("gender").split(","));
    }

    if (searchParams.has("sessionTypes")) {
      setSessionTypes(searchParams.get("sessionTypes").split(","));
    }

    if (searchParams.has("wheelchairAccess")) {
      setWheelchairAccess(searchParams.get("wheelchairAccess") === "true");
    }
    if (searchParams.has("otherInfo")) {
      setOtherInfo(searchParams.get("otherInfo").split(","));
    }

    if (searchParams.has("page")) {
      setPage(Number(searchParams.get("page"), 10));
    }

    if (searchParams.has("limit")) {
      setLimit(Number(searchParams.get("limit"), 10));
    }

    if (searchParams.has("longitude")) {
      setLongitude(Number(searchParams.get("longitude")));
    }
    if (searchParams.has("latitude")) {
      Number(searchParams.get("latitude"));
    }
    if (searchParams.has("radius")) {
      setRadius(Number(searchParams.get("radius")));
    }
  }, [
    searchParams,
    specialismsQuery.data,
    languagesQuery.data,
    setLocation,
    setSpecialisms,
    setTherapyTypes,
    setLanguages,
    setGender,
    setSessionTypes,
    setWheelchairAccess,
    resetFilters,
    setOtherInfo,
    setPage,
    setLimit,
    setLongitude,
    setLatitude,
    setRadius,
  ]);

  const handleInputChange = (event) => {
    setLocation({ ...location, formattedAddress: event.target.value });
  };

  // Add this new function
  const handleTouchMove = useCallback((e) => {
    e.stopImmediatePropagation();
    e.stopPropagation();
  }, []);

  // Modify the return statement
  return (
    <div onTouchMove={handleTouchMove}>
      {isMobile && (
        <div className="filter_title">
          <h2>Filter your Search</h2>
          <button
            type="button"
            onClick={handleSearch}
            className="cta_ghost_white hideFiltersMobile updateFiltersCTA"
          >
            Update Results
          </button>
        </div>
      )}
      <div className="filter_block_wrap">
        {!isMobile && (
          <button
            type="button"
            onClick={handleSearch}
            className="cta_ghost_white updateFiltersCTA"
            disabled={inputError}
          >
            Update Results
          </button>
        )}
        <div className="filter_block">
          <h3>Locations</h3>
          <InputGoogle
            placeholder="e.g. London"
            onPlaceSelected={handlePlaceSelected}
            value={location.formattedAddress}
            onChange={handleInputChange}
            ref={inputRef}
            onClear={handleClear}
            onError={handleInputError}
          />
        </div>

        <div className="filter_block">
          <h3>Radius</h3>
          <div className="radiusWrap">
            <input
              type="text" // Use "text" to avoid showing the arrows
              placeholder="1"
              value={radius}
              onChange={handleRadiusChange}
              onBlur={onSearch}
              inputMode="numeric" // Displays a numeric keypad on mobile devices
              pattern="[0-9]*" // Allows only numeric input
              onKeyPress={(e) => {
                if (!/[0-9]/.test(e.key)) {
                  e.preventDefault(); // Prevents non-numeric characters from being entered
                }
              }}
            />
            <label>Miles</label>
          </div>
        </div>

        <FilterSelectObject
          title="Specialisms"
          options={specialismsQuery.data?.specialisms || []}
          selectedOptions={specialisms}
          onChange={(option, isAdd) => {
            if (isAdd) {
              setSpecialisms([...specialisms, option]);
            } else {
              setSpecialisms(specialisms.filter((s) => s.id !== option.id));
            }
          }}
          multiSelect
        />
        <FilterSelectString
          title="Type of Therapy"
          options={typesOfTherapy}
          selectedOptions={therapyTypes}
          onChange={(option, isAdd) => {
            if (isAdd) {
              setTherapyTypes([...therapyTypes, option]);
            } else {
              setTherapyTypes(therapyTypes.filter((type) => type !== option));
            }
          }}
          multiSelect
        />

        <FilterSelectObject
          title="Languages"
          options={languagesQuery.data?.languages || []}
          selectedOptions={languages}
          onChange={(option, isAdd) => {
            if (isAdd) {
              setLanguages([...languages, option]);
            } else {
              setLanguages(languages.filter((l) => l.id !== option.id));
            }
          }}
          multiSelect
        />
        <FilterSelectString
          title="Gender"
          options={genders}
          selectedOptions={gender?.map((item) =>
            item
              .split("_")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")
          )}
          onChange={(option, isAdd) => {
            if (isAdd) {
              setGender([...gender, option]);
            } else {
              setGender(
                gender.filter((g) => {
                  return (
                    g.toLowerCase() !== option.toLowerCase().replace(/ /g, "_")
                  );
                })
              );
            }
          }}
          multiSelect
        />
        <FilterOptions
          title="Session Type"
          options={["In Person", "Online", "Telephone"]}
          selectedOptions={sessionTypes}
          onChange={handleSessionTypeChange}
          isSingleSelect={false}
        />

        <FilterOptions
          title="Wheelchair Accessible"
          options={["Yes", "No"]}
          selectedOptions={[wheelchairAccess ? "Yes" : "No"]}
          onChange={handleWheelchairAccessChange}
          isSingleSelect={true}
        />
      </div>
    </div>
  );
}

Filters.propTypes = {
  onHideFiltersClick: PropTypes.func,
  onSearch: PropTypes.func,
};

export default Filters;
