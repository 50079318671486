import Layout from "../../components/layouts/layout";
import { Home } from "../../pages/home";
import { ArticlesRoutes } from "../../pages/routes/ArticlesRoutes";
import { AuthRoutes } from "../../pages/routes/AuthRoutes";
import { BlogRoutes } from "../../pages/routes/BlogRoutes";
import { CounselingRoutes } from "../../pages/routes/CounselingRoutes";
import { FindHelpForRoutes } from "../../pages/routes/FindHelpForRoutes";
import { HowtoRoutes } from "../../pages/routes/HowtoRoutes";
import { ResultsRoutes } from "../../pages/routes/ResultsRoutes";
import { AcceptableUse } from "../../pages/terms/acceptableUse";
import { Terms } from "../../pages/terms/terms";
import { PPolicy } from "../../pages/terms/PPolicy";
import { CPolicy } from "../../pages/terms/CPolicy";
import { TermsTherapist } from "../../pages/terms/termsTherapist";
import { WebsiteDisclaimer } from "../../pages/terms/websiteDisclaimer";
import { WhyJoin } from "../../pages/why-join";

export const publicRoutes = [
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "why-join-therapy-in-london/*",
        element: <WhyJoin />,
      },
      {
        path: "results/*",
        element: <ResultsRoutes />,
      },
      {
        path: "articles/*",
        element: <ArticlesRoutes />,
      },
      {
        path: "find-help-for/*",
        element: <FindHelpForRoutes />,
      },
      {
        path: "blog/*",
        element: <BlogRoutes />,
      },
      {
        path: "counselling-services/*",
        element: <CounselingRoutes />,
      },
      {
        path: "how-to/*",
        element: <HowtoRoutes />,
      },
      {
        path: "terms/acceptable-use/*",
        element: <AcceptableUse />,
      },
      {
        path: "terms/cookies-policy/*",
        element: <CPolicy />,
      },
      {
        path: "terms/disclaimer/*",
        element: <WebsiteDisclaimer />,
      },
      {
        path: "terms/terms/*",
        element: <Terms />,
      },
      {
        path: "terms/privacy-policy/*",
        element: <PPolicy />,
      },
      {
        path: "terms/terms-therapists/*",
        element: <TermsTherapist />,
      },
    ],
  },
  {
    path: "/portal/*",
    element: <AuthRoutes />,
  },
];
