import "../../../styles/global.scss";
import "../../../styles/sections.scss";
import "../../../styles/articles.scss";
import PostIt from "../../../components/elements/findhelpfor/postIt";
import { motion } from "framer-motion";

// Define animation variants
const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } }
};

function PanicAttacks() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../find-help-for">Find Help For..</a>
            <img src="/images/illustrations/underline.svg" />
          </div>
          <img src="/images/icons/chevron_right.svg" className="chevron" />
          <div className="breadcrumb current_page">
            <a href="#">Panic Attacks</a>
            <img src="/images/illustrations/underline.svg" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Therapy for Panic Attacks</h1>
                    <img src="/images/illustrations/purpleScribble.svg" className="titleScribble" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="/images/articles/findHelpFor/panic-attacks.jpg" />
                </div>
              </div>
              <div className="content">
                <p>Panic attacks, (anxiety attacks, or panic disorder) is an issue resulting in severe physical symptoms surrounding anxiety.</p>

                <p>Experiencing panic attacks can be scary, especially when you might feel it has come out of nowhere. There are many symptoms associated with panic attacks, but this often short yet intense surge of anxiety can feel uncontrollable and as if it will never end.</p>

                <p>The most <a href="https://www.nhs.uk/mental-health/conditions/panic-disorder/" target="_blank" rel="noreferrer noopener">common symptoms</a> include:</p>

                <ul>
                  <li>Shaking</li>
                  <li>Hyperventilating / shortness of breath</li>
                  <li>Uncontrollable crying</li>
                  <li>Hot flushes or chills</li>
                  <li>Racing heart</li>
                </ul>

                <h2>What causes panic attacks?</h2>

                <p>Many will experience a panic attack when faced with intense pressure or stress over something.</p>

                <ul>
                  <li>Exposed to a phobia</li>
                  <li>A shocking or unexpected event</li>
                  <li>Building pressure or stress in your life</li>
                </ul>

                <p>When faced with something you might not know how to intuitively handle, you can start to panic.</p>

                <p>The overall cause of a panic attack is rooted in the anxiety that built up to the attack. A panic attack can often be your last resort attempt at letting you know that something is wrong.</p>

                <p>Usually, before a panic attack, you will experience anxiety letting you know you are in a wary or dangerous situation. When ignoring these signs, your anxiety will increase in an attempt to keep you safe. This can all build up to a panic attack as your mind’s extreme attempt to send you the same message.&nbsp;</p>

                <p>This is why understanding your own anxiety is so important in keeping these more extreme, and even harder to deal with emotions at bay.</p>

                <p>In many cases, this can be unavoidable if your past includes issues such as:</p>

                <ul>
                  <li>Abuse</li>
                  <li>Bullying</li>
                  <li>Trauma</li>
                  <li>High stress such as being made redundant or fired.</li>
                </ul>

                <p>No matter what your issues were previously, panic attacks are scary yet preventable in the long term through counselling.</p>

                <h2>How can therapy help?</h2>

                <p>Many panic attacks can feel completely unavoidable as you can feel disengaged from your own body during an attack.</p>

                <p>This is why during therapy, you are able to take a look at your attacks from the outside in. This way you can understand what is happening to surround each one – both in hindsight and with foresight. It’s important to reflect on yourself and your experiences outside of you living them as this offers you a whole new perspective on what you were feeling and why.</p>

                <p>Even during your highest moments, you can create the clearest picture of your lowest moments.</p>

                <h2>How can <em>Therapy in London</em> help?</h2>

                <p>Your therapist is able to be the one person in your life who is as immersed in your life as you are.&nbsp;</p>

                <p>This is the kind of support that is difficult to find anywhere else. And this is so important in understanding what triggers your attacks and how you can rework your outlook on the past for the better.</p>

                <p>Your panic attacks can feel lonely, isolating, and as if no one can fully understand them. This is why our practitioners can offer support that is completely unique to you and your world. By having this kind of support you are able to gain a whole new insight into your thoughts, feelings, and beliefs.</p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default PanicAttacks;
