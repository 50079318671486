import "../../styles/global.scss";
import "../../styles/sections.scss";
import "../../styles/articles.scss";
import PostIt from "../../components/elements/findhelpfor/postIt";
import { motion } from "framer-motion";
import { useQuery } from "@tanstack/react-query";
import { getBlogBySlug } from "../../app/api/blogs/getBlogBySlug";
import { Link, useParams } from "react-router-dom";
import Loader from "../../components/elements/loader";
import { getFileUrl } from "../../app/lib/getFileUrl";
import { decode } from 'html-entities';
import { format, parseISO } from 'date-fns';
import "react-quill/dist/quill.bubble.css";
import parse from 'html-react-parser';


// Define animation variants
const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } },
};


export const Blog = () => {
  const { slug } = useParams();

  const { data, isLoading } = useQuery({
    queryKey: ["blog", slug],
    queryFn: () => getBlogBySlug({ slug }),
  });

  if (isLoading) {
    return <Loader subtitle="Loading bolgpost..." />;
  }
  const blog = data?.blog;
  const blogImage = getFileUrl("blogImage", blog?.id, blog?.picture);

  const transformTherapyUrl = (content) => {
    if (!content) return content;

    // First replace the therapy URLs
    let transformedContent = content.replace(
      /https:\/\/www\.therapylondon\.co\//g,
      "https://www.therapyin.london/"
    );

    // Extract and clean up YouTube iframes
    transformedContent = transformedContent.replace(
      /<figure[^>]*>.*?<iframe[^>]*data-src="(https:\/\/www\.youtube\.com\/embed\/[^"]+)"[^>]*>.*?<\/figure>/gs,
      '<iframe src="$1" frameborder="0" allowfullscreen class="ql-video"></iframe>'
    );

    // Remove category links but keep the text content
    transformedContent = transformedContent.replace(
      /<a[^>]*href="[^"]*\/categories\/[^"]*"[^>]*>(.*?)<\/a>/g,
      '$1'
    );

    return transformedContent;
  };

  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <motion.div
          className="breadcrumbs_wrap"
          initial="hidden"
          animate="visible"
          variants={fadeInVariants}
        >
          <div className="breadcrumb">
            <Link to="/blog">Blog</Link>
            <img
              src="../../images/illustrations/underline.svg"
              alt="Underline decoration"
            />
          </div>
          <img
            src="../../images/icons/chevron_right.svg"
            className="chevron"
            alt="Chevron right"
          />
          <div className="breadcrumb current_page">
            <a href="#">{decode(blog.title)}</a>
            <img
              src="../../images/illustrations/underline.svg"
              alt="Underline decoration"
            />
          </div>
        </motion.div>

        <div className="articleView_wrap fhfArticleWrap">
          <motion.div
            className="articleView"
            initial="hidden"
            animate="visible"
            variants={fadeInVariants}
          >
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>{decode(blog.title)}</h1>
                    <img
                      src="../../images/illustrations/purpleScribble.svg"
                      className="titleScribble"
                      alt="Purple scribble decoration"
                    />
                  </div>
                  <div className="writerProfile">
                    <div className="details">
                      <label>{decode(blog.author)}</label>
                      <label>{format(parseISO(blog.createdAt), 'do MMMM yyyy')}</label>
                    </div>
                  </div>
                </div>
                <div className="featured_image">
                  <img src={blogImage} alt={blog.title} />
                </div>
              </div>
              <div className="content blogContent">
                {parse(transformTherapyUrl(blog.content))}
              </div>
            </motion.div>
            <PostIt />
          </motion.div>
        </div>
      </motion.div>
    </>
  );
};
