import PostIt from "../../../components/elements/findhelpfor/postIt";
import "../../../styles/global.scss";
import "../../../styles/sections.scss";
import "../../../styles/articles.scss";
import { motion } from "framer-motion";

// Define animation variants
const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } }
};

function Anxiety() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../find-help-for">Find Help For..</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
          <img src="/images/icons/chevron_right.svg" className="chevron" alt="Chevron right" />
          <div className="breadcrumb current_page">
            <a href="#">Anxiety</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Therapy for Anxiety</h1>
                    <img src="/images/illustrations/purpleScribble.svg" className="titleScribble" alt="Purple scribble decoration" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="/images/articles/findHelpFor/anxiety.jpg" alt="Anxiety article image" />
                </div>
              </div>
              <div className="content">
                <p>Understanding anxiety first begins with why it’s there in the first place. Anxiety is an early sign of perceived danger, so without it, we wouldn’t be able to keep ourselves safe within the world.</p>

                <p>Even though we need it, many forget how to use it, and this is where these issues come into play.</p>

                <p>Anxiety is one of the most common issues that people face. It’s something that can creep up on you and feel as if it takes control over your life, and sometimes you won’t even realise it.</p>

                <h2>What is Anxiety?</h2>

                <p>The first thing to understand is that everyone feels anxious in one way or another. It is our natural, built-in red flag.</p>

                <p>Feeling anxious is unique to each and every one of you, but there are a few symptoms that can be prominent:</p>

                <ul>
                  <li>Dread of something in the future</li>
                  <li>Sleep issues or <a href="https://www.therapyin.london/blog/how-to-get-a-good-nights-sleep/" target="_blank" rel="noreferrer noopener">Insomnia</a></li>
                  <li><a href="https://www.therapyin.london/find-help-for/procrastination/" target="_blank" rel="noreferrer noopener">Procrastination</a> or wanting to run away</li>
                  <li>Irritation</li>
                  <li>Unable to concentrate or feeling overly alert</li>
                </ul>

                <p>When these symptoms start to affect your day-to-day, it’s because anxiety hasn’t been addressed.</p>

                <p>Burying those feelings can then create a snowball effect and even lead to <a href="https://www.therapyin.london/find-help-for/depression/" target="_blank" rel="noreferrer noopener">depression</a>. This is why it is so important to understand what you need to address, and the tools you can use to keep it at bay.</p>

                <h2>What causes anxiety?</h2>

                <p>When talking about anxiety it’s important to differentiate between it being an issue and it being a functional part of your life.</p>

                <p>If you do not have the tools in place, then any strong feelings like these can feel like a burden and something that can get in your way from either doing anything new or moving forward in your life.</p>

                <p>Anxiety will keep reminding you of something, and become louder and more intense until it leaks into insignificant parts of your life. Anxiety issues then cause you to feel anxious even from your regular activities because you are yet to teach yourself what is safe and what isn’t.</p>

                <h2>How therapy can help with anxiety issues.</h2>

                <p>Anyone (even you) can step away from anxiety to teach yourself what it is, why it is there, and how to take control of it. When you start therapy your aim is to understand it, rather than get rid of it for good.</p>

                <p>Feeling anxious is fuel for your passions, dreams and goals once you know how to use it. And understanding it is the real key. Therapy is the ideal situation to do this because your counsellor is able to hear your story for the first time and be a non-judgemental guide through your issues. This time is where you are able to find out what makes you tick and what anxiety was trying to protect you from initially.</p>

                <h2>How <em>Therapy in London</em> can help.</h2>

                <p>Anxiety is a common issue for many, but this shouldn’t take away the fact that your experiences and your feelings are completely unique to you.</p>

                <p>This is why we take into account your background, lifestyle and needs for therapy and find the ideal counsellor for you.</p>

                <p>We aim to find the strongest therapeutic relationship for you, with someone you can trust, and find the confidence to learn how to keep it within your control.</p>

                <p>With your counsellor, your journey to understanding anxiety can be a successful one.</p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default Anxiety;
