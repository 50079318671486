import "../../../styles/global.scss";
import "../../../styles/sections.scss";
import "../../../styles/articles.scss";
import PostIt from "../../../components/elements/findhelpfor/postIt";
import { motion } from "framer-motion";

// Define animation variants
const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } }
};

function Phobia() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../find-help-for">Find Help For..</a>
            <img src="/images/illustrations/underline.svg" />
          </div>
          <img src="/images/icons/chevron_right.svg" className="chevron" />
          <div className="breadcrumb current_page">
            <a href="#">Phobias</a>
            <img src="/images/illustrations/underline.svg" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Therapy for Your Phobia</h1>
                    <img src="/images/illustrations/purpleScribble.svg" className="titleScribble" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="/images/articles/findHelpFor/phobia.jpg" />
                </div>
              </div>
              <div className="content">
                <p>A phobia is an anxiety disorder that causes severe, and sometimes debilitating, fear in certain things or situations.</p>
                <p>Phobias bring on a feeling of a heightened sense of danger and/or insecurity, and can become worse over time if not addressed.</p>
                <p>There are many different <a href="https://www.nhs.uk/mental-health/conditions/phobias/overview/">phobias</a>, but what is important to know is how phobias develop, and with that, an understanding of how they can be controlled, avoided and treated.</p>
                <p>What can begin as fear, can then become an active avoidance. When consistently avoiding that thing or situation, your mind will then create a false sense of endangerment.</p>
                <p>As phobias are a form of anxiety, they react to your mind and body in the same way. An anxiety will ensure that you are safe by giving you warning signs in the form of fear to make sure you are safe.</p>
                <p>As we grow and develop through life, if you do not address your <a href="https://www.therapyin.london/find-help-for/anxiety">anxieties</a> and update your thoughts, feelings and reactions to them, then they can snowball into a more severe condition.</p>
                <p>This snowball effect can form a phobia. At this point, you have allowed your mind to give that anxiety a false narrative. When you eventually become exposed to that fear after all that avoidance, your body will react to that manufactured danger you have created.</p>
                <p>At the point of developing a phobia, your reactions to it can feel uncontrollable, including:</p>
                <ul>
                  <li>Shaking and sweating</li>
                  <li>Unable to think about anything but the ‘dangers’ involved</li>
                  <li>Palpitations</li>
                  <li>Losing consciousness to escape the situation</li>
                </ul>
                <p>Any reaction to your phobia is an attempt to remove yourself from where you are or what you are doing.</p>
                <h2>What causes phobias.</h2>
                <p>Understanding how a phobia develops for you, but not for others, can be pinpointed back to any moment in your life where you believed that what or who you were with was fearful.</p>
                <h3>A small fear, and confirmation of that fear, is where it can begin.</h3>
                <p>As an example:</p>
                <p>One of the most common phobias is spiders (Arachnophobia). Factually, the majority of spiders are harmless, but they have a stigma of being scary. This stigma can plant a seed for that fear – now with that fear planted, a common house spider can be a thing of worry.</p>
                <p>That anticipation is usually forgotten about, but when a house spider does show itself, it catches you off guard. You are frightened because that fear was already present in the back of your mind.</p>
                <p>Those (harmless) scares will continually plant more seeds, and if not addressed, will do so until you cannot control your emotions towards it.</p>
                <p>You can apply this to any phobia and usually find that initial anxiety from your past.</p>
                <h2>How therapy can help you.</h2>
                <p>Therapy is a hugely beneficial way of tackling phobias. Talking therapy will allow you to trace your fears back to that first moment when the anxiety started. By doing this you are creating a truthful and factual narrative for your phobia.</p>
                <p>Once you understand how you have gotten to this point in your life, you are able to fully understand that your phobia is something that you created and therefore can be changed for the better.</p>
                <h2>How <em>Therapy in London</em> can help.</h2>
                <p>Our practitioners have experienced and integrative backgrounds within counselling, therapy and psychology. This means that your sessions will suit your individual needs and expectations for counselling.</p>
                <p>We understand that you want to beat your phobia to the point where it won’t be a burden or anticipated anxiety in your day to day life. Our counsellors will help you understand your phobia and not only how it has affected your life, but also how it has been trying to protect you.</p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default Phobia;
