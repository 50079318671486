import "../../../styles/global.scss";
import "../../../styles/sections.scss";
import "../../../styles/articles.scss";
import PostIt from "../../../components/elements/findhelpfor/postIt";
import { motion } from "framer-motion";

const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

function Family() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../../counselling-services/">Types of Therapy</a>
            <img src="../../images/illustrations/underline.svg" />
          </div>
          <img src="../../images/icons/chevron_right.svg" className="chevron" />
          <div className="breadcrumb current_page">
            <a href="#">Family Counselling</a>
            <img src="../../images/illustrations/underline.svg" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView ">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={containerVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Family Counselling</h1>
                    <img src="../../images/illustrations/purpleScribble.svg" className="titleScribble" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="../../images/articles/typesOfTherapy/article/family.jpg" />
                </div>
              </div>
              <div className="content">
                <p>
                  When considering family counselling, you are likely experiencing either
                  a breakdown in communication between your family or you want to bring
                  your family closer together.
                </p>
                <p>
                  These are all valid reasons to want to begin family counselling. What
                  it can do is open up lines of communication between you and your family.
                </p>
                <p>
                  When you have the confidential and neutral space to really reflect on
                  your families experiences and feelings, you can gain candid insight
                  into how they are feeling and how you feel within the family dynamic
                  too.
                </p>
                <h2>What family counselling can help you with.</h2>
                <p>
                  Beginning family counselling can seem like the worst case scenario –
                  this isn’t the case, however. You must have heard throughout your life
                  that you can choose your friends, but you cannot choose your family, but
                  this doesn’t mean that you shouldn’t put the work in to ensure your
                  family remains as bonded as they can be.
                </p>
                <p>
                  Within the family dynamic there can be regular:
                </p>
                <ul>
                  <li>Arguments</li>
                  <li>Lack of boundaries</li>
                  <li>Lack of communication</li>
                  <li>Dishonesty</li>
                  <li>Avoidance</li>
                  <li>Betrayal and disloyalty</li>
                </ul>
                <p>
                  These can be a common occurrence in families because you are surrounded
                  by the same people for most or all of your life. You can all have a
                  heightened expectation of how well you know each other. Combining that
                  with the close proximity of a family home, then some cracks and rifts
                  can form.
                </p>
                <h2>Meeting your family counsellor.</h2>
                <p>
                  A family counsellor will be the impartial guide within your sessions.
                  Their job will be to encourage discussion between you and your family
                  and help you (and them) to, importantly, listen and fully understand
                  each other’s perspectives.
                </p>
                <p>
                  Each session can bring a new outlook on some or many aspects of your
                  relationships and the way you all think and act the way you do.
                </p>
                <p>
                  Your counsellor will ask open ended questions which can either be
                  directed at one or all of you, and you take this session time to listen,
                  appreciate and respond to each other.
                </p>
                <p>
                  Having an impartial go-between is beneficial as it can diffuse any
                  tension from something said that may otherwise cause further rifts. It
                  creates a safe space for you and your family to open up in ways you may
                  otherwise not be able to.
                </p>
                <p>
                  With your counsellor there to steer the conversation forward, your
                  family is able to grow and adapt to each other’s needs, creating a
                  stronger bond and a happier family overall.
                </p>
                <h2>How Therapy in London can help.</h2>
                <p>
                  Deciding to not only open up within counselling but to also open up in
                  front of your family can seem daunting. This is why we believe that
                  choosing the right counsellor is an important step in a successful
                  counselling experience. Each of our counsellors is highly qualified and
                  experienced, and we are able to fit the right practitioner to meet your
                  expectations.
                </p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default Family;
