import { useQuery } from "@tanstack/react-query";
import { motion, useScroll } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { getAllArticles } from "../app/api/articles";
import { getFileUrl } from "../app/lib/getFileUrl";
import ArmChair from "../components/animations/ArmChair";
import ArrowPurple from "../components/animations/ArrowPurple";
import Door from "../components/animations/Door";
import GreenUnderline from "../components/animations/GreenUnderline";
import Lamp5 from "../components/animations/Lamp5";
import Room from "../components/animations/Room";
import SideTable from "../components/animations/SideTable";
import SmallPot from "../components/animations/SmallPot";
import SofaBlue from "../components/animations/SofaBlue";
import SofaGreen from "../components/animations/SofaGreen";
import SofaOrange from "../components/animations/SofaOrange";
import ZigGreen from "../components/animations/ZigZagGreen";
import FeaturedCarousel from "../components/elements/carousel";
import OctagonImage from "../components/elements/filteredImage";
import Loader from "../components/elements/loader";
import Search from "../components/elements/search";
import CTA from "../components/fragments/CTA";
import MetaData from "../components/fragments/MetaData";
import LineBackground from "../components/fragments/lineBackground";
import "../styles/global.scss";
import "../styles/sections.scss";
import ZigGreen2 from "../components/animations/ZigZagGreen2";
import ArticleLoader from "../components/elements/articleLoader";

// Define the slide-up effect variant
const slideUpVariant = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
};

// Typing animation for h1 text
const typingVariant = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1, // delay between each letter
    },
  },
};

const letterVariant = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.1 } },
};

export const Home = () => {
  const titleRef = useRef(null);
  const contentRef = useRef(null);
  const homeMainSplashRef = useRef(null);
  const [bgColor, setBgColor] = useState("rgba(0, 0, 0, 0)");
  const [isTypingComplete, setIsTypingComplete] = useState(false); // Track when typing completes

  const getArticlesQuery = useQuery({
    queryKey: ["articles", "home"],
    queryFn: async () =>
      await getAllArticles({ status: "approved", page: 1, limit: 10 }),
  });

  const articles =
    getArticlesQuery.data?.articles
      .filter((a) => a.status !== "draft" && a.status !== "awaitingApproval")
      .slice(0, 5) || [];
  const { scrollY } = useScroll();

  useEffect(() => {
    return scrollY.on((latest) => {
      const opacity = Math.min(latest / 500, 0.5); // Adjust the divisor to control the speed of transition
      setBgColor(`rgba(0, 0, 0, ${opacity})`);
    });
  }, [scrollY]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            titleRef.current.classList.add("animate");
          } else {
            titleRef.current.classList.remove("animate");
          }
        });
      },
      { threshold: 0.1 }, // Trigger when 10% of the element is visible
    );

    if (titleRef.current) {
      observer.observe(titleRef.current);
    }

    return () => {
      if (titleRef.current) {
        observer.unobserve(titleRef.current);
      }
    };
  }, []);

  return (
    <>
      <MetaData title="Therapy in London • Find a Therapist in London" />

      <section
        id="find"
        className="homeMainSplash overflowHidden"
        ref={homeMainSplashRef}
      >
        <div
          className="backgroundTransition"
          style={{ backgroundColor: bgColor }}
        />
        <LineBackground />
        <div className="homeSplash_wrap">
          <Search />
        </div>
      </section>

      <section className="backgroundColor">
        <img
          alt="stamp"
          className="borderTopHome"
          src="/images/borders/stampMaskTop.png"
        />
        <div className="homeSplash_wrap homeSplash_main">
          <motion.div
            ref={titleRef}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.2 }}
            variants={typingVariant}
            className="homeSplash_title"
            onAnimationComplete={() => setIsTypingComplete(true)} // Mark typing as complete
          >
            <h1>
              {"Therapy in London".split("").map((letter, index) => (
                <motion.span key={index} variants={letterVariant}>
                  {letter}
                </motion.span>
              ))}
            </h1>
          </motion.div>
          <motion.div
            className="homeSplash_illustration"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.2 }}
            variants={slideUpVariant}
          >
            <Room />
          </motion.div>
        </div>
        <div className="homeSplash_content">
          <motion.div
            className="homeSplash_block"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.2 }}
            variants={slideUpVariant}
          >
            <div className="title">
              <h2>
                Real Connections.
                <br />
                <span>
                  {" "}
                  Real Change.
                  <GreenUnderline />
                </span>
              </h2>
            </div>
          </motion.div>
          <motion.div
            className="homeSplash_block"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.2 }}
            variants={slideUpVariant}
          >
            <div className="image">
              <div className="arrowWrap">
                <ArrowPurple />
              </div>
              <div className="doorWrap">
                <Door />
              </div>
            </div>
            <div className="description">
              <p>
                Your mental health should take priority in your life. The
                journey to finding the right therapist begins with establishing
                a deep connection and trust with a professional who creates a
                safe space for you to share openly. At Therapy in London, we’ll
                support and enable you to find the right therapist for you and
                your needs.
              </p>
              <p>
                Therapy in London provides an opportunity for you to find a
                therapist with whom you instantly connect to; fostering trust,
                understanding, and personal growth. Explore our therapists
                profiles today to start your therapy journey.
              </p>
              <div className="plantpot">
                <SmallPot />
              </div>
            </div>
          </motion.div>
        </div>
      </section>

      <section className="backgroundColor" style={{ paddingTop: "0" }}>
        <div className="homeSplash_wrap">
          <motion.div
            className="home_latestArticles"
            id="fixed-target"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.2 }}
            variants={slideUpVariant}
          >
            <div
              className="title"
              data-scroll
              data-scroll-sticky
              data-scroll-target="#fixed-target"
              data-scroll-offset="100"
              ref={contentRef}
            >
              <div>
                <h2>
                  Latest Articles <br /> by Our Therapists
                </h2>
              </div>
              <div>
                <CTA type="blue" link="/articles" text="See all Articles" />
              </div>
              <Lamp5 />
            </div>

            <div className="latestArticles_wrap">
              {getArticlesQuery.isLoading ? (
                <ArticleLoader />
              ) : (
                <div className="latestArticles">
                  <motion.div
                    className="column"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.2 }}
                    variants={slideUpVariant}
                  >
                    {articles.slice(3, 5).map((article) => (
                      <OctagonImage
                        key={article.id}
                        src={
                          getFileUrl(
                            "profilePicture",
                            article.user.id,
                            article.user.profile.profilePicture,
                          ) || "/images/placeholders/default.png"
                        }
                        href={`/articles/${article.user.id}/${article.id}`}
                        title={article.title}
                        author={article?.user?.name}
                        authorId={article?.user?.id}
                      />
                    ))}
                  </motion.div>
                  <motion.div
                    className="column"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.2 }}
                    variants={slideUpVariant}
                  >
                    {articles.slice(0, 3).map((article) => (
                      <OctagonImage
                        key={article.id}
                        src={
                          getFileUrl(
                            "profilePicture",
                            article.user.id,
                            article.user.profile.profilePicture,
                          ) || "/images/placeholders/default.png"
                        }
                        href={`/articles/${article.user.id}/${article.id}`}
                        title={article.title}
                        author={article?.user?.name}
                        authorId={article?.user?.id}
                      />
                    ))}
                  </motion.div>
                </div>
              )}
            </div>
          </motion.div>
        </div>
      </section>

      <section className="backgroundColor topPadding">
        <div className="homeSplash_wrap">
          <motion.div
            className="home_YourGuide"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.2 }}
            variants={slideUpVariant}
          >
            <div className="title">
              <h2>
                The
                <span className="zigWrap">
                  {" "}
                  <span>how to&apos;s</span>
                  <ZigGreen2 />
                </span>{" "}
                of Therapy
              </h2>
              <div className="cabinet">
                <SideTable />
              </div>
            </div>
            <div className="content">
              <div className="howTo_Steps">
                {/* Step 1 */}
                <motion.div
                  className="row"
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.2 }}
                  variants={slideUpVariant}
                >
                  <div className="wrap">
                    <div className="title step">
                      <div className="label_angle" />
                      <label>Step 1</label>
                    </div>
                    <div className="rowBlock">
                      <div className="column">
                        <div className="blockWrap">
                          <SofaOrange />
                        </div>
                      </div>
                      <div className="column">
                        <div className="blockWrap">
                          <div className="text">
                            <h2>Search for the right Therapist</h2>
                            <p>
                              Use our intuitive search bar to help you find the
                              right therapist based on your issue and location.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </motion.div>

                {/* Step 2 */}
                <motion.div
                  className="row"
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.2 }}
                  variants={slideUpVariant}
                >
                  <div className="wrap">
                    <div className="title step">
                      <div className="label_angle" />
                      <label>Step 2</label>
                    </div>
                    <div className="rowBlock">
                      <div className="column">
                        <div className="blockWrap">
                          <SofaBlue />
                        </div>
                      </div>
                      <div className="column">
                        <div className="blockWrap">
                          <div className="text">
                            <h2>See what they actually think</h2>
                            <p>
                              Our therapists are showcasing their individual
                              practices by regularly discussing topics relevant
                              to your search. Making your choice far less
                              daunting.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </motion.div>

                {/* Step 3 */}
                <motion.div
                  className="row"
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.2 }}
                  variants={slideUpVariant}
                >
                  <div className="wrap">
                    <div className="title step">
                      <div className="label_angle" />
                      <label>Step 3</label>
                    </div>
                    <div className="rowBlock">
                      <div className="column">
                        <div className="blockWrap">
                          <SofaGreen />
                        </div>
                      </div>
                      <div className="column">
                        <div className="blockWrap">
                          <div className="text">
                            <h2>Make Contact</h2>
                            <p>
                              Personalised profiles allow you to see who each
                              therapist really is. From their background and
                              taste in films to a common interest or personal
                              outlook, you can find the right therapist in a
                              more personable way.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </motion.div>
              </div>
            </div>
            <div className="mainCTAWrap">
              <a className="cta_blue" href="#">
                {" "}
                Find a Therapist
              </a>
            </div>
          </motion.div>
        </div>
      </section>

      <section className="backgroundColor">
        <div className="homeSplash_wrap">
          <motion.div
            className="featuredIn_wrap"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.2 }}
            variants={slideUpVariant}
          >
            <div className="title">
              <h2>Featured In</h2>
            </div>
            <div className="featuredIn_logos">
              <FeaturedCarousel />
            </div>
          </motion.div>
        </div>
      </section>

      <section className="overflowHidden backgroundColor">
        <div className="postItWrap">
          <div className="postit_dots">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <LineBackground />

        <div className="homeSplash_wrap lineBackOverlay">
          <motion.div
            className="homeTherapist_wrap"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.2 }}
            variants={slideUpVariant}
          >
            <div className="block">
              <div className="description">
                <div className="title">
                  <h2>Are you a Therapist?</h2>
                  <span className="zigWrapChair">
                    <ZigGreen />
                  </span>
                </div>
                <div className="content">
                  <p>
                    Sign up for your free account to see what your Therapy in
                    London profile is capable of. Promote your mental health
                    practice with connection in mind.
                  </p>

                  <CTA
                    type="blue"
                    text="Find out more and sign up here"
                    link="/why-join-therapy-in-london"
                  />
                </div>
              </div>
            </div>

            <div className="block" style={{ pointerEvents: "none" }}>
              <div className="chair">
                <ArmChair />
              </div>
            </div>
          </motion.div>
        </div>
      </section>
    </>
  );
};
