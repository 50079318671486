import { useQuery } from "@tanstack/react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getUserById } from "../app/api/profile/getUserByid";
import Loader from "../components/elements/loader";
import ProfilePageView from "../components/elements/profilePageView";
import { motion } from "framer-motion"; // Import Framer Motion
import "../styles/buttons.scss";
import "../styles/global.scss";
import "../styles/profiles.scss";

// Define animation variants
const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } },
};

function Profile() {
  const { userId } = useParams();
  const navigate = useNavigate();

  const { data, isLoading } = useQuery({
    queryKey: ["user", userId],
    queryFn: () => (userId ? getUserById(userId) : null),
    enabled: !!userId,
  });

  const handleBackToSearch = () => {
    navigate(-1); // This will go back to the previous page in the history
  };

  if (isLoading) return <Loader subtitle="Loading..." />;

  return (
    <motion.div
      className="profileView_wrap"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <motion.div
        className="profileView profileView_full"
        initial="hidden"
        animate="visible"
        variants={fadeInVariants}
      >
        <div className="profileView_controls_wrap">
          <motion.div
            className="breadcrumbs_wrap"
            initial="hidden"
            animate="visible"
            variants={fadeInVariants}
          >
            <div className="breadcrumb">
              <a href="/">Find a Therapist</a>
              <img
                src="../../images/illustrations/underline.svg"
                alt="underline"
              />
            </div>
            <img
              src="../../images/icons/chevron_right.svg"
              className="chevron"
              alt="chevron"
            />
            <div className="breadcrumb">
              <Link to="#" onClick={handleBackToSearch}>
                Search Results
              </Link>
              <img
                src="../../images/illustrations/underline.svg"
                alt="underline"
              />
            </div>
            <img
              src="../../images/icons/chevron_right.svg"
              className="chevron"
              alt="chevron right"
            />
            <div className="breadcrumb current_page">
              <Link to="">{data.user.name}</Link>
              <img
                src="../../images/illustrations/underline.svg"
                alt="underline"
              />
            </div>
          </motion.div>
        </div>

        <ProfilePageView
          userId={userId}
          isTherapistAdmin={false}
          user={data.user}
        />
      </motion.div>
    </motion.div>
  );
}

export default Profile;
