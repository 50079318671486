import PropTypes from "prop-types";
import "../../styles/global.scss";
import "../../styles/sections.scss";

function FilterSelectString({
  title,
  options,
  selectedOptions,
  onChange,
  multiSelect = false,
}) {
  const handleChange = (e) => {
    const selectedValue = e.target.value;
    if (multiSelect) {
      onChange(selectedValue, true);
    } else {
      onChange([selectedValue], true);
    }
  };

  const handleRemove = (optionToRemove) => {
    if (multiSelect) {
      onChange(optionToRemove, false);
    } else {
      onChange([], false);
    }
  };

  const availableOptions = options.filter(
    (option) => !selectedOptions.includes(option)
  );
  return (
    <div className="filter_block">
      <h3>{title}</h3>

      <select value="" onChange={handleChange}>
        <option value="">Select {title}</option>
        {availableOptions.map((option, index) => (
          <option key={index} value={option} style={{ color: "black" }}>
            {option}
          </option>
        ))}
      </select>
      <div className="selected-options">
        {(selectedOptions || []).map((option) => (
          <span key={option} className="selected-option">
            {option}
            <button type="button" onClick={() => handleRemove(option)}>
              ×
            </button>
          </span>
        ))}
      </div>
    </div>
  );
}

FilterSelectString.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedOptions: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  multiSelect: PropTypes.bool,
};

export default FilterSelectString;
