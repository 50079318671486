import { useInfiniteQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Link } from "react-router-dom";
import { getAllArticles } from "../../app/api/admin";
import ArticleRow from "../../components/elements/admin/articleRow";
import Loader from "../../components/elements/loader";
import CTA from "../../components/fragments/CTA";
import "../../styles/admin.scss";
import "../../styles/buttons.scss";
import "../../styles/global.scss";
import { useRef } from "react";
import { useCallback } from "react";
import { useMemo } from "react";

const ARTICLES_PER_PAGE = 10;
function AdminArticles() {
  const [currentFilter, setCurrentFilter] = useState("");
  const observer = useRef();

  const {
    data,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: ["adminArticles", currentFilter],
    queryFn: ({ pageParam = 1 }) =>
      getAllArticles({
        status: currentFilter,
        page: pageParam,
        limit: ARTICLES_PER_PAGE,
      }),
    getNextPageParam: (lastPage, allPages) => {
      return lastPage.result.length === ARTICLES_PER_PAGE
        ? allPages.length + 1
        : undefined;
    },
  });

  const handleFilterChange = async (filter) => {
    setCurrentFilter(filter);
    refetch();
  };

  const lastArticleRef = useCallback(
    (node) => {
      if (isLoading) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
          fetchNextPage();
        }
      });

      if (node) observer.current.observe(node);
    },
    [fetchNextPage, hasNextPage, isFetchingNextPage, isLoading],
  );

  const articles = useMemo(() => {
    return data?.pages.reduce((acc, page) => {
      return [...acc, ...page.result];
    }, []);
  }, [data]);

  if (isLoading) return <Loader subtitle="Loading..." />;

  return (
    <>
      <div className="profileView_wrap signedIn">
        <div className="profileView">
          <div className="profileView_controls_wrap">
            <div className="adminNav">
              <div className="profileView_page_title">
                <div className="title">
                  <h1>Admin</h1>
                </div>
              </div>
              <div className="adminNav_links">
                <div className="links">
                  <ul>
                    <li>
                      <Link to="/admin/users">Users</Link>
                      <img
                        src="../../../images/illustrations/underline.svg"
                        alt="underline"
                      />
                    </li>
                    <li className="active">
                      <Link to="#" disabled>
                        Articles
                      </Link>
                      <img
                        src="../../../images/illustrations/underline.svg"
                        alt="underline"
                      />
                    </li>
                  </ul>
                </div>
                <div className="controls" />
              </div>
            </div>
          </div>
          <div className="adminView_wrap">
            <div className="adminTable_wrap">
              <div className="adminTable">
                {articles &&
                  articles.map((article, index) => (
                    <ArticleRow
                      key={article.id}
                      ref={
                        index === articles.length - 1 ? lastArticleRef : null
                      }
                      title={article.title}
                      status={article.status}
                      articleId={article.id}
                      userName={article.user?.name}
                      userPicture={article.user?.profile?.profilePicture}
                      userId={article.user?.profile?.id}
                    />
                  ))}
                {isFetchingNextPage && <p>Loading...</p>}
              </div>
            </div>
          </div>

          <div className="adminControls_wrap">
            <div className="adminControls">
              <div className="block">
                <h2>Options</h2>
                <CTA
                  type="green"
                  text="Available Articles"
                  link="../articles/available-articles"
                />
              </div>

              <div className="block">
                <h2>Filter</h2>
                <CTA
                  type="ghost_white"
                  text="All"
                  onClick={() => handleFilterChange("")}
                />
                <CTA
                  type="ghost_white"
                  text="Published"
                  onClick={() => handleFilterChange("approved")}
                />
                <CTA
                  type="ghost_white"
                  text="Pending"
                  onClick={() => handleFilterChange("awaitingApproval")}
                />
                <CTA
                  type="ghost_white"
                  text="Rejected"
                  onClick={() => handleFilterChange("rejected")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminArticles;
